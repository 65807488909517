<template>
  <div class="similarityQuestion">
    <!-- 同类试题弹出层 -->
    <el-dialog title="同类题" :visible.sync="questionListModel" width="940px">
      <div class="question-item-box edit-scroll-style">
        <div
          v-for="(v, i) in similarQuestionsList"
          :key="i"
          v-loading="loading"
          class="question-list"
        >
          <div class="question-html" v-html="v.question"></div>
          <div
            v-if="$parent.indexTypes == 'VariantTraining'"
            class="question-btn-box"
            :class="{ 'show-btn': showAwswerList.indexOf(v.id) != -1 }"
          >
            <div v-if="v.xuekeId" class="tips">
              <p>试题ID：{{ v.xuekeId }}</p>
              <span @click="copy(v.xuekeId)">复制</span>
            </div>
            <div :key="boxKey" class="question-btn-right">
              <el-button type="text" @click="showAwswer(v)">
                <template v-if="showAwswerList.indexOf(v.id) != -1">
                  隐藏解析
                </template>
                <template v-if="showAwswerList.indexOf(v.id) == -1">
                  显示解析
                </template>
              </el-button>
              <span
                v-if="getIndexQuestionType(v.id)"
                class="tips-icon"
                @click="$parent.lockingVariation(v)"
              >
                <img src="@/assets/newExam/newpaper/open.png" alt="" />
                设为变式题
              </span>
              <span
                v-else
                class="tips-icon tips-icon-on"
                @click="$parent.cancelVariation(v)"
              >
                <img src="@/assets/newExam/newpaper/down.png" alt="" />
                取消变式题
              </span>
            </div>
          </div>
          <div
            v-if="$parent.indexTypes == 'layeredVariantTraining'"
            class="question-btn-box"
            :class="{ 'show-btn': showAwswerList.indexOf(v.id) != -1 }"
          >
            <div v-if="v.xuekeId" class="tips">
              <p>试题ID：{{ v.xuekeId || v.id }}</p>
              <span @click="copy(v.xuekeId)">复制</span>
            </div>
            <div :key="boxKey" class="question-btn-right">
              <el-popover
                v-if="v.extendList && v.extendList.length > 0"
                placement="bottom"
                width="300"
                trigger="hover"
              >
                <div
                  v-for="(m, n) in v.extendList"
                  :key="n"
                  class="exam-list-box"
                >
                  <span>{{ m.name }}</span>
                  <span>{{ m.examDate }}</span>

                  <span v-if="m.difficultyType == 1"> 较难 </span>
                  <span v-if="m.difficultyType == 2"> 中等 </span>
                  <span v-if="m.difficultyType == 3"> 较易 </span>
                </div>
                <el-button slot="reference" type="text"
                  >当前试题已使用</el-button
                >
              </el-popover>
              <el-button type="text" @click="$parent.showDiffModel(v)"
                >分享至其他拓展层</el-button
              >
              <el-button type="text" @click="showAwswer(v)">
                <template v-if="showAwswerList.indexOf(v.id) != -1">
                  隐藏解析
                </template>
                <template v-if="showAwswerList.indexOf(v.id) == -1">
                  显示解析
                </template>
              </el-button>

              <span
                v-if="getIndexLayeredType(v.id)"
                class="tips-icon"
                @click="$parent.trainingExtend(v, 1, 'new')"
              >
                <img src="@/assets/newExam/newpaper/open.png" alt="" />
                设为变式题
              </span>
              <span
                v-else
                class="tips-icon tips-icon-on"
                @click="$parent.trainingExtend(v, 2)"
              >
                <img src="@/assets/newExam/newpaper/down.png" alt="" />
                取消变式题
              </span>
            </div>
          </div>
          <answerBox
            v-if="showAwswerList.indexOf(v.id) != -1"
            :id="v.id"
          ></answerBox>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="questionListModel = false">取 消</el-button>
        <el-button
          class="btn-all"
          type="primary"
          @click="questionListModel = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getRecommend, lockTraining } from "@/core/api/exam/examLore";
import answerBox from "@/components/answer";
import { mapGetters } from "vuex";
export default {
  name: "SimilarityQuestion",
  components: {
    answerBox,
  },
  data() {
    return {
      questionListModel: false,
      loading: false,
      boxKey: 1,
      similarQuestionsList: [],
      showAwswerList: [],
    };
  },
  computed: {
    ...mapGetters(["getIndexQuestion", "getExamPaper"]),
  },
  created() {},
  methods: {
    lockingVariation(val) {
      let data = {
        examQuestionOpt: val.id,
      };
      data.statusReplaceTraining = 0;
      this.lockTraining(data);
    },
    // 提交新增变式训练题 锁定知识点
    lockTraining(data, type) {
      data.id = this.getIndexQuestion.id;

      if (this.btnDisabled) {
        this.$message({
          message: "网络状态不佳，请不要快速点击！",
          type: "warning",
        });
        return;
      }
      data.type = 1;
      this.btnDisabled = true;
      lockTraining(data)
        .then(() => {
          this.btnDisabled = false;

          if (!type) {
            data.extendType = 1;
            this.$store.commit("putTraining", data);
            this.setList(data);
            this.$parent.getTrainings();
          }
        })
        .catch(() => {
          this.btnDisabled = false;
        });
    },
    copy(v) {
      this.$store.commit("SET_ALL_COPY", { key: v, name: "试题 ID 已复制。" });
    },
    //显示答案组件
    showAwswer(v) {
      if (this.showAwswerList.indexOf(v.id) == -1) {
        this.showAwswerList.push(v.id);
      } else {
        this.showAwswerList.splice(this.showAwswerList.indexOf(v.id), 1);
      }
    },
    //获取当前试题是否是当前锁定项
    getIndexLayeredType(id) {
      let type = true;
      this.getIndexQuestion.examQuestionExtendList.forEach((index) => {
        if (
          index.examQuestionOpt == id &&
          index.difficultyType == this.$parent.layeredTypes
        ) {
          type = false;
        }
      });
      return type;
    },
    getIndexQuestionType(id) {
      let type = true;

      this.getIndexQuestion.examQuestionExtendList.map((item) => {
        if (item.examQuestionOpt == id && (item.type == 1 || item.type == 3)) {
          type = false;
        }
      });

      return type;
    },
    init(v) {
      if (this.btnDisabled) {
        this.$message({
          message: "网络状态不佳，请不要快速点击！",
          type: "warning",
        });
        return;
      }
      this.btnDisabled = true;
      getRecommend(
        this.getIndexQuestion.examQuestionId,
        v.recommendXuekeId,
        this.getExamPaper.id
      )
        .then((res) => {
          this.btnDisabled = false;
          if (res.data.data && res.data.data.length == 0) {
            this.$message({
              message: "未找到变式题",
              type: "warning",
            });
          } else {
            this.similarQuestionsList = res.data.data;
            this.questionListModel = true;
          }
        })
        .catch(() => {
          this.pages = this.current;
          this.btnDisabled = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.similarityQuestion {
  .question-list {
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    margin-bottom: 12px;
    position: relative;
    overflow: hidden;
    min-height: 40px;
    .question-header {
      background: #f9fafc;
      border-bottom: 1px solid #e9e9e9;
      padding: 10px 18px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .span-tips {
        background: #ffecdd;
        border-radius: 4px;
        padding: 2px 10px;
        color: #ff5700;
        margin-left: 4px;
      }
      .header-type {
        color: $primary-color;
      }
      .question-header-right {
        align-items: center;
        display: flex;
        justify-content: flex-end;
        width: 600px;
        line-height: 16px;
        .tips {
          display: flex;

          margin-left: 12px;
          p {
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 300px;
            color: $primary-color;
          }
          span {
            margin-left: 12px;
            cursor: pointer;
          }
        }
      }
    }
    &:hover {
      border-color: $primary-color;
      .question-btn-box {
        display: flex;
      }
    }
    .question-btn-box {
      // opacity: 0;
      // visibility: hidden;
      background: #f9fafc;
      border: 1px solid #e0e0e0;
      padding: 5px 12px;
      width: 100%;
      display: none;
      align-items: center;
      justify-content: space-between;
      position: relative;

      .show-err-span {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: inline-block;
        max-width: 300px;
      }
      .err-span {
        color: #ed2424;
        align-items: center;
        display: flex;
        span {
          margin-left: 12px;
        }
        img {
          margin-right: 4px;
        }
      }
      .succ-span {
        color: #2474ed;
        align-items: center;
        display: flex;
        img {
          margin-right: 4px;
        }
      }
      .parm-span {
        color: #ff7303;
      }
      .ans-box {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        transition: 0.2s linear;
        cursor: pointer;
        user-select: none;
        img {
          width: 18px;
        }
      }
      .ans-on {
        img {
          transform: rotate(180deg);
        }
      }
      .tips {
        display: flex;
        width: 30%;
        p {
          margin: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 60%;
          color: $primary-color;
        }
        span {
          margin-left: 12px;
          color: $primary-color;
          cursor: pointer;
        }
      }
      .question-btn-right {
        display: flex;
        align-items: center;
        .tips-icon {
          cursor: pointer;
          color: #0a1119;
          img {
            width: 16px;
            vertical-align: text-bottom;
          }
        }
        .tips-icon-on {
          color: $primary-color;
        }

        .el-button {
          // padding: 4px 8px;
          margin-right: 16px;
          margin-left: 0;
        }

        // justify-content: space-between;
      }
    }
    .show-btn {
      display: flex;
    }
    .question-html {
      padding: 5px 8px;
    }
  }
}
</style>
