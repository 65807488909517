<template>
  <div class="supplementaryQuestion">
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="940px"
      :close-on-click-modal="false"
    >
      <div slot="title" class="header-box">
        <!-- GET_TEACHER_AUTH -->
        <template v-for="(item, index) in questionType">
          <div
            v-if="
              GET_TEACHER_AUTH == 0 ||
              (GET_TEACHER_AUTH == 1 && item.value == 3) ||
              (GET_TEACHER_AUTH == 2 && item.value == 4)
            "
            :key="index"
            :class="{
              on: indexKey == item.value,
            }"
            @click="checkItem(item.value)"
          >
            {{ item.label }}
          </div>
        </template>
      </div>

      <div class="edit-scroll-style question-item-box" @scroll="disposeScroll">
        <!-- 变式训练题 -->
        <template v-if="indexKey == 3">
          <!-- 条件搜索的盒子 -->
          <div class="search-box">
            <div class="search-left">
              <div
                v-for="(v, i) in variationBtn"
                :key="i"
                class="filter-item"
                :class="{ on: indexVariationBtn == v.val }"
                @click="clickIndexType(v)"
              >
                {{ v.name }}
              </div>
              <div class="filter-item" @click="clickIndexTypeModel('1')">
                自主上传试题
              </div>
              <div class="filter-item" @click="clickIndexTypeModel('3')">
                上传图片搜题
              </div>
              <el-input
                v-model="inheritVal"
                placeholder="输入试题ID或者关键字"
                @keyup.enter.native="inherit"
              >
                <i
                  slot="suffix"
                  class="el-icon-search el-input__icon"
                  @click="inherit"
                >
                </i>
              </el-input>
            </div>
          </div>
          <div v-if="indexVariationBtn == 3" class="search-item-box">
            <div class="search-item">
              <span class="search-item-title">知识点条件搜索：</span>
              <span v-for="(v, i) in knowledgeNames" :key="i" class="know-list">
                <i class="el-icon-remove" @click="delKnowList(i)"></i>
                {{ v }}
              </span>
              <span class="size-btn" @click="showChangeKnow()">
                <i class="el-icon-plus"></i>添加
              </span>
            </div>
            <div class="search-item" style="padding: 4px 0 3px">
              <span>知识点交集/并集：</span>
              <el-switch
                v-model="knowSearchType"
                active-text="交集"
                inactive-text="并集"
              >
              </el-switch>
            </div>
            <div class="search-item">
              <span class="search-item-title">题型搜索条件：</span>
              <span
                v-for="(v, i) in getExamTypeList"
                :key="i"
                class="know-list type-list"
                :class="{ on: indexType == v.value }"
                @click="typeClick(v, 'indexType')"
                >{{ v.label }}</span
              >
            </div>
            <div class="search-item">
              <span class="search-item-title">试题难度：</span>
              <span
                v-for="(v, i) in difficultyList"
                :key="i"
                class="know-list difficulty-list"
                :class="{ on: indexDifficulty == v.value }"
                @click="typeClick(v, 'indexDifficulty')"
                >{{ v.label }}</span
              >
              <template v-if="indexDifficulty == 999">
                <el-input
                  v-model="difficultyMin"
                  class="difficulty-input"
                  placeholder=""
                ></el-input>
                <hr class="hr" />
                <el-input
                  v-model="difficultyMax"
                  class="difficulty-input"
                  placeholder=""
                ></el-input>
              </template>
              <el-button plain class="search" @click="condition()">
                搜索题目
              </el-button>
            </div>
          </div>
          <!-- 试题列表 -->
          <div v-loading="loading" class="question-list-box">
            <template v-if="trainingQuestionList.length > 0">
              <div
                v-for="(v, i) in trainingQuestionList"
                :key="i"
                class="question-list"
                :class="{
                  'on-succ': !getIndexQuestionType(v.id),
                }"
              >
                <div class="question-header">
                  <div>
                    {{ i + 1 }} - <span class="header-type">同类题</span> -
                    <template>
                      {{ v.type | setName($parent.examTypeList) }}
                    </template>
                  </div>
                  <div class="question-header-right">
                    <el-popover
                      v-if="v.examList && v.examList.length > 0"
                      placement="bottom"
                      width="300"
                      trigger="hover"
                    >
                      <div
                        v-for="(m, n) in v.examList"
                        :key="n"
                        class="exam-list-box"
                      >
                        <span>{{ m.name }}</span>
                        <span>{{ m.examDate }}</span>
                      </div>
                      <el-button slot="reference" type="text"
                        >应用记录</el-button
                      >
                    </el-popover>
                    <div class="tips">
                      <p>试题ID：{{ v.xuekeId }}</p>
                      <el-button type="text" @click="copy(v.xuekeId)">
                        <img
                          src="@/assets/newExam/newpaper/icon_copy.png"
                          alt=""
                        />
                        <img
                          class="on"
                          src="@/assets/newExam/newpaper/icon_copy_on.png"
                          alt=""
                        />
                      </el-button>
                    </div>
                  </div>
                </div>
                <div class="question-html" v-html="v.question"></div>
                <div
                  class="question-btn-box"
                  :class="{ 'show-btn': showAwswerList.indexOf(v.id) != -1 }"
                >
                  <div></div>
                  <div
                    class="ans-box"
                    :class="{
                      'ans-on': showAwswerList.indexOf(v.id) != -1,
                    }"
                  >
                    <img
                      src="@/assets/newExam/newpaper/icon_check.png"
                      alt=""
                    />
                    <span type="text" @click="showAwswer(v)">
                      <template v-if="showAwswerList.indexOf(v.id) != -1">
                        隐藏解析
                      </template>
                      <template v-if="showAwswerList.indexOf(v.id) == -1">
                        显示解析
                      </template>
                    </span>
                  </div>

                  <div :key="boxKey" class="question-btn-right">
                    <el-button
                      type="text"
                      style="margin-left: 12px"
                      @click="showEditQ(v, 1)"
                    >
                      修改
                    </el-button>

                    <span
                      v-if="getIndexQuestionType(v.id)"
                      class="tips-icon"
                      @click="lockingVariation(v)"
                    >
                      补充此题
                    </span>
                    <span
                      v-else
                      class="tips-icon tips-icon-on"
                      @click="cancelVariation(v)"
                    >
                      取消补充
                    </span>
                  </div>
                </div>
                <answerBox
                  v-if="showAwswerList.indexOf(v.id) != -1"
                  :id="v.id"
                ></answerBox>
              </div>
            </template>
          </div>

          <el-divider>
            <template v-if="current != pages">加载中...</template>
            <template v-else> 没有找到更多了</template>
          </el-divider>
        </template>
        <!-- 分层训练题 -->
        <template v-if="indexKey == 4">
          <div class="search-box">
            <div class="search-left">
              <div
                v-for="(v, i) in layeredList"
                :key="i"
                class="filter-item"
                :class="{ on: layeredTypes == v.val }"
                @click="clicklayerIndexType(v, 'layeredTypes')"
              >
                {{ v.name }}
                <!-- <template v-if="v.number">（{{ v.number }}）</template> -->
              </div>
            </div>
          </div>
          <div class="search-item-box layered-search-list">
            <div class="layered-search">
              <div
                v-for="(v, i) in variationBtn"
                :key="i"
                class="search-cnt"
                :class="{ on: indexLayeredVariationBtn == v.val }"
                @click="clickIndexLayeredType(v)"
              >
                {{ v.name }}
              </div>
              <!-- <div class="search-cnt" @click="showUpLoadFile">自主上传试题</div> -->
              <div class="search-cnt" @click="clickIndexTypeModel('2')">
                自主上传试题
              </div>
              <div class="search-cnt" @click="clickIndexTypeModel('3')">
                上传图片搜题
              </div>
              <el-input v-model="inheritVal" placeholder="输入试题ID或者关键字">
                <i
                  slot="suffix"
                  class="el-icon-search el-input__icon"
                  @click="inherit"
                >
                </i>
              </el-input>
            </div>

            <div class="search-item">
              <span class="search-item-title">知识点条件搜索：</span>
              <span v-for="(v, i) in knowledgeNames" :key="i" class="know-list">
                <i class="el-icon-remove" @click="delKnowList(i)"></i>
                {{ v }}
              </span>
              <span class="size-btn" @click="showChangeKnow">
                <i class="el-icon-plus"></i>添加
              </span>
            </div>
            <div class="search-item" style="padding: 4px 0 3px">
              <span>知识点交集/并集：</span>
              <el-switch
                v-model="knowSearchType"
                active-text="交集"
                inactive-text="并集"
              >
              </el-switch>
            </div>
            <div class="search-item">
              <span class="search-item-title">题型搜索条件：</span>
              <span
                v-for="(v, i) in getExamTypeList"
                :key="i"
                class="know-list type-list"
                :class="{ on: indexType == v.value }"
                @click="typeClick(v, 'indexType')"
                >{{ v.label }}</span
              >
            </div>
            <div class="search-item">
              <span class="search-item-title">试题难度：</span>
              <span
                v-for="(v, i) in difficultyList"
                :key="i"
                class="know-list difficulty-list"
                :class="{ on: indexDifficulty == v.value }"
                @click="typeClick(v, 'indexDifficulty')"
                >{{ v.label }}</span
              >
              <template v-if="indexDifficulty == 999">
                <el-input
                  v-model="difficultyMin"
                  class="difficulty-input"
                  placeholder=""
                ></el-input>
                <hr class="hr" />
                <el-input
                  v-model="difficultyMax"
                  class="difficulty-input"
                  placeholder=""
                ></el-input>
              </template>
              <el-button plain class="search" @click="condition()"
                >搜索题目</el-button
              >
            </div>
          </div>

          <div class="question-list-box">
            <template v-if="trainingQuestionList.length > 0">
              <div
                v-for="(v, i) in trainingQuestionList"
                :key="i + 'question-list'"
                class="question-list"
                :class="{ 'on-succ': !getIndexLayeredType(v.id) }"
              >
                <div class="question-header">
                  <div>
                    {{ i + 1 }} - <span class="header-type">拓展题</span> -
                    {{ v.type | setName($parent.examTypeList) }}
                  </div>
                  <div class="question-header-right">
                    <!-- <el-button
                    type="text"
                    style="margin-right: 8px"
                    @click="showDiffModel(v)"
                  >
                    分享至其他拓展层
                  </el-button> -->
                    <el-popover
                      v-if="v.extendList && v.extendList.length > 0"
                      placement="bottom"
                      width="300"
                      trigger="hover"
                    >
                      <div
                        v-for="(m, n) in v.extendList"
                        :key="n"
                        class="exam-list-box"
                      >
                        <span>{{ m.name }}</span>
                        <span>{{ m.examDate }}</span>

                        <span v-if="m.difficultyType == 1"> 较难 </span>
                        <span v-if="m.difficultyType == 2"> 中等 </span>
                        <span v-if="m.difficultyType == 3"> 较易 </span>
                      </div>
                      <el-button slot="reference" type="text"
                        >应用记录</el-button
                      >
                    </el-popover>

                    <div class="tips">
                      <p>试题ID：{{ v.xuekeId }}</p>
                      <el-button type="text" @click="copy(v.xuekeId)">
                        <img
                          src="@/assets/newExam/newpaper/icon_copy.png"
                          alt=""
                        />
                        <img
                          class="on"
                          src="@/assets/newExam/newpaper/icon_copy_on.png"
                          alt=""
                        />
                      </el-button>
                    </div>
                  </div>
                </div>
                <div class="question-html" v-html="v.question"></div>
                <div
                  class="question-btn-box"
                  :class="{ 'show-btn': showAwswerList.indexOf(v.id) != -1 }"
                >
                  <div class="tips"></div>
                  <div
                    class="ans-box"
                    :class="{
                      'ans-on': showAwswerList.indexOf(v.id) != -1,
                    }"
                  >
                    <img
                      src="@/assets/newExam/newpaper/icon_check.png"
                      alt=""
                    />
                    <span type="text" @click="showAwswer(v)">
                      <template v-if="showAwswerList.indexOf(v.id) != -1">
                        隐藏解析
                      </template>
                      <template v-if="showAwswerList.indexOf(v.id) == -1">
                        显示解析
                      </template>
                    </span>
                  </div>
                  <div :key="boxKey" class="question-btn-right">
                    <el-button
                      type="text"
                      style="margin-left: 12px"
                      @click="showEditQ(v, 4)"
                    >
                      修改
                    </el-button>
                    <span
                      v-if="getIndexLayeredType(v.id)"
                      class="tips-icon"
                      @click="trainingExtend(v, 1)"
                    >
                      补充此题
                    </span>
                    <span
                      v-else
                      class="tips-icon tips-icon-on"
                      @click="trainingExtend(v, 2)"
                    >
                      取消补充
                    </span>
                  </div>
                </div>
                <answerBox
                  v-if="showAwswerList.indexOf(v.id) != -1"
                  :id="v.id"
                ></answerBox>
              </div>
            </template>
          </div>

          <el-divider>
            <template v-if="current != pages">加载中...</template>
            <template v-else> 没有找到更多了</template>
          </el-divider>
        </template>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">
          确 定
        </el-button>
      </span>
    </el-dialog>
    <knowledge ref="knowledge"></knowledge>
    <unloadQuestion ref="unloadQuestion"></unloadQuestion>
    <editQuestion ref="editQuestion"></editQuestion>
    <el-dialog
      title="选择补充题目类型"
      :visible.sync="dialogVisibleTips"
      width="304px"
      class="question-type"
    >
      <div>选择一种题型</div>
      <div class="question-type-box">
        <div @click="goType(3)">同类题</div>
        <div @click="goType(4)">分层拓展题</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-checkbox v-model="checkedTips">不再提示</el-checkbox>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getRecommend,
  trainingList,
  examRandom,
  getList,
  lockTraining,
  unlockTraining,
  trainingExtend,
  postTrainingList,
} from "@/core/api/exam/examLore";
import knowledge from "./knowledge";
import { mapGetters } from "vuex";
import unloadQuestion from "./unloadQuestion.vue";
import editQuestion from "./editQuestion";
import { getStore, setStore } from "@/core/util/store";
const variationBtn = [
  {
    name: "举一反三",
    val: 1,
  },
  {
    name: "原图搜索",
    val: 2,
  },
  {
    name: "按条件搜索",
    val: 3,
  },
];
const questionType = [
  {
    label: "补充同类题",
    value: 3,
  },
  {
    label: "补充分层拓展题",
    value: 4,
  },
];
import answerBox from "@/components/answer";
export default {
  name: "SupplementaryQuestion",
  components: { answerBox, knowledge, unloadQuestion, editQuestion },
  data() {
    return {
      variationBtn: variationBtn,
      dialogVisible: false,
      checkedTips: true,
      dialogVisibleTips: false,
      trainingQuestionList: [],
      getExamTypeList: [],
      current: 1,
      boxKey: 1,
      pages: 1,
      indexVariationBtn: 1,
      questionType: questionType,
      indexKey: 3,
      indexType: 1,
      newKeys: 3,
      knowledgeNames: [],
      showAwswerList: [],
      knowledgeIds: [],
      showErrList: [],
      indexDifficulty: 0,
      inheritVal: "",
      loading: true,
      knowSearchType: false,
      TreeArray: [],
      twoTreeArray: [],
      twoTreeVal: "",
      twoTree: "",
      indexLayeredVariationBtn: 1,
      layeredTypes: 1,
    };
  },
  computed: {
    ...mapGetters([
      "getPaperKnowList",
      "getPaperTreeKnowList",
      "getExamPaper",
      "GET_TEACHER_AUTH",
    ]),
  },
  watch: {},
  created() {},
  methods: {
    showEditQ(v, editType) {
      // console.log(v);
      this.$refs.editQuestion.init(v, this.indexQuestion, editType);
    },
    addQuestion(list) {
      this.dialogVisible = true;
      this.trainingQuestionList = list.concat(this.trainingQuestionList);
    },
    //上拉加载
    disposeScroll() {
      if (this.current == this.pages) {
        return;
      } // 如果当前大于总页数，也就是最后一页，那么就不不触发了
      let time = new Date().getTime();
      if (time - this.reqTime < 2000) {
        return;
      }
      let top =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0; // 获取滚动条垂直的位置
      if (document.body.clientHeight - top - window.innerHeight == 0) {
        this.current++;
        this.reqTime = time;

        this.onloadView();

        //如果滚动条的位置加上窗口的高度大于可见的窗口可见的高度，那么也就是滚动条到低了
      }
    },
    typeClick(v, key) {
      this[key] = v.value;
    },
    submitQuestion(data) {
      if (this.indexKey == 3) {
        // this.$message({
        //   showClose: true,
        //   message: "已补充至变式题!",
        //   type: "success",
        // });
        // this.$store.commit("SET_NEW_VIEW");

        this.lockTraining(data, 1);
      }
      if (this.indexKey == 4) {
        data.id = data.examQuestionOpt;
        this.trainingExtend(data, 1, "new");
      }
    },
    // 提交新增变式训练题
    lockTraining(data, type) {
      if (!data.id) {
        data.id = this.indexQuestion.id;
      } else {
        onload = true;
      }

      if (this.btnDisabled) {
        this.$message({
          message: "网络状态不佳，请不要快速点击！",
          type: "warning",
        });
        return;
      }
      data.type = 3;
      this.btnDisabled = true;
      lockTraining(data)
        .then(() => {
          this.btnDisabled = false;
          //type为1就是在锁定知识点

          this.$store.commit("putTraining", data);
          this.indexQuestion.examQuestionExtendList.push({
            examQuestionOpt: data.examQuestionOpt,
            type: 3,
          });
          this.indexQuestion = JSON.parse(JSON.stringify(this.indexQuestion));
          if (type) {
            this.$message({
              showClose: true,
              message: "修改成功,已补充至变式题!",
              type: "success",
            });
          }
          this.setList(data);
          this.$store.commit("SET_NEW_VIEW");
        })
        .catch(() => {
          this.btnDisabled = false;
        });
    },
    lockingVariation(val) {
      let data = {
        examQuestionOpt: val.id,
      };
      this.lockTraining(data);
    },
    // 触发搜索方法
    getSearchList() {
      if (this.indexVariationBtn == 1) {
        this.getIdList();
      }
      if (this.indexVariationBtn == 2) {
        this.trainingList();
      }
      if (this.indexVariationBtn == 3) {
        this.condition();
      }
    },
    // 条件搜索
    condition() {
      let data = {
        knowledgeId: this.knowledgeIds.join("#"),
        questionId: this.indexQuestion.examQuestionId,
        beginIndex: this.current,
        examPaperId: this.$route.query.id,
        quesDiff: this.indexDifficulty,
        quesType: this.indexType,
      };
      if (this.knowSearchType) {
        data.knowledgeGatherType = 2;
      } else {
        data.knowledgeGatherType = 0;
      }
      examRandom(data)
        .then((res) => {
          this.setDataList(res.data.data);
        })
        .catch(() => {
          this.pages = this.current;
        });
    },
    setDataList(data) {
      if (this.current == 1) {
        this.trainingQuestionList = data;
      } else {
        this.trainingQuestionList = this.trainingQuestionList.concat(data);
      }
      this.setQuestion();
      this.loading = false;
      this.$nextTick(() => {
        window.MathJyeLoad.LayOut(document.body);
      });
    },
    //获取当前试题是否是当前锁定项
    getIndexLayeredType(id) {
      let type = true;
      this.indexQuestion.examQuestionExtendList.forEach((index) => {
        if (
          index.examQuestionOpt == id &&
          index.difficultyType == this.layeredTypes
        ) {
          type = false;
        }
      });
      return type;
    },
    //提交取消变式训练题
    unlockTraining(data) {
      // let data = {};
      data.id = this.indexQuestion.id;
      if (this.btnDisabled) {
        this.$message({
          message: "网络状态不佳，请不要快速点击！",
          type: "warning",
        });
        return;
      }
      this.btnDisabled = true;
      unlockTraining(data)
        .then(() => {
          this.btnDisabled = false;
          this.$store.commit("SET_NEW_VIEW");
          this.indexQuestion.examQuestionExtendList =
            this.indexQuestion.examQuestionExtendList.filter(
              (item) =>
                item.examQuestionOpt != data.examQuestionOpt && item.type == 3
            );
          this.indexQuestion = JSON.parse(JSON.stringify(this.indexQuestion));
          this.setList(data, 1);
        })
        .catch(() => {
          this.pages = this.current;
          this.btnDisabled = false;
        });
    },
    //设置已考试列表
    setList(data, type) {
      //type == 1 就是删除  trainingQuestionList
      this.trainingQuestionList.map((index) => {
        if (index.id == data.examQuestionOpt) {
          // index.examList.map((item) => {
          if (type == 1) {
            index.examList = index.examList.filter(
              (i) => i.name != this.getExamPaper.name
            );
          } else {
            index.examList.push({
              name: this.getExamPaper.name,
              examDate: "当前考试",
            });
          }
        }
      });

      this.boxKey = new Date().getTime();
    },
    // 变式训练题取消
    cancelVariation(val) {
      let data = {
        examQuestionOpt: val.id,
      };
      this.unlockTraining(data);
      // console.log(val);
    },
    // 变式训练题列表
    trainingList() {
      if (this.current == 1) {
        this.loading = true;
      }

      let page = this.current;
      let { examQuestionId } = this.indexQuestion;
      trainingList(examQuestionId, this.current, this.$route.query.id)
        .then((res) => {
          this.loading = false;
          if (page == 1) {
            this.current = page;
          }
          this.setDataList(res.data.data);
        })
        .catch(() => {
          this.pages = this.current;
          this.loading = false;
        });
    },
    //举一反三
    getIdList() {
      this.trainingQuestionList = [];
      let { examQuestionId, recommendXuekeId } = this.indexQuestion;

      getRecommend(examQuestionId, recommendXuekeId, this.$route.query.id)
        .then((res) => {
          this.pages = 1;
          this.current = 1;
          this.setDataList(res.data.data);
        })
        .catch(() => {
          this.pages = this.current;
          this.loading = false;
        });
    },
    //分层变式训练
    clickIndexLayeredType(v) {
      this.indexLayeredVariationBtn = v.val;
      // this.oldDataList = [];
      this.current = 1;
      this.getSearchLayered();
    },
    //页面加载
    onloadView() {
      if (this.indexKey == 3) {
        this.getSearchList();
      }
      if (this.indexTypes == 4) {
        this.getSearchLayered();
      }
    },
    getSearchLayered() {
      if (this.indexLayeredVariationBtn == 1) {
        this.condition();
      }
      if (this.indexLayeredVariationBtn == 2) {
        this.trainingList();
      }
      if (this.indexLayeredVariationBtn == 3) {
        this.getIdList();
      }
    },
    //显示答案组件
    showAwswer(v) {
      if (this.showAwswerList.indexOf(v.id) == -1) {
        this.showAwswerList.push(v.id);
      } else {
        this.showAwswerList.splice(this.showAwswerList.indexOf(v.id), 1);
      }
    },

    clickIndexType(v) {
      this.indexVariationBtn = v.val;
      this.getSearchList();
    },
    clickIndexTypeModel(val) {
      this.$refs.unloadQuestion.init(val);
      this.dialogVisible = false;
    },
    //显示知识点选择弹出层
    showChangeKnow() {
      this.$refs.knowledge.init(this.knowledgeIds);
    },
    setKnow(data) {
      this.knowledgeIds = data.knowledgeIds.split("#");
      this.knowledgeNames = data.knowledgeNames.split("#");
      this.$refs.knowledge.dialogVisible = false;
    },
    inherit(type) {
      if (this.inheritVal == "") {
        this.$message({
          message: "搜索内容",
          type: "warning",
        });
        return;
      }
      this.loading = true;
      if (type == 1) {
        getList(this.indexQuestion.examQuestionId, this.inheritVal)
          .then((res) => {
            this.loading = false;
            this.pages = 1;
            this.current = 1;
            this.subBtnType = false;
            this.trainingQuestionList = [];
            this.trainingQuestionList.push(res.data.data);
            this.setQuestion();
          })
          .catch(() => {
            this.pages = this.current;
            this.loading = false;
          });
      } else {
        postTrainingList(this.indexQuestion.examQuestionId, {
          quesKeyword: this.inheritVal,
          isNeedExamList: 1,
        })
          .then((res) => {
            this.loading = false;
            this.pages = 1;
            this.current = 1;
            this.subBtnType = false;

            this.trainingQuestionList = res.data.data;
            this.setQuestion();
          })
          .catch(() => {
            this.pages = this.current;
            this.loading = false;
          });
      }
    },
    setQuestion() {
      let arr = [];
      this.indexQuestion.examQuestionExtendList.map((item) => {
        if (this.indexKey == 3 ? item.type == 1 : item.type == 2) {
          arr.push(item.id);
        }
      });
      this.trainingQuestionList = this.trainingQuestionList.filter(
        (item) => arr.indexOf(item.id) == -1
      );
    },
    copy(v) {
      this.$store.commit("SET_ALL_COPY", v);
    },
    getIndexQuestionType(id) {
      let type = true;

      this.indexQuestion.examQuestionExtendList.map((item) => {
        if (item.examQuestionOpt == id && item.type == 3) {
          type = false;
        }
      });

      return type;
    },
    showList({ id }) {
      let type = true;

      this.indexQuestion.examQuestionExtendList.map((item) => {
        if (item.examQuestionOpt == id && item.type == 1) {
          type = false;
        }
      });

      return type;
    },
    goType(type) {
      this.dialogVisibleTips = false;
      this.indexKey = type;
      this.initTMolde();
      setStore({
        name: "checkedTips",
        content: this.checkedTips,
      });
    },

    init(v) {
      this.indexQuestion = JSON.parse(JSON.stringify(v));
      let checkedTips = getStore({
        name: "checkedTips",
      });
      if (this.GET_TEACHER_AUTH === 0 && !checkedTips) {
        this.dialogVisibleTips = true;
        this.checkedTips = checkedTips ? true : false;
      } else {
        if (this.GET_TEACHER_AUTH == 1) {
          this.indexKey = 3;
        }
        if (this.GET_TEACHER_AUTH == 2) {
          this.indexKey = 4;
        }
        this.initTMolde();
      }

      this.getExamTypeList = this.$parent.getExamTypeList;
      this.difficultyList = this.$parent.difficultyList;
      this.layeredList = this.$parent.layeredList;
      this.trainingQuestionList = [];

      this.knowledgeIds = this.indexQuestion.knowledgeIds.split("#");
      this.knowledgeNames = this.indexQuestion.knowledgeNames.split("#");
      this.knowledgeAllList = this.getPaperTreeKnowList;
      this.loreArr = this.getPaperKnowList;
    },
    initTMolde() {
      this.onloadView();
      this.dialogVisible = true;
    },
    checkItem(value) {
      this.indexKey = value;
      this.onloadView();
    },
    //变式训练题请款获取当前试题有几道变式训练题
    setVariation() {
      let simple = 0;
      let commonly = 0;
      let difficult = 0;

      if (
        !this.getIndexQuestion ||
        !this.getIndexQuestion.examQuestionExtendList
      ) {
        return;
      }
      this.getIndexQuestion.examQuestionExtendList.forEach((index) => {
        if (index.difficultyType == 1) {
          difficult++;
        }
        if (index.difficultyType == 2) {
          commonly++;
        }
        if (index.difficultyType == 3) {
          simple++;
        }
      });
      this.layeredList[0].number = simple;
      this.layeredList[1].number = commonly;
      this.layeredList[2].number = difficult;
    },
    //锁定  取消分层变式训练题
    trainingExtend(val, type, reqType) {
      // difficultyType	分层区间（难度类型）：较难:1 /中等:2/ 较易:3		false
      // examPaperId	试卷ID		false
      // examQuestionId	试题ID		false
      // examQuestionOpt	变式训练题ID		false
      // id			false
      // type 区分新增、删除。1：新增，2：删除
      let data = {
        examQuestionOpt: val.id,
        examQuestionId: this.indexQuestion.examQuestionId,
        examPaperId: this.indexQuestion.examPaperId,
        type: type,
        difficultyType: this.layeredTypes,
      };

      data.changingType = 4;
      trainingExtend(data).then(() => {
        data.status = 0;
        // this.$store.commit("changeQuestion", data);
        if (reqType) {
          // this.dialogVisible = false;
          this.$message({
            showClose: true,
            message: "修改成功,已补充至分层变式题!",
            type: "success",
          });
        }
        this.$store.commit("SET_NEW_VIEW");
        this.setExtendList(data);
      });
    },
    //设置分层考试列表
    setExtendList(data) {
      if (data.type == 1) {
        data.type = data.changeType;
        this.indexQuestion.examQuestionExtendList.push(data);
      } else {
        this.indexQuestion.examQuestionExtendList =
          this.indexQuestion.examQuestionExtendList.filter(
            (item) =>
              item.examQuestionOpt != data.examQuestionOpt ||
              (item.examQuestionOpt == data.examQuestionOpt &&
                item.difficultyType != data.difficultyType)
          );
      }
      this.trainingQuestionList.map((index) => {
        if (index.id == data.examQuestionOpt) {
          if (data.type == 2) {
            index.extendList = index.extendList.filter(
              (i) =>
                i.name != this.getExamPaper.name &&
                i.difficultyType != data.difficultyType
            );
          } else {
            index.extendList.push({
              name: this.getExamPaper.name,
              examDate: "当前考试",
              difficultyType: data.difficultyType,
            });
          }
        }
      });
      this.boxKey = new Date().getTime();
    },
    clicklayerIndexType(v, name) {
      this[name] = v.val;
      this.current = 1;
      this.trainingQuestionList = [];
      if (this.layeredTypes == 1) {
        this.indexDifficulty = 0.1;
      }
      if (this.layeredTypes == 2) {
        this.indexDifficulty = 0.5;
      }
      if (this.layeredTypes == 3) {
        this.indexDifficulty = 0.9;
      }
      this.oldDataList = [];
      this.current = 1;
      this.$store.commit("SET_LAYER_TYPS", this.layeredTypes);
      this.onloadView();
      this.setVariation();
    },
  },
};
</script>
<style lang="scss" scoped>
.supplementaryQuestion {
  .search {
    color: $primary-color;
    border: 1px solid $primary-color;
    padding: 0;
    margin: 4px 8px 3px 0;
    padding: 0 8px;
    line-height: 21px;
    &:hover {
      background-color: $primary-color;
      color: #ffffff;
    }
  }
  .question-type {
    ::v-deep {
      .el-dialog__footer {
        text-align: center;
      }
    }
  }
  .question-type-box {
    display: flex;
    justify-content: space-between;
    text-align: center;
    margin-top: 12px;
    div {
      width: 120px;
      height: 80px;
      line-height: 80px;
      border-radius: 4px;
      cursor: pointer;
      border: 1px solid #dee0e7;
      &:hover {
        background: #f3f8ff;
        border-radius: 4px;
        border: 1px solid #2474ed;
        color: #2474ed;
      }
    }
  }
  .question-item-box {
    height: calc(100vh - 340px);
  }
  .on-succ.question-list {
    border-color: $primary-color;
    .question-btn-box {
      display: flex;
    }
  }
  .layered-search {
    margin-top: 8px;
    display: flex;
    align-items: center;
    ::v-deep {
      .el-input__inner {
        line-height: 28px;
        height: 28px;
      }
      .el-input__icon {
        line-height: 28px;
      }
    }
    .search-cnt {
      padding: 6px 8px;
      border-radius: 4px;
      border: 1px solid #d5d6db;
      margin-right: 8px;
      line-height: 14px;
      cursor: pointer;

      &:hover {
        border-color: $primary-color;
        color: #ffffff;
        background-color: $primary-color;
      }
    }
    .on {
      border-color: $primary-color;
      color: #ffffff;
      background-color: $primary-color;
    }
  }
  .question-list {
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    margin-bottom: 12px;
    position: relative;
    overflow: hidden;
    min-height: 40px;
    .question-header {
      background: #f9fafc;
      border-bottom: 1px solid #e9e9e9;
      padding: 10px 18px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .header-type {
        color: $primary-color;
      }
      .question-header-right {
        align-items: center;
        display: flex;
        justify-content: flex-end;
        width: 600px;
        line-height: 16px;
        .tips {
          display: flex;

          margin-left: 12px;
          p {
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 300px;
            color: $primary-color;
          }
          span {
            margin-left: 12px;
            cursor: pointer;
          }
        }
      }
    }
    &:hover {
      border-color: $primary-color;
      .question-btn-box {
        display: flex;
      }
    }
    .question-btn-box {
      // opacity: 0;
      // visibility: hidden;
      background: #f9fafc;
      border: 1px solid #e0e0e0;
      padding: 5px 12px;
      width: 100%;
      display: none;
      align-items: center;
      justify-content: space-between;
      position: relative;
      .err-span {
        color: #ed2424;
      }
      .succ-span {
        color: #2474ed;
        align-items: center;
        display: flex;
        img {
          margin-right: 4px;
        }
      }
      .ans-box {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        transition: 0.2s linear;
        cursor: pointer;
        user-select: none;
        img {
          width: 18px;
        }
      }
      .ans-on {
        img {
          transform: rotate(180deg);
        }
      }
      .tips {
        display: flex;
        width: 30%;
        p {
          margin: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 60%;
          color: $primary-color;
        }
        span {
          margin-left: 12px;
          cursor: pointer;
        }
      }
      .question-btn-right {
        display: flex;
        align-items: center;
        .tips-icon {
          cursor: pointer;
          color: #0a1119;
          color: $primary-color;
          img {
            width: 16px;
            vertical-align: text-bottom;
          }
        }
        .tips-icon-on {
          color: #ed2424;
        }

        .el-button {
          padding: 4px 8px;
          margin-right: 16px;
        }

        // justify-content: space-between;
      }
    }
    .show-btn {
      display: flex;
    }
    .question-html {
      padding: 5px 8px;
    }
  }
  .search-item-box {
    // padding: 0 18px;
    margin-bottom: 12px;
    .search-item {
      margin-top: 8px;
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      width: 80%;
      .type-list {
        cursor: pointer;
      }
      .search-item-title {
        margin: 4px 8px 3px 0;
        line-height: 21px;
      }
      > span {
        margin-right: 8px;
      }
      .size-btn {
        color: $primary-color;
        margin: 4px 8px 3px 0;
        cursor: pointer;
      }
      .know-list {
        background: #e6e6e4;
        border-radius: 4px;
        padding: 0 8px;
        color: #000000;
        position: relative;
        margin: 4px 8px 3px 0;
        line-height: 21px;
        &:hover {
          i {
            opacity: 1;
          }
        }
        i {
          // el-icon-remove
          opacity: 0;
          color: red;
          position: absolute;
          right: -8px;
          top: -8px;
          cursor: pointer;
        }
      }
      .difficulty-list {
        background-color: unset;
        color: #333333;
        cursor: pointer;
      }

      .on {
        background-color: $primary-color;
        color: #ffffff;
      }
    }
  }
  .search-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-bottom: 8px;
    .search-box-title {
      font-size: 16px;
      color: #0a1119;
      font-weight: 500;
    }
    .unload-btn {
      padding: 5px 15px;
      border-color: $primary-color;
      color: $primary-color;
      position: absolute;
      right: 0;
      top: 40px;
      background-color: #ffffff;
      &:hover {
        background-color: #ffffff;
        color: $primary-color;
      }
    }
    .btn-all {
      padding: 5px 15px;
    }
    > div {
      display: flex;
      align-items: center;
    }
    .check-btn {
      .el-checkbox {
        height: 32px;
        margin-right: 12px;
      }
      .check-btn-item {
        padding: 4.5px 16px;
        border-radius: 4px;
        border: 1px solid #dee0e7;
        cursor: pointer;
        // line-height: 14px;
        img {
          vertical-align: text-bottom;
        }
        .on {
          display: none;
        }
      }
      .check-btn-item-on {
        border: 1px solid #2474ed;
        color: #2474ed;
        img {
          display: none;
        }
        .on {
          display: inline-block;
        }
      }
    }
    .search-left {
      .filter-item {
        padding: 0 8px;
        line-height: 28px;
        border: 1px solid #e0e0e0;
        color: $primary-color;
        border-radius: 4px;
        overflow: hidden;
        margin-right: 12px;
        cursor: pointer;
        flex-shrink: 0;
        transition: 0.2s linear;
        &:hover {
          border-color: $primary-color;
          color: #ffffff;
          background-color: $primary-color;
        }
      }
      .on {
        border-color: $primary-color;
        color: #ffffff;
        background-color: $primary-color;
      }
    }
  }
  .header-box {
    display: flex;
    align-items: center;
    div {
      margin-right: 40px;
      cursor: pointer;
      &::after {
        width: 80%;
        height: 1px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
      &:hover {
        color: #2474ed;
        position: relative;
        &::after {
          background-color: #2474ed;
          bottom: -18px;
          content: "";
        }
      }
    }
    .on {
      color: #2474ed;
      position: relative;
      &::after {
        background-color: #2474ed;
        bottom: -18px;
        content: "";
      }
    }
  }
}
</style>
