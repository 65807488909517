var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"questionNum edit-scroll-style",class:{ 'postion-box': _vm.postionBox }},[(_vm.$parent.topicType == 0)?[(_vm.$parent.indexTypes == 'VariantTraining')?_c('div',[_c('div',{staticClass:"tab-box"},_vm._l((_vm.tabItem),function(item,index){return _c('div',{key:index,staticClass:"tab-item",class:{ 'tab-item-on': _vm.indexTab == item.value },on:{"click":function($event){return _vm.checkTab(item.value)}}},[_c('p',[_vm._v(_vm._s(_vm.getNums(item.value)))]),_vm._v(" "+_vm._s(item.label)+" ")])}),0),_vm._m(0),_c('div',{staticClass:"order-list-box"},[(_vm.showTextVa())?[_c('div',{staticClass:"no-data"},[_vm._v("当前列表暂无试题")])]:_vm._e(),_vm._l((_vm.getVariationList),function(v,i){return [(_vm.getShow(v))?_c('div',{key:i,staticClass:"order-list",class:{
              on: v.id == _vm.indexQuestion.id,
              err: !v.type,
              succ: v.type,
              voluntarily: v.statusReplaceTraining,
            },on:{"click":function($event){return _vm.questionVariation(v)}}},[_c('div',{staticClass:"question-nums"},[_c('span',{staticClass:"question-num-tips"},[_c('el-tooltip',{attrs:{"effect":"dark","content":v.examQuestionOrder,"placement":"top"}},[_c('span',{ref:'textRef' + (i + 1),refInFor:true},[_vm._v(" "+_vm._s(v.examQuestionOrder)+" ")])])],1),(v.statusReplaceTraining == 1)?[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"由系统智能配题","placement":"top"}},[_c('span',[_c('img',{attrs:{"src":require("@/assets/newExam/newpaper/icon_ai.png"),"alt":""}}),_c('img',{staticClass:"ai-on",attrs:{"src":require("@/assets/newExam/newpaper/icon_ai_on.png"),"alt":""}})])])]:_vm._e()],2),_c('div',{staticClass:"order-tips",class:{
                'nums-tips-bs': v.nums > 0,
                'tips-default': _vm.getClass(1, 1, v),
                'tips-refuse': _vm.getClass(1, 2, v),
                'tips-portion': _vm.getClass(1, 3, v),
                'tips-succ': _vm.getClass(1, 4, v),
              }},[(v.nums == 0 && v.id == _vm.indexQuestion.id)?[_vm._v(" 无变式题 ")]:[_c('span',[_vm._v("变式题")]),_vm._v(" "+_vm._s(v.nums)+" ")]],2)]):_vm._e()]})],2)]):_vm._e(),(_vm.$parent.indexTypes == 'layeredVariantTraining')?_c('div',[(_vm.getVariationList.length > 0)?_c('div',{staticClass:"tab-box"},_vm._l((_vm.tabItem),function(item,index){return _c('div',{key:index,staticClass:"tab-item",class:{ 'tab-item-on': _vm.indexTab == item.value },on:{"click":function($event){return _vm.checkTab(item.value)}}},[_c('p',[_vm._v(_vm._s(_vm.getNumsLayer(item.value)))]),_vm._v(" "+_vm._s(item.label)+" ")])}),0):_vm._e(),_vm._m(1),_c('div',{staticClass:"order-list-box"},[(_vm.showTextLaVa())?[_c('div',{staticClass:"no-data"},[_vm._v("当前列表暂无试题")])]:_vm._e(),_vm._l((_vm.getExamQuestion),function(v,i){return [(_vm.getShowLayer(v))?_c('div',{key:i,staticClass:"order-list",class:{
              on: v.id == _vm.indexQuestion.id,
            },on:{"click":function($event){return _vm.questionListClick(v)}}},[_c('span',{staticClass:"question-num-tips"},[_c('el-tooltip',{attrs:{"effect":"dark","content":v.examQuestionOrder,"placement":"top"}},[_c('span',{ref:'textRef' + (i + 1),refInFor:true},[_vm._v(" "+_vm._s(v.examQuestionOrder)+" ")])])],1),_c('div',{staticClass:"order-tips",class:{
                'nums-tips-bs': _vm.getShowValue(v) > 0,
                'tips-default': _vm.getClass(2, 1, v),
                'tips-refuse': _vm.getClass(2, 2, v),
                'tips-portion': _vm.getClass(2, 3, v),
                'tips-succ': _vm.getClass(2, 4, v),
              }},[(_vm.getShowValue(v) == 0 && v.id == _vm.indexQuestion.id)?[_vm._v(" 无分层变式题 ")]:[_c('span',[_vm._v("分层变式题")]),_vm._v(" "+_vm._s(_vm.getShowValue(v))+" ")]],2)]):_vm._e()]})],2)]):_vm._e()]:_vm._e(),(_vm.$parent.topicType == 1)?[_c('div',[_c('div',{staticClass:"tab-box"},_vm._l((_vm.tabItem),function(item,index){return _c('div',{key:index,staticClass:"tab-item",class:{ 'tab-item-on': _vm.indexTab == item.value },on:{"click":function($event){return _vm.checkTab(item.value)}}},[_c('p',[_vm._v(_vm._s(_vm.getNumsAudit(item.value)))]),_vm._v(" "+_vm._s(item.labelA)+" ")])}),0),_c('div',{staticClass:"order-list-box"},[(_vm.showText())?[_c('div',{staticClass:"no-data"},[_vm._v("当前列表暂无试题")])]:_vm._e(),_vm._l((_vm.getVariationList),function(v,i){return [(_vm.getShowAudit(v))?_c('div',{key:i,staticClass:"order-list",class:{
              on: v.id == _vm.indexQuestion.id,
            },on:{"click":function($event){return _vm.questionVariation(v)}}},[_c('span',{staticClass:"question-num-tips"},[_c('el-tooltip',{attrs:{"effect":"dark","content":v.examQuestionOrder,"placement":"top"}},[_c('span',{ref:'textRef' + (i + 1),refInFor:true},[_vm._v(" "+_vm._s(v.examQuestionOrder)+" ")])])],1),_c('div',{staticClass:"tips-box"},[(_vm.getNumsTypeShow(v))?_c('div',{staticClass:"nums-tips order-tips order-tips-b"},[_c('el-tooltip',{attrs:{"effect":"dark","content":"他人补充过变式题","placement":"top"}},[_c('span',[_vm._v("补")])])],1):_vm._e(),_c('div',{staticClass:"order-tips",class:{
                  'nums-tips': _vm.getcNumsErrTypes(v),
                }},[_c('el-tooltip',{attrs:{"effect":"dark","content":"变式题过审数/变式题总数","placement":"top"}},[_c('span',[_vm._v(" "+_vm._s(_vm.getcNums(v))+" / "+_vm._s(v.examQuestionExtendList.length)+" ")])])],1)])]):_vm._e()]})],2),_c('div',{staticClass:"submit-push"},[_c('el-button',{attrs:{"type":"primary","loading":_vm.btnLoading},on:{"click":function($event){return _vm.submitPush()}}},[_vm._v("审题完成，推送通知")])],1)])]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tips-item"},[_c('div',[_c('span',{staticClass:"tips-icon tips-default"}),_vm._v("待审核")]),_c('div',[_c('span',{staticClass:"tips-icon tips-refuse"}),_vm._v("全部拒绝")]),_c('div',[_c('span',{staticClass:"tips-icon tips-portion"}),_vm._v("部分通过")]),_c('div',[_c('span',{staticClass:"tips-icon tips-succ"}),_vm._v("全部通过")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tips-item"},[_c('div',[_c('span',{staticClass:"tips-icon tips-default"}),_vm._v("待审核")]),_c('div',[_c('span',{staticClass:"tips-icon tips-refuse"}),_vm._v("全部拒绝")]),_c('div',[_c('span',{staticClass:"tips-icon tips-portion"}),_vm._v("部分通过")]),_c('div',[_c('span',{staticClass:"tips-icon tips-succ"}),_vm._v("全部通过")])])
}]

export { render, staticRenderFns }