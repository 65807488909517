<template>
  <div class="questionIndex">
    <div ref="flexdL" class="left-box">
      <div class="left-box-header">
        <!-- <div>原题显示：<el-checkbox v-model="showQuestion"></el-checkbox></div> -->
        <div class="header-box">
          <div class="header-re">
            难度：
            <el-dropdown>
              <span class="el-dropdown-link">
                {{ indexQuestion.difficulty | setName(difficultyList) }}
                <i class="el-icon-caret-bottom el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="item in difficultyList"
                  :key="item.value"
                  :disabled="item.disabled"
                  @click.native="changeDiffculty(item.value)"
                >
                  {{ item.label }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            科目：
            <el-dropdown>
              <span class="el-dropdown-link">
                {{ indexQuestion.subjectId | setName(GET_SUBJECT_Q_LIST) }}
                <i class="el-icon-caret-bottom el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="item in GET_SUBJECT_Q_LIST"
                  :key="item.value"
                  :disabled="item.disabled"
                  @click.native="changeSubject(item.value)"
                >
                  {{ item.label }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            题型：
            <el-dropdown>
              <span class="el-dropdown-link">
                {{ indexQuestion.type | setName(examTypeList) }}
                <i class="el-icon-caret-bottom el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="item in examTypeList"
                  :key="item.value"
                  @click.native="changeQuestionType(item.value)"
                >
                  {{ item.name }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>

          <div class="know-list-box">
            <div class="know-left">
              <span> 知识点： </span>
              <div
                v-for="(v, i) in indexQuestion.knowledgeNames"
                :key="i"
                class="konw-list"
              >
                <i class="el-icon-remove" @click="delKnow(i)"></i>
                {{ v }}
              </div>
              <span class="size-btn" @click="showChangeKnow">
                <i class="el-icon-plus"></i>添加
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        ref="questionBox"
        class="question-box"
        :class="{ 'fd-box': postionBox }"
      >
        <div class="question-cnt-box">
          <div
            v-if="showQuestion"
            class="question-box-img-style edit-scroll-style"
          >
            <div
              v-if="!indexQuestion.questionBak"
              v-html="indexQuestion.question"
            ></div>
            <div
              v-if="indexQuestion.questionBak"
              v-html="indexQuestion.questionBak"
            ></div>
          </div>

          <div class="check-question-box" :class="{ 'check-q': !showQuestion }">
            <span class="check-q-text" @click="checkQuestionType()">
              <img src="@/assets/newExam/newpaper/icon_check.png" alt="" />
              <template v-if="showQuestion"> 点击隐藏原题 </template>
              <template v-else>点击显示原题</template>
            </span>
          </div>
        </div>
      </div>
    </div>
    <knowledge ref="knowledge"></knowledge>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { getQuestionPoint, putObj } from "@/core/api/exam/examLore";
import { subjectOptions, difficultyList } from "@/core/util/constdata";
import { filterDifficulty } from "@/core/util/util";
import knowledge from "./knowledge.vue";
export default {
  name: "QuestionIndex",
  components: { knowledge },
  data() {
    return {
      difficultyList: difficultyList,
      subjectOptions: subjectOptions,
      scrollBox: false,
      postionBox: false,
      showQuestion: true,
      indexQuestion: {},
      examTypeList: [],
      knowledgeAllList: [],
    };
  },
  computed: {
    ...mapGetters([
      "GET_QUESTION_TYPE_LIST",
      "GET_SUBJECT_Q_LIST",
      "GET_SUBJECT_ID",
      "getExamPaper",
      "getIndexQuestion",
    ]),
  },
  watch: {
    getIndexQuestion: {
      deep: true,
      handler() {
        let oldQ = JSON.parse(JSON.stringify(this.indexQuestion));
        this.indexQuestion = Object.assign({}, this.getIndexQuestion);
        this.indexQuestion.difficulty = filterDifficulty(
          this.indexQuestion.difficulty
        );
        if (this.GET_QUESTION_TYPE_LIST.length > 0) {
          this.examTypeList = this.GET_QUESTION_TYPE_LIST.filter(
            (item) => item.subjectId == this.indexQuestion.subjectId
          );
        }
        //拆分知识点
        this.setKnowList();
        if (oldQ.subjectId != this.indexQuestion.subjectId) {
          this.getToList();
        }
      },
    },
  },
  created() {
    // this.getToList();
  },
  mounted() {
    //给window添加一个滚动滚动监听事件
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    //离开该页面需要移除这个监听的事件
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    checkQuestionType() {
      this.showQuestion = !this.showQuestion;
      this.$nextTick(() => {
        this.handleScroll();
      });
    },
    handleScroll() {
      let scrollTop = 0;
      if (document.documentElement && document.documentElement.scrollTop) {
        scrollTop = document.documentElement.scrollTop;
      } else if (document.body) {
        scrollTop = document.body.scrollTop;
      }
      let { clientHeight } = this.$refs.questionBox;

      if (scrollTop > 240) {
        this.$refs.flexdL.style.height = clientHeight + 30 + "px";
        this.postionBox = true;
      } else {
        this.$refs.flexdL.style.height = "unset";
        this.postionBox = false;
      }
    },
    // 将知识点拆分为数组
    setKnowList() {
      if (this.indexQuestion.knowledgeIds) {
        this.indexQuestion.knowledgeIds =
          this.indexQuestion.knowledgeIds.split("#");
        this.indexQuestion.knowledgeNames =
          this.indexQuestion.knowledgeNames.split("#");
      } else {
        this.indexQuestion.knowledgeIds = [];
        this.indexQuestion.knowledgeNames = [];
      }
    },
    setTypeList() {
      this.examTypeList = this.GET_QUESTION_TYPE_LIST.filter(
        (item) => item.subjectId == this.indexQuestion.subjectId
      );
    },
    //显示知识点选择弹出层
    showChangeKnow() {
      this.$refs.knowledge.init(this.indexQuestion.knowledgeIds, 5);
    },
    checkQuestion() {
      let indexQuestion = JSON.parse(JSON.stringify(this.getIndexQuestion));
      indexQuestion.knowledgeNames = indexQuestion.knowledgeNames
        ? indexQuestion.knowledgeNames.split("#")
        : [];
      indexQuestion.knowledgeIds = indexQuestion.knowledgeIds
        ? indexQuestion.knowledgeIds.split("#")
        : [];
      this.indexQuestion = indexQuestion;
    },
    delKnow(i) {
      // console.log(i);
      // this.indexQuestion.knowledgeIds.splice(i, 1);
      // this.indexQuestion.knowledgeNames.splice(i, 1);
      let copyData = Object.assign({}, this.indexQuestion);
      copyData.knowledgeIds.splice(i, 1);
      copyData.knowledgeNames.splice(i, 1);
      let data = {
        knowledgeIds: copyData.knowledgeIds.join("#"),
        knowledgeNames: copyData.knowledgeNames.join("#"),
      };
      this.saveQuestion(data);
      // this.$store.commit("changeIdQuestion", data);
    },
    //修改题型
    changeQuestionType(val) {
      this.indexQuestion.type = val;
      let data = {
        type: this.indexQuestion.type,
        subjectId: this.indexQuestion.subjectId,
        knowledgeIds: "",
        knowledgeNames: "",
      };
      this.saveQuestion(data);
    },
    saveQuestion(data, modelType) {
      data.statusKnowledge = 1;
      data.id = this.indexQuestion.examQuestionId;
      putObj(data).then(() => {
        this.$store.commit("changeQuestionId", data);
        //如果存在第二个值  就说明有弹框  直接关闭
        if (modelType) {
          this.$refs[modelType].loading = false;
          this.$refs[modelType].dialogVisible = false;
        }
      });
    },
    setKnow(data) {
      this.saveQuestion(data, "knowledge");
    },
    //修改难度
    changeDiffculty(difficulty) {
      this.indexQuestion.difficulty = difficulty;
      let data = {
        difficulty: this.indexQuestion.difficulty,
      };
      this.saveQuestion(data);
    },
    changeSubject(val) {
      this.indexQuestion.subjectId = val;
      this.getToList();
      this.examTypeList = this.GET_QUESTION_TYPE_LIST.filter(
        (item) => item.subjectId == this.indexQuestion.subjectId
      ).map((item) => {
        item.value = item.id;
        item.label = item.name;
        return item;
      });
      if (this.examTypeList.length > 0) {
        this.indexQuestion.type = this.examTypeList[0].id;
        this.changeQuestionType();
      } else {
        this.indexQuestion.type = "";
      }
      this.$store.commit("SET_SUBJECT_ID", this.indexQuestion.subjectId);
    },
    init() {
      this.getToList();
    },
    //组装知识点的树形结构
    getToList() {
      this.knowledgeAllList = [];
      getQuestionPoint({
        level: this.getExamPaper.level,
        subjectId: this.indexQuestion.subjectId,
      }).then((res) => {
        this.loreArr = res.data.data.resQuestionPoints;
        let arrList = this.loreArr;
        arrList
          .filter((i) => i.rank == 1)
          .forEach((i) => {
            let obj = i;
            obj.child = this.convertToTreeData(arrList, i.number);
            this.knowledgeAllList.push(obj);
          });
        // console.log(res.data.data);
        this.$store.commit("setKnowList", {
          paperKnowList: this.loreArr,
          paperTreeKnowList: this.knowledgeAllList,
        });
      });
    },
    //递归形成树形结构
    convertToTreeData(data, pid) {
      const result = [];
      let temp = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].number.slice(0, -1) == pid) {
          const obj = data[i];
          if (!obj.child) {
            obj.child = new Array();
          }
          temp = this.convertToTreeData(data, data[i].number);
          if (temp.length > 0) {
            obj.child = temp;
          }
          result.push(obj);
        }
      }
      // console.log(result);
      return result;
    },
  },
};
</script>
<style lang="scss" scoped>
.questionIndex {
  .question-box-img-style {
    max-height: 194px;
  }
  .el-dropdown-link {
    color: $primary-color;
  }
  .size-btn {
    cursor: pointer;
  }
  .el-dropdown {
    cursor: pointer;
  }
  .question-box {
    text-align: left;
    padding: 12px 18px;
    background-color: #ffffff;
    transition: 0.2s;
    border: 1px solid #e9e9e9;
    border-top: 0;
    .check-question-box {
      text-align: center;
      .check-q-text {
        color: #5e6a7a;
        cursor: pointer;
        user-select: none;
      }
      img {
        transition: 0.2s linear;
      }
    }
    .check-q {
      img {
        transform: rotate(180deg);
      }
    }
  }
  .fd-box {
    position: fixed;
    top: 73px;
    left: 91px;
    z-index: 9;
    width: calc(100% - 516px);
    transition: 0.2s;
  }
  .konw-list {
    padding: 4px;
    border-radius: 4px;
    background: #f3f8ff;
    color: #2474ed;
    margin-right: 12px;
    i {
      cursor: pointer;
      display: none;
    }
    &:hover {
      i {
        display: inline-block;
      }
    }
  }
  .know-left {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .left-box {
    // width: 988px;
    margin-top: 12px;
    background-color: #ffffff;
    .top-question-box {
      // overflow-y: scroll;
    }
    .top-question-scroll {
      max-height: 200px;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 3px;
        height: 3px;
      }

      ///*<!--修改 滑块 -->*/
      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.25);
      }
    }
    .left-box-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      border: 1px solid #e9e9e9;
      padding: 8px 18px;
    }
    .header-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .header-re {
        display: flex;
        flex-shrink: 0;
      }
      .el-dropdown {
        margin-right: 18px;
      }
    }

    .el-checkbox {
      margin-bottom: 0;
    }
  }
}
</style>
