<template>
  <div class="examQuestionList">
    <template v-if="$parent.topicType == 0">
      <!-- 标注知识点 -->
      <template v-if="indexTypes == 'KnowledgePoints'">
        <div class="search-box">
          <div class="search-left">
            <div class="on">按拍照搜题搜索试题</div>
          </div>
          <div>
            <el-input
              v-model="inheritVal"
              placeholder="输入试题ID或者关键字"
            ></el-input>
            <el-button type="primary" class="btn-all" @click="inherit(1)">
              搜索
            </el-button>
          </div>
        </div>
        <!-- 试题列表 -->
        <!-- 已锁定 -->
        <div
          v-if="knowledgeListData.length > 0"
          v-loading="loading"
          class="question-list-box"
        >
          <div
            v-for="(v, i) in knowledgeListData"
            :key="i"
            class="question-list"
            :class="{
              'on-succ':
                v.id == indexQuestion.statusKnowledge ||
                indexQuestion.xuekeId == v.xuekeId,
            }"
          >
            <!-- {{ v.id == indexQuestion.statusKnowledge }} -->
            <div class="question-html" v-html="v.question"></div>
            <div
              class="question-btn-box"
              :class="{ 'show-btn': showAwswerList.indexOf(v.id) != -1 }"
            >
              <div v-if="v.xuekeId" class="tips">
                <p>试题ID：{{ v.xuekeId }}</p>
                <span @click="copy(v.xuekeId)">复制</span>
              </div>
              <div :key="boxKey" class="question-btn-right">
                <el-button @click="showAwswer(v)">
                  <template v-if="showAwswerList.indexOf(v.id) != -1">
                    隐藏解析
                  </template>
                  <template v-if="showAwswerList.indexOf(v.id) == -1">
                    显示解析
                  </template>
                </el-button>
                <div>
                  替换为原题：<el-checkbox
                    v-model="v.xuekeType"
                    @change="changeIndexQuestion(v)"
                  ></el-checkbox>
                </div>
                <!-- statusReplaceKnowledge -->

                <img
                  v-if="v.id != indexQuestion.statusKnowledge"
                  src="@/assets/imgs/open.png"
                  alt=""
                  @click="addKnow(v)"
                />
                <!-- down_err.svg -->
                <template v-else>
                  <img
                    v-if="indexQuestion.statusReplaceKnowledge"
                    src="@/assets/imgs/down_err.svg"
                    alt=""
                    @click="addKnow(v)"
                  />
                  <img v-else src="@/assets/imgs/down.png" alt="" />
                </template>

                <!--         @click="delKnow(v)" -->
              </div>
            </div>
            <answerBox
              v-if="showAwswerList.indexOf(v.id) != -1"
              :id="v.id"
            ></answerBox>
          </div>
        </div>
        <div
          v-if="trainingQuestionList.length > 0"
          v-loading="loading"
          class="question-list-box"
        >
          <div
            v-for="(v, i) in trainingQuestionList"
            :key="i"
            class="question-list"
            :class="{
              'on-succ':
                v.id == indexQuestion.statusKnowledge ||
                indexQuestion.xuekeId == v.xuekeId,
            }"
          >
            <div class="question-html" v-html="v.question"></div>
            <div
              class="question-btn-box"
              :class="{ 'show-btn': showAwswerList.indexOf(v.id) != -1 }"
            >
              <div class="tips">
                <p>试题ID：{{ v.xuekeId }}</p>
                <span @click="copy(v.xuekeId)">复制</span>
              </div>
              <div :key="boxKey" class="question-btn-right">
                <el-button @click="showAwswer(v)">
                  <template v-if="showAwswerList.indexOf(v.id) != -1">
                    隐藏解析
                  </template>
                  <template v-if="showAwswerList.indexOf(v.id) == -1">
                    显示解析
                  </template>
                </el-button>
                <div>
                  替换为原题：<el-checkbox
                    v-model="v.xuekeType"
                    @change="changeIndexQuestion(v)"
                  ></el-checkbox>
                </div>
                <img
                  v-if="v.id != indexQuestion.statusKnowledge"
                  src="@/assets/imgs/open.png"
                  alt=""
                  @click="addKnow(v)"
                />
                <img v-else src="@/assets/imgs/down.png" alt="" />
              </div>
            </div>
            <answerBox
              v-if="showAwswerList.indexOf(v.id) != -1"
              :id="v.id"
            ></answerBox>
          </div>
        </div>
        <el-divider>
          <template v-if="current != pages">加载中...</template>
          <template v-else> 没有找到更多了</template>
        </el-divider>
      </template>
      <!-- 变式训练题 -->
      <template v-if="indexTypes == 'VariantTraining'">
        <div class="search-box">
          <div class="search-left">
            <div
              v-for="(v, i) in variationBtn"
              :key="i"
              class="filter-item"
              :class="{ on: indexVariationBtn == v.val }"
              @click="clickIndexType(v)"
            >
              {{ v.name }}
            </div>
            <div class="filter-item" @click="clickIndexTypeModel('1')">
              自主上传试题
            </div>
            <div class="filter-item" @click="clickIndexTypeModel('3')">
              上传图片搜题
            </div>
            <el-input
              v-model="inheritVal"
              placeholder="输入试题ID或者关键字"
              @keyup.enter.native="inherit"
            >
              <i
                slot="suffix"
                class="el-icon-search el-input__icon"
                @click="inherit"
              >
              </i>
            </el-input>
          </div>
          <div class="check-btn">
            <!-- <div
              class="check-btn-item"
              :class="{
                'check-btn-item-on': indexQuestion.isPassOpt == 1,
              }"
              @click="setpassTraining()"
            >
              <img
                src="@/assets/newExam/newpaper/icon_reviewed_set.png"
                alt=""
              />
              <img
                class="on"
                src="@/assets/newExam/newpaper/icon_reviewed_set_on.png"
                alt=""
              />
              设为过审
            </div> -->
          </div>
        </div>
        <!-- 条件搜索的盒子 -->
        <div v-if="indexVariationBtn == 3" class="search-item-box">
          <div class="search-item">
            <span class="search-item-title">知识点条件搜索：</span>
            <span v-for="(v, i) in knowledgeNames" :key="i" class="know-list">
              <i class="el-icon-remove" @click="delKnowList(i)"></i>
              {{ v }}
            </span>
            <span class="size-btn" @click="showChangeKnow">
              <i class="el-icon-plus"></i>添加
            </span>
          </div>
          <div class="search-item" style="padding: 4px 0 3px">
            <span>知识点交集/并集：</span>
            <el-switch
              v-model="knowSearchType"
              active-text="交集"
              inactive-text="并集"
            >
            </el-switch>
          </div>
          <div class="search-item">
            <span class="search-item-title">题型搜索条件：</span>
            <span
              v-for="(v, i) in getExamTypeList"
              :key="i"
              class="know-list type-list"
              :class="{ on: indexType == v.value }"
              @click="typeClick(v, 'indexType')"
              >{{ v.label }}</span
            >
          </div>
          <div class="search-item">
            <span class="search-item-title">试题难度：</span>
            <span
              v-for="(v, i) in difficultyList"
              :key="i"
              class="know-list difficulty-list"
              :class="{ on: indexDifficulty == v.value }"
              @click="typeClick(v, 'indexDifficulty')"
              >{{ v.label }}</span
            >
            <template v-if="indexDifficulty == 999">
              <el-input
                v-model="difficultyMin"
                class="difficulty-input"
                placeholder=""
              ></el-input>
              <hr class="hr" />
              <el-input
                v-model="difficultyMax"
                class="difficulty-input"
                placeholder=""
              ></el-input>
            </template>
            <el-button plain class="search" @click="condition()"
              >搜索题目</el-button
            >
          </div>
        </div>
        <!-- 试题列表 -->

        <div v-loading="loading" class="question-list-box">
          <template v-if="examTraining.length > 0">
            <div
              v-for="(v, i) in examTraining"
              :key="i"
              class="question-list"
              :class="{
                'on-succ': !getIndexQuestionType(v.id),
              }"
            >
              <div class="question-header">
                <div>
                  {{ i + 1 }} - <span class="header-type">同类题</span> -
                  {{ v.type | setName(examTypeList) }}

                  <el-tooltip
                    v-if="v.addType == 3"
                    class="item"
                    effect="dark"
                    :content="'来源:' + (v.username || '')"
                    placement="top"
                  >
                    <span class="span-tips">补充</span>
                  </el-tooltip>
                </div>
                <div class="question-header-right">
                  <!-- v-if="$parent.topicType == 0" -->
                  <el-popover
                    v-if="
                      v.examList &&
                      v.examList.length > 0 &&
                      $parent.topicType == 0
                    "
                    placement="bottom"
                    width="300"
                    trigger="hover"
                  >
                    <div
                      v-for="(m, n) in v.examList"
                      :key="n"
                      class="exam-list-box"
                    >
                      <span>{{ m.name }}</span>
                      <span>{{ m.examDate }}</span>
                    </div>
                    <el-button slot="reference" type="text">应用记录</el-button>
                  </el-popover>
                  <div v-if="v.xuekeId" class="tips">
                    <p>试题ID：{{ v.xuekeId }}</p>
                    <el-button type="text" @click="copy(v.xuekeId)">
                      <img
                        src="@/assets/newExam/newpaper/icon_copy.png"
                        alt=""
                      />
                      <img
                        class="on"
                        src="@/assets/newExam/newpaper/icon_copy_on.png"
                        alt=""
                      />
                    </el-button>
                  </div>
                </div>
              </div>
              <div class="question-html" v-html="v.question"></div>
              <div
                class="question-btn-box"
                :class="{ 'show-btn': showAwswerList.indexOf(v.id) != -1 }"
              >
                <div>
                  <template v-if="!getIndexQuestionType(v.id)">
                    <div v-if="v.status == 0" class="parm-span succ-span">
                      <img
                        src="@/assets/newExam/newpaper/icon_reviewed.png"
                        alt=""
                      />
                      待审核
                    </div>
                    <div v-if="v.status == 1" class="succ-span">
                      <img
                        src="@/assets/newExam/newpaper/icon_next.png"
                        alt=""
                      />
                      通过
                      <span class="name-box">
                        {{ v.teacherName }}
                      </span>
                    </div>
                    <div v-if="v.status == 2" class="err-span">
                      <img
                        src="@/assets/newExam/newpaper/icon_err.png"
                        alt=""
                      />
                      拒绝
                      <span class="show-err-span name-box">
                        {{ v.teacherName }}
                      </span>
                      <el-tooltip
                        v-if="v.reasonType != 6"
                        class="item"
                        effect="dark"
                        :content="v.reasonType | setNames(refusalGroup)"
                        placement="top"
                      >
                        <span class="show-err-span">
                          拒绝原因:{{ v.reasonType | setNames(refusalGroup) }}
                        </span>
                      </el-tooltip>
                      <el-tooltip
                        v-if="v.reasonType == 6"
                        class="item"
                        effect="dark"
                        :content="v.reasonText"
                        placement="top"
                      >
                        <span class="show-err-span">
                          拒绝原因:{{ v.reasonText }}
                        </span>
                      </el-tooltip>
                    </div>
                  </template>
                </div>
                <div
                  class="ans-box"
                  :class="{
                    'ans-on': showAwswerList.indexOf(v.id) != -1,
                  }"
                >
                  <img src="@/assets/newExam/newpaper/icon_check.png" alt="" />
                  <span type="text" @click="showAwswer(v)">
                    <template v-if="showAwswerList.indexOf(v.id) != -1">
                      隐藏解析
                    </template>
                    <template v-if="showAwswerList.indexOf(v.id) == -1">
                      显示解析
                    </template>
                  </span>
                </div>
                <div v-if="$parent.topicType == 1">
                  <el-button
                    size="small"
                    type="danger"
                    plain
                    @click="showTurnDown(v)"
                    >拒绝</el-button
                  >
                  <el-button size="small" type="primary" @click="submitData(v)">
                    通过
                  </el-button>
                </div>
                <div :key="boxKey" class="question-btn-right">
                  <el-button type="text" @click="showEditQ(v, 1)">
                    修改
                  </el-button>
                  <el-button type="text" @click="showQuestionModel(v)">
                    浏览同类题
                  </el-button>

                  <span
                    v-if="getIndexQuestionType(v.id)"
                    class="tips-icon"
                    @click="lockingVariation(v)"
                  >
                    <img src="@/assets/newExam/newpaper/open.png" alt="" />
                    设为变式题
                  </span>
                  <span
                    v-else
                    class="tips-icon tips-icon-on"
                    @click="cancelVariation(v)"
                  >
                    <img src="@/assets/newExam/newpaper/down.png" alt="" />
                    取消变式题
                  </span>
                </div>
              </div>
              <answerBox
                v-if="showAwswerList.indexOf(v.id) != -1"
                :id="v.id"
              ></answerBox>
              <!-- showErrList -->
            </div>
          </template>
          <template
            v-if="trainingQuestionList.length > 0 && $parent.topicType == 0"
          >
            <div
              v-for="(v, i) in trainingQuestionList"
              :key="i + 'question-list'"
              class="question-list"
              :class="{ 'on-succ': !getIndexQuestionType(v.id) }"
            >
              <div class="question-header">
                <div>
                  {{ i + 1 + examTraining.length }} -
                  <span class="header-type">同类题</span> -
                  {{ v.type | setName(examTypeList) }}
                </div>
                <div class="question-header-right">
                  <div v-if="v.xuekeId" class="tips">
                    <p>试题ID：{{ v.xuekeId }}</p>
                    <el-button type="text" @click="copy(v.xuekeId)">
                      <img
                        src="@/assets/newExam/newpaper/icon_copy.png"
                        alt=""
                      />
                      <img
                        class="on"
                        src="@/assets/newExam/newpaper/icon_copy_on.png"
                        alt=""
                      />
                    </el-button>
                  </div>
                </div>
              </div>
              <div class="question-html" v-html="v.question"></div>
              <div
                class="question-btn-box"
                :class="{ 'show-btn': showAwswerList.indexOf(v.id) != -1 }"
              >
                <div>
                  <div
                    v-if="!getIndexQuestionType(v.id)"
                    class="parm-span succ-span"
                  >
                    <img
                      src="@/assets/newExam/newpaper/icon_reviewed.png"
                      alt=""
                    />
                    待审核
                  </div>
                </div>
                <div
                  class="ans-box"
                  :class="{
                    'ans-on': showAwswerList.indexOf(v.id) != -1,
                  }"
                >
                  <img src="@/assets/newExam/newpaper/icon_check.png" alt="" />
                  <span type="text" @click="showAwswer(v)">
                    <template v-if="showAwswerList.indexOf(v.id) != -1">
                      隐藏解析
                    </template>
                    <template v-if="showAwswerList.indexOf(v.id) == -1">
                      显示解析
                    </template>
                  </span>
                </div>
                <div :key="boxKey" class="question-btn-right">
                  <el-button type="text" @click="showEditQ(v, 1)">
                    修改
                  </el-button>
                  <el-button type="text" @click="showQuestionModel(v)">
                    浏览同类题
                  </el-button>
                  <span
                    v-if="getIndexQuestionType(v.id)"
                    class="tips-icon"
                    @click="lockingVariation(v)"
                  >
                    <img src="@/assets/newExam/newpaper/open.png" alt="" />
                    设为变式题
                  </span>
                  <span
                    v-else
                    class="tips-icon tips-icon-on"
                    @click="cancelVariation(v)"
                  >
                    <img src="@/assets/newExam/newpaper/down.png" alt="" />
                    取消变式题
                  </span>
                </div>
              </div>
              <answerBox
                v-if="showAwswerList.indexOf(v.id) != -1"
                :id="v.id"
              ></answerBox>
            </div>
          </template>
        </div>

        <el-divider>
          <template v-if="current != pages">加载中...</template>
          <template v-else> 没有找到更多了</template>
        </el-divider>
      </template>
      <!-- 分层变式训练题 -->
      <template v-if="indexTypes == 'layeredVariantTraining'">
        <div class="search-box">
          <div class="search-left">
            <div
              v-for="(v, i) in layeredList"
              :key="i"
              class="filter-item"
              :class="{ on: layeredTypes == v.val }"
              @click="clicklayerIndexType(v, 'layeredTypes')"
            >
              {{ v.name }}
              <template v-if="v.number">（{{ v.number }}）</template>
            </div>
          </div>
          <div class="check-btn">
            <!-- <div
              class="check-btn-item"
              :class="{
                'check-btn-item-on': indexQuestion.isPassExtend == 1,
              }"
              @click="setpassTraining()"
            >
              <img
                src="@/assets/newExam/newpaper/icon_reviewed_set.png"
                alt=""
              />
              <img
                class="on"
                src="@/assets/newExam/newpaper/icon_reviewed_set_on.png"
                alt=""
              />
              设为过审
            </div> -->
          </div>
        </div>
        <div class="search-item-box layered-search-list">
          <div class="layered-search">
            <div
              v-for="(v, i) in layeredVariationBtn"
              :key="i"
              class="search-cnt"
              :class="{ on: indexLayeredVariationBtn == v.val }"
              @click="clickIndexLayeredType(v)"
            >
              {{ v.name }}
            </div>
            <!-- <div class="search-cnt" @click="showUpLoadFile">自主上传试题</div> -->
            <div class="search-cnt" @click="clickIndexTypeModel('2')">
              自主上传试题
            </div>
            <div class="search-cnt" @click="clickIndexTypeModel('3')">
              上传图片搜题
            </div>
            <el-input v-model="inheritVal" placeholder="输入试题ID或者关键字">
              <i
                slot="suffix"
                class="el-icon-search el-input__icon"
                @click="inherit"
              >
              </i>
            </el-input>
          </div>

          <div class="search-item">
            <span class="search-item-title">知识点条件搜索：</span>
            <span v-for="(v, i) in knowledgeNames" :key="i" class="know-list">
              <i class="el-icon-remove" @click="delKnowList(i)"></i>
              {{ v }}
            </span>
            <span class="size-btn" @click="showChangeKnow">
              <i class="el-icon-plus"></i>添加
            </span>
          </div>
          <div class="search-item" style="padding: 4px 0 3px">
            <span>知识点交集/并集：</span>
            <el-switch
              v-model="knowSearchType"
              active-text="交集"
              inactive-text="并集"
            >
            </el-switch>
          </div>
          <div class="search-item">
            <span class="search-item-title">题型搜索条件：</span>
            <span
              v-for="(v, i) in getExamTypeList"
              :key="i"
              class="know-list type-list"
              :class="{ on: indexType == v.value }"
              @click="typeClick(v, 'indexType')"
              >{{ v.label }}</span
            >
          </div>
          <div class="search-item">
            <span class="search-item-title">试题难度：</span>
            <span
              v-for="(v, i) in difficultyList"
              :key="i"
              class="know-list difficulty-list"
              :class="{ on: indexDifficulty == v.value }"
              @click="typeClick(v, 'indexDifficulty')"
              >{{ v.label }}</span
            >
            <template v-if="indexDifficulty == 999">
              <el-input
                v-model="difficultyMin"
                class="difficulty-input"
                placeholder=""
              ></el-input>
              <hr class="hr" />
              <el-input
                v-model="difficultyMax"
                class="difficulty-input"
                placeholder=""
              ></el-input>
            </template>
            <el-button plain class="search" @click="condition()"
              >搜索题目</el-button
            >
          </div>
        </div>

        <div class="question-list-box">
          <template v-if="questionExtendList.length > 0">
            <div
              v-for="(v, i) in questionExtendList"
              :key="i"
              class="question-list"
              :class="{ 'on-succ': !getIndexLayeredType(v.id) }"
            >
              <div class="question-header">
                <div>
                  {{ i + 1 }} - <span class="header-type">拓展题</span> -
                  {{ v.typeName }}
                  <el-tooltip
                    v-if="v.addType == 4"
                    class="item"
                    effect="dark"
                    :content="'来源:' + (v.username || '')"
                    placement="top"
                  >
                    <span class="span-tips">补充</span>
                  </el-tooltip>
                </div>
                <div class="question-header-right">
                  <el-button
                    type="text"
                    style="margin-right: 8px"
                    @click="showDiffModel(v)"
                  >
                    分享至其他拓展层
                  </el-button>
                  <el-popover
                    v-if="v.extendList && v.extendList.length > 0"
                    placement="bottom"
                    width="300"
                    trigger="hover"
                  >
                    <div
                      v-for="(m, n) in v.extendList"
                      :key="n"
                      class="exam-list-box"
                    >
                      <span>{{ m.name }}</span>
                      <span>{{ m.examDate }}</span>

                      <span v-if="m.difficultyType == 1"> 较难 </span>
                      <span v-if="m.difficultyType == 2"> 中等 </span>
                      <span v-if="m.difficultyType == 3"> 较易 </span>
                    </div>
                    <el-button slot="reference" type="text">应用记录</el-button>
                  </el-popover>

                  <div class="tips">
                    <p>试题ID：{{ v.xuekeId }}</p>
                    <el-button type="text" @click="copy(v.xuekeId)">
                      <img
                        src="@/assets/newExam/newpaper/icon_copy.png"
                        alt=""
                      />
                      <img
                        class="on"
                        src="@/assets/newExam/newpaper/icon_copy_on.png"
                        alt=""
                      />
                    </el-button>
                  </div>
                </div>
              </div>
              <div class="question-html" v-html="v.question"></div>
              <div
                class="question-btn-box"
                :class="{ 'show-btn': showAwswerList.indexOf(v.id) != -1 }"
              >
                <div>
                  <template v-if="!getIndexLayeredType(v.id)">
                    <div v-if="v.status == 0" class="parm-span succ-span">
                      <img
                        src="@/assets/newExam/newpaper/icon_reviewed.png"
                        alt=""
                      />
                      待审核
                    </div>
                    <div v-if="v.status == 1" class="succ-span">
                      <img
                        src="@/assets/newExam/newpaper/icon_next.png"
                        alt=""
                      />
                      通过
                      <span class="name-box">
                        {{ v.teacherName }}
                      </span>
                    </div>
                    <div v-if="v.status == 2" class="err-span">
                      <img
                        src="@/assets/newExam/newpaper/icon_err.png"
                        alt=""
                      />
                      拒绝
                      <span class="name-box show-err-span">
                        {{ v.teacherName }}
                      </span>
                      <el-tooltip
                        v-if="v.reasonType != 6"
                        class="item"
                        effect="dark"
                        :content="v.reasonType | setNames(refusalGroup)"
                        placement="top"
                      >
                        <span class="show-err-span">
                          拒绝原因:{{ v.reasonType | setNames(refusalGroup) }}
                        </span>
                      </el-tooltip>
                      <el-tooltip
                        v-if="v.reasonType == 6"
                        class="item"
                        effect="dark"
                        :content="v.reasonText"
                        placement="top"
                      >
                        <span class="show-err-span">
                          拒绝原因:{{ v.reasonText }}
                        </span>
                      </el-tooltip>
                    </div>
                  </template>
                </div>
                <div
                  class="ans-box"
                  :class="{
                    'ans-on': showAwswerList.indexOf(v.id) != -1,
                  }"
                >
                  <img src="@/assets/newExam/newpaper/icon_check.png" alt="" />
                  <span type="text" @click="showAwswer(v)">
                    <template v-if="showAwswerList.indexOf(v.id) != -1">
                      隐藏解析
                    </template>
                    <template v-if="showAwswerList.indexOf(v.id) == -1">
                      显示解析
                    </template>
                  </span>
                </div>
                <div :key="boxKey" class="question-btn-right">
                  <el-button type="text" @click="showEditQ(v, 2)">
                    修改
                  </el-button>
                  <el-button type="text" @click="showQuestionModel(v)"
                    >浏览同类题</el-button
                  >
                  <span
                    v-if="getIndexLayeredType(v.id)"
                    class="tips-icon"
                    @click="trainingExtend(v, 1)"
                  >
                    <img src="@/assets/newExam/newpaper/open.png" alt="" />
                    设为分层变式题
                  </span>
                  <span
                    v-else
                    class="tips-icon tips-icon-on"
                    @click="trainingExtend(v, 2)"
                  >
                    <img src="@/assets/newExam/newpaper/down.png" alt="" />
                    取消分层变式题
                  </span>
                </div>
              </div>
              <answerBox
                v-if="showAwswerList.indexOf(v.id) != -1"
                :id="v.id"
              ></answerBox>
            </div>
          </template>
          <template v-if="trainingQuestionList.length > 0">
            <div
              v-for="(v, i) in trainingQuestionList"
              :key="i + 'question-list'"
              class="question-list"
              :class="{ 'on-succ': !getIndexLayeredType(v.id) }"
            >
              <div class="question-header">
                <div>
                  {{ i + 1 + questionExtendList.length }} -
                  <span class="header-type">拓展题</span> -
                  {{ v.type | setName(examTypeList) }}
                </div>
                <div class="question-header-right">
                  <el-button
                    type="text"
                    style="margin-right: 8px"
                    @click="showDiffModel(v)"
                  >
                    分享至其他拓展层
                  </el-button>
                  <el-popover
                    v-if="v.extendList && v.extendList.length > 0"
                    placement="bottom"
                    width="300"
                    trigger="hover"
                  >
                    <div
                      v-for="(m, n) in v.extendList"
                      :key="n"
                      class="exam-list-box"
                    >
                      <span>{{ m.name }}</span>
                      <span>{{ m.examDate }}</span>

                      <span v-if="m.difficultyType == 1"> 较难 </span>
                      <span v-if="m.difficultyType == 2"> 中等 </span>
                      <span v-if="m.difficultyType == 3"> 较易 </span>
                    </div>
                    <el-button slot="reference" type="text">应用记录</el-button>
                  </el-popover>

                  <div v-if="v.xuekeId" class="tips">
                    <p>试题ID：{{ v.xuekeId }}</p>
                    <el-button type="text" @click="copy(v.xuekeId)">
                      <img
                        src="@/assets/newExam/newpaper/icon_copy.png"
                        alt=""
                      />
                      <img
                        class="on"
                        src="@/assets/newExam/newpaper/icon_copy_on.png"
                        alt=""
                      />
                    </el-button>
                  </div>
                </div>
              </div>
              <div class="question-html" v-html="v.question"></div>
              <div
                class="question-btn-box"
                :class="{ 'show-btn': showAwswerList.indexOf(v.id) != -1 }"
              >
                <div>
                  <div
                    v-if="!getIndexLayeredType(v.id)"
                    class="parm-span succ-span"
                  >
                    <img
                      src="@/assets/newExam/newpaper/icon_reviewed.png"
                      alt=""
                    />
                    待审核
                  </div>
                </div>
                <div
                  class="ans-box"
                  :class="{
                    'ans-on': showAwswerList.indexOf(v.id) != -1,
                  }"
                >
                  <img src="@/assets/newExam/newpaper/icon_check.png" alt="" />
                  <span type="text" @click="showAwswer(v)">
                    <template v-if="showAwswerList.indexOf(v.id) != -1">
                      隐藏解析
                    </template>
                    <template v-if="showAwswerList.indexOf(v.id) == -1">
                      显示解析
                    </template>
                  </span>
                </div>
                <div :key="boxKey" class="question-btn-right">
                  <el-button type="text" @click="showEditQ(v, 2)">
                    修改
                  </el-button>
                  <el-button type="text" @click="showQuestionModel(v)"
                    >浏览同类题</el-button
                  >
                  <span
                    v-if="getIndexLayeredType(v.id)"
                    class="tips-icon"
                    @click="trainingExtend(v, 1)"
                  >
                    <img src="@/assets/newExam/newpaper/open.png" alt="" />
                    设为分层变式题
                  </span>
                  <span
                    v-else
                    class="tips-icon tips-icon-on"
                    @click="trainingExtend(v, 2)"
                  >
                    <img src="@/assets/newExam/newpaper/down.png" alt="" />
                    取消分层变式题
                  </span>
                </div>
              </div>
              <answerBox
                v-if="showAwswerList.indexOf(v.id) != -1"
                :id="v.id"
              ></answerBox>
            </div>
          </template>
        </div>

        <el-divider>
          <template v-if="current != pages">加载中...</template>
          <template v-else> 没有找到更多了</template>
        </el-divider>
      </template>
    </template>
    <!-- 审题 -->
    <template v-if="$parent.topicType == 1">
      <div class="search-box">
        <div class="search-box-title">审核列表</div>
        <div class="check-btn">
          <el-button @click="showAdd()">
            <img src="@/assets/newExam/newpaper/icon_add.png" alt="" />
            <img
              class="on"
              src="@/assets/newExam/newpaper/icon_add_on.png"
              alt=""
            />
            补充试题
          </el-button>
        </div>
      </div>

      <!-- 试题列表 -->

      <div v-loading="loading" class="question-list-box">
        <template v-if="examTraining.length > 0">
          <div
            v-for="(v, i) in examTraining"
            :key="i"
            class="question-list"
            :class="{
              'on-succ': $parent.topicType == 1,
            }"
          >
            <div class="question-header">
              <div>
                {{ i + 1 }} -
                <span class="header-type">
                  <template v-if="v.type == 1 || v.type == 3">同类题</template>
                  <template v-if="v.type == 2 || v.type == 4"
                    >分层拓展题</template
                  >
                </span>
                -
                <template> {{ v.questionTypeName }}</template>
                <el-tooltip
                  v-if="v.type == 3 || v.type == 4"
                  class="item"
                  effect="dark"
                  :content="'来源:' + (v.username || '')"
                  placement="top"
                >
                  <span class="span-tips">补充</span>
                </el-tooltip>
              </div>
              <div class="question-header-right">
                <div v-if="v.xuekeId" class="tips">
                  <p>试题ID：{{ v.xuekeId }}</p>
                  <el-button type="text" @click="copy(v.xuekeId)">
                    <img src="@/assets/newExam/newpaper/icon_copy.png" alt="" />
                    <img
                      class="on"
                      src="@/assets/newExam/newpaper/icon_copy_on.png"
                      alt=""
                    />
                  </el-button>
                </div>
              </div>
            </div>
            <div class="question-html" v-html="v.question"></div>
            <div
              class="question-btn-box"
              :class="{ 'show-btn': showAwswerList.indexOf(v.id) != -1 }"
            >
              <div>
                <template>
                  <div v-if="v.status == 0" class="parm-span succ-span">
                    <img
                      src="@/assets/newExam/newpaper/icon_reviewed.png"
                      alt=""
                    />
                    待审核
                  </div>
                  <div v-if="v.status == 1" class="succ-span">
                    <img src="@/assets/newExam/newpaper/icon_next.png" alt="" />
                    通过
                  </div>
                  <div v-if="v.status == 2" class="err-span">
                    <img src="@/assets/newExam/newpaper/icon_err.png" alt="" />
                    拒绝
                    <el-tooltip
                      v-if="v.reasonType != 6"
                      class="item"
                      effect="dark"
                      :content="v.reasonType | setNames(refusalGroup)"
                      placement="top"
                    >
                      <span class="show-err-span">
                        拒绝原因:{{ v.reasonType | setNames(refusalGroup) }}
                      </span>
                    </el-tooltip>
                    <el-tooltip
                      v-if="v.reasonType == 6"
                      class="item"
                      effect="dark"
                      :content="v.reasonText"
                      placement="top"
                    >
                      <span class="show-err-span">
                        拒绝原因:{{ v.reasonText }}
                      </span>
                    </el-tooltip>
                  </div>
                </template>
              </div>
              <div
                class="ans-box"
                :class="{
                  'ans-on': showAwswerList.indexOf(v.id) != -1,
                }"
              >
                <img src="@/assets/newExam/newpaper/icon_check.png" alt="" />
                <span type="text" @click="showAwswer(v)">
                  <template v-if="showAwswerList.indexOf(v.id) != -1">
                    隐藏解析
                  </template>
                  <template v-if="showAwswerList.indexOf(v.id) == -1">
                    显示解析
                  </template>
                </span>
              </div>
              <div>
                <el-button
                  size="small"
                  :type="v.status == 2 ? 'danger' : ''"
                  @click="showTurnDown(v)"
                  >拒绝</el-button
                >
                <el-button
                  size="small"
                  :type="v.status == 1 ? 'primary' : ''"
                  @click="submitData(v)"
                  >通过</el-button
                >
              </div>
            </div>
            <answerBox
              v-if="showAwswerList.indexOf(v.id) != -1"
              :id="v.id"
            ></answerBox>
            <!-- showErrList -->
            <reasonRefusal
              v-if="showErrList.indexOf(v.id) != -1"
              ref="reasonRefusal"
              :index-question="v"
            ></reasonRefusal>
          </div>
        </template>
      </div>

      <el-divider> 已经是最后一道试题了 </el-divider>
    </template>

    <!-- 上传变式训练题弹窗 -->
    <el-dialog
      class="uploadVariationDialog"
      title="上传试题"
      :visible.sync="uploadVariation"
      width="650px"
    >
      <el-tabs v-model="uploadType" type="border-card" @tab-click="handleClick">
        <el-tab-pane label="变式训练题" name="1">
          <div v-if="uploadType == 1" class="upload-tips">
            通过上传 Word 文件上传变式题，可一次上传多道多套题。<el-link
              type="primary"
              href="https://static.wtjy.com/exam/paper/template/%E8%87%AA%E4%B8%BB%E4%B8%8A%E4%BC%A0%E5%8F%98%E5%BC%8F%E8%AE%AD%E7%BB%83%E9%A2%98%E6%A8%A1%E6%9D%BF.docx"
              >下载 Word 模板<span class="el-icon-download"></span
            ></el-link>
            <br />注意： <br />1、标签【题号】、【套号】、【试题】为必填。
            <br />2、标签【题号】是一道题的开始，即一道题的第一个标签。务必与切题时设置的题号一致。如果是材料题，请填写大题题号，不要填写子题题号。
            <br />3、标签【套号】填写范围为 1-5。表示该题为第几套变式题。
            <br />4、模板里只提供了一道题的标签，增加试题只需要换行增加一套这些标签即可。
            <br />5、上传会根据【题号】、【套号】覆盖已锁定的变式题。
          </div>
        </el-tab-pane>
        <el-tab-pane label="分层拓展题" name="2">
          <div v-if="uploadType == 2" class="upload-tips">
            通过上传 Word 文件上传变式题，可一次上传多道多套题。<el-link
              type="primary"
              href="https://static.wtjy.com/exam/paper/template/%E8%87%AA%E4%B8%BB%E4%B8%8A%E4%BC%A0%E5%88%86%E5%B1%82%E5%8F%98%E5%BC%8F%E9%A2%98%E6%A8%A1%E6%9D%BF.docx"
              >下载 Word 模板<span class="el-icon-download"></span
            ></el-link>
            <br />注意： <br />1、标签【题号】、【难度】、【试题】为必填。
            <br />2、标签【题号】是一道题的开始，即一道题的第一个标签。务必与切题时设置的题号一致。如果是材料题，请填写大题题号，不要填写子题题号。
            <br />3、标签【难度】填写范围为 1-3，分别代表 较难 、 中等 、 较易
            。表示该题的分层难度。
            <br />4、模板里只提供了一道题的标签，增加试题只需要换行增加一套这些标签即可。
            <br />5、上传会根据【题号】、【难度】增加该题该难度的分层变式题。
          </div>
        </el-tab-pane>
        <el-tab-pane label="图片搜索试题" name="3">
          根据上传的图片搜索试题，不会绑定至小题，支持上传文件类型：.png,jpg,jpeg,gif
        </el-tab-pane>
      </el-tabs>

      <el-upload
        ref="uploadVariationFile"
        class="uploadVariation"
        :action="uploadVariationURL"
        :headers="uploadVariationHeaders"
        :data="uploadVariationData"
        :auto-upload="false"
        :accept="uploadType == 3 ? '.png,.jpg,.jpeg,.gif' : '.docx'"
        :before-upload="handleUploadVariationBefore"
        :on-progress="handleUploadVariationOnProgress"
        :on-success="handleUploadVariationOnSuccess"
        :on-error="handleUploadVariationOnError"
        :limit="1"
        drag
      >
        <!--      :show-file-list="false" -->
        <div class="upload-text">将文件拖放到此处或点击上传</div>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button @click="uploadVariation = false">取 消</el-button>
        <el-button type="primary" :loading="loading" @click="submitUpload()">
          上 传
        </el-button>
      </span>
    </el-dialog>
    <!-- 上传分层拓展题弹窗 -->
    <el-dialog
      class="uploadVariationDialog"
      :title="titleDialog"
      :visible.sync="uploadLayerVariationDialogVisible"
      width="650px"
    >
      <div class="upload-tips">
        通过上传 Word 文件上传变式题，可一次上传多道多套题。<el-link
          type="primary"
          href="https://static.wtjy.com/exam/paper/template/%E8%87%AA%E4%B8%BB%E4%B8%8A%E4%BC%A0%E5%88%86%E5%B1%82%E5%8F%98%E5%BC%8F%E9%A2%98%E6%A8%A1%E6%9D%BF.docx"
          >下载 Word 模板<span class="el-icon-download"></span
        ></el-link>
        <br />注意： <br />1、标签【题号】、【难度】、【试题】为必填。
        <br />2、标签【题号】是一道题的开始，即一道题的第一个标签。务必与切题时设置的题号一致。如果是材料题，请填写大题题号，不要填写子题题号。
        <br />3、标签【难度】填写范围为 1-3，分别代表 较难 、 中等 、 较易
        。表示该题的分层难度。
        <br />4、模板里只提供了一道题的标签，增加试题只需要换行增加一套这些标签即可。
        <br />5、上传会根据【题号】、【难度】增加该题该难度的分层变式题。
      </div>
      <el-upload
        class="uploadVariation"
        :action="uploadLayerVariationURL"
        :headers="uploadVariationHeaders"
        :data="uploadVariationData"
        accept=".docx"
        :show-file-list="false"
        :before-upload="handleUploadVariationBefore"
        :on-progress="handleUploadVariationOnProgress"
        :on-success="handleUploadLayerVariationOnSuccess"
        :on-error="handleUploadVariationOnError"
        drag
      >
        <div class="upload-text">将文件拖放到此处或点击上传</div>
      </el-upload>
    </el-dialog>
    <!-- 分享拓展层 -->
    <el-dialog
      title="分享至其他拓展层"
      :visible.sync="dialogVisibleDiff"
      width="270px"
    >
      <el-radio-group v-model="diffCheckboxGroup" class="model-check">
        <template v-for="(v, i) in layeredList">
          <el-radio v-if="layeredTypes != v.val" :key="i" :label="v.val" border>
            {{ v.name }}
          </el-radio>
        </template>
      </el-radio-group>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleDiff = false">取 消</el-button>
        <el-button
          class="btn-all"
          type="primary"
          :loading="modelLoading"
          @click="submitLayer"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <editQuestion ref="editQuestion"></editQuestion>
    <supplementaryQuestion ref="supplementaryQuestion"></supplementaryQuestion>
    <knowledge ref="knowledge"></knowledge>
    <similarityQuestion ref="similarityQuestion"></similarityQuestion>
  </div>
</template>

<script>
const variationBtn = [
  {
    name: "举一反三搜索",
    val: 1,
  },
  {
    name: "原图搜索",
    val: 2,
  },
  {
    name: "按条件搜索",
    val: 3,
  },
  // {
  //   name: "自主上传试题",
  //   val: 4,
  // },
];
const layeredVariationBtn = [
  {
    name: "举一反三搜索",
    val: 3,
  },
  {
    name: "原图搜索",
    val: 2,
  },
  {
    name: "按条件搜索",
    val: 1,
  },

  // {
  //   name: "自主上传试题",
  //   val: 4
  // }
];
const layeredList = [
  {
    name: "较易拓展题",
    val: 3,
  },
  {
    name: "中等拓展题",
    val: 2,
  },
  {
    name: "较难拓展题",
    val: 1,
  },
];
import {
  STEP_MARKKNOWLEDGEPOINT,
  STEP_MATCHVARIATIONQUESTION,
  STEP_FINISHALL,
  difficultyList,
} from "@/core/util/constdata";
import { setExamPaperStatusStep } from "@/core/api/exam/exampaper";

import { answer } from "@/core/api/resource/knowledgePointsPaper";
import {
  trainingList,
  getList,
  // putObj,
  getRecommend,
  putPaperQuestionObj,
  examRandom,
  lockTraining,
  unlockTraining,
  trainingExtend,
  postTrainingList,
  questionExtendV2,
  trainingsV2,
  saveResult,
  passTraining,
} from "@/core/api/exam/examLore";
import { mapGetters } from "vuex";
import answerBox from "@/components/answer";
import editQuestion from "./editQuestion";
import supplementaryQuestion from "./supplementaryQuestion";
import reasonRefusal from "./reasonRefusal";
import knowledge from "./knowledge";
import similarityQuestion from "./similarityQuestion";
import { getStore } from "@/core/util/store";
import { refusalGroup } from "@/core/util/dictionaries";

export default {
  name: "ExamQuestionList",
  components: {
    answerBox,
    editQuestion,
    supplementaryQuestion,
    reasonRefusal,
    knowledge,
    similarityQuestion,
  },
  props: {
    indexTypes: String,
  },
  data() {
    return {
      layeredTypes: 3,
      uploadType: "0",
      titleDialog: "自主上传",
      uploadVariation: false,
      refusalGroup: refusalGroup,
      knowledgeNames: [],
      //知识点已锁定列表
      knowledgeListData: [],
      questionListModel: false,
      // 其他拓展层
      dialogVisibleDiff: false,
      modelLoading: false,
      layeredList: layeredList,
      diffCheckboxGroup: "",
      similarQuestionsList: [],
      variationBtn: variationBtn,
      layeredVariationBtn: layeredVariationBtn,
      indexVariationBtn: 1,
      indexLayeredVariationBtn: 1,
      difficultyList: JSON.parse(JSON.stringify(difficultyList)),
      input: "",

      loading: false,
      oldQuestionId: 0,
      trainingQuestionList: [],
      showAwswerList: [],
      showErrList: [],
      indexQuestion: {},
      inheritVal: "",
      boxKey: 1,
      knowSearchType: false,
      indexType: 0,
      indexDifficulty: 0,
      difficultyMin: 0.1,
      difficultyMax: 0.9,
      // 知识点弹出层 start
      twoTree: false,
      twoTreetype: true,
      defaultProps: {
        children: "child",
        label: "name",
      },
      loreTureArrid: [],
      TreeArray: [],
      twoTreeArray: [],
      newKeys: 1,
      twoTreeVal: "",
      knowledgeAllList: [],
      loreArr: [],
      //知识点弹出层 end

      // 上传变式题
      uploadVariationDialogVisible: false,
      uploadVariationURL: "/exam/exampaper/uploadexamquestionopt",
      uploadVariationHeaders: {
        Authorization: "Bearer " + getStore({ name: "access_token" }),
        "SCHOOL-ID": getStore({ name: "school_id" }),
      },
      uploadVariationData: {},
      // 上传分层拓展题
      uploadLayerVariationDialogVisible: false,
      uploadLayerVariationURL: "/exam/exampaper/uploadexamquestionextend",
      //已锁定的变式训练题
      examTraining: [],

      pages: 0,
      current: 1,
      reqTime: 1,
      questionExtendList: [],
      examTypeList: [],
      checked: false,
    };
  },
  computed: {
    ...mapGetters([
      "getExamPaper",
      "getIndexQuestion",
      "getExamQuestion",
      "getExamTypeList",
      "getPaperKnowList",
      "getPaperTreeKnowList",
      "GET_QUESTION_TYPE_LIST",
    ]),
  },
  watch: {
    indexTypes() {
      this.oldDataList = [];
      this.current = 1;
      this.pages = 0;
      if (this.$parent.topicType == 1) {
        // 如果是审题
        this.showAudit();
        return;
      }
      this.onloadView();
    },
    indexVariationBtn() {
      this.oldDataList = [];
      this.current = 1;
      this.pages = 0;
      this.getSearchList();
    },
    getPaperKnowList: {
      immediate: true,
      handler() {
        this.knowledgeAllList = this.getPaperTreeKnowList;
        this.loreArr = this.getPaperKnowList;
        // console.log(this.loreArr);
      },
    },
    // 监听当前题在试题列表中是否更新了数据
    getExamQuestion: {
      deep: true,
      handler() {
        this.setStatusStep();
        this.setVariation();
        this.getExamQuestion.forEach((index) => {
          if (this.indexQuestion.examQuestionId == index.examQuestionId) {
            //如果xuekeid不同  就是更新了（是否为原题）
            if (index.xuekeId != this.indexQuestion.xuekeId) {
              this.indexQuestion = Object.assign({}, index);
              this.setTraningList();
            }
            this.indexQuestion = Object.assign({}, index);
            setTimeout(() => {
              this.boxKey = new Date().getTime();
            }, 200);
            //如果是切换了试题类型
            if (this.oldQuestionType != this.indexQuestion.type) {
              this.oldQuestionType = this.indexQuestion.type;
              this.onloadView();
            }
            this.setKnowList();
          }
        });
      },
    },
    // 获取当前项试题
    getIndexQuestion: {
      immediate: true,
      handler() {
        this.setVariation();
        if (this.oldQuestionId != this.getIndexQuestion.id) {
          if (this.indexVariationBtn == 4) {
            this.indexVariationBtn = 1;
          }
          // 回到顶部
          // this.backTop();

          this.oldQuestionId = this.getIndexQuestion.examQuestionId;
          this.oldQuestionType = this.getIndexQuestion.type;
          this.indexQuestion = Object.assign({}, this.getIndexQuestion);
          this.indexType = this.getIndexQuestion.type;
          this.trainingQuestionList = [];
          this.examTraining = [];
          this.questionExtendList = [];
          this.knowledgeListData = [];
          if (this.GET_QUESTION_TYPE_LIST.length > 0) {
            this.examTypeList = this.GET_QUESTION_TYPE_LIST.filter(
              (item) => item.subjectId == this.indexQuestion.subjectId
            );
          }
          if (
            this.indexTypes == "KnowledgePoints" &&
            this.indexQuestion.statusKnowledge > 1
          ) {
            this.getQuestion(this.indexQuestion.statusKnowledge);
          }

          if (this.indexQuestion.id) {
            this.setKnowList();
            this.onloadView();
          }
        }
      },
    },
    //标注知识点
    knowledgeListData: {
      deep: true,
      handler: function () {
        if (
          this.knowledgeListData.length > 0 &&
          this.trainingQuestionList.length > 0
        ) {
          this.trainingQuestionList.map((index, arr) => {
            if (
              this.knowledgeListData.filter((item) => item.id == index.id)
                .length > 0
            ) {
              this.trainingQuestionList.splice(arr, 1);
            }
          });
        }
        this.trainingSet();
        // this.setState();
      },
    },
    examTraining: {
      deep: true,
      handler: function () {
        if (
          this.examTraining.length > 0 &&
          this.trainingQuestionList.length > 0
        ) {
          this.trainingQuestionList.map((index, arr) => {
            if (
              this.examTraining.filter((item) => item.id == index.id).length > 0
            ) {
              this.trainingQuestionList.splice(arr, 1);
            }
          });
        }
        // this.setState();
      },
    },
    trainingQuestionList: {
      deep: true,
      handler: function () {
        //过滤下相同的试题
        this.trainingQuestionList.map((index) => {
          if (
            this.trainingQuestionList.filter((i) => i.xuekeId == index.xuekeId)
              .length > 1
          ) {
            this.trainingQuestionList = this.trainingQuestionList.filter(
              (item) => item.id != index.id
            );
          }
        });

        //标注知识点的时候
        if (this.indexTypes == "KnowledgePoints") {
          // console.log(this.knowledgeListData);
          if (
            this.knowledgeListData.length > 0 &&
            this.trainingQuestionList.length > 0
          ) {
            this.trainingQuestionList.map((index, arr) => {
              if (
                this.knowledgeListData.filter((item) => item.id == index.id)
                  .length > 0
              ) {
                // console.log(
                //   this.knowledgeListData.filter((item) => item.id == index.id)
                //     .length > 0
                // );
                this.trainingQuestionList.splice(arr, 1);
              }
            });
          }
          this.trainingSet();
        }
        //变式训练题的时候
        if (this.indexTypes == "VariantTraining") {
          if (
            this.examTraining.length > 0 &&
            this.trainingQuestionList.length > 0
          ) {
            this.trainingQuestionList.map((index, arr) => {
              if (
                this.examTraining.filter((item) => item.id == index.id).length >
                0
              ) {
                this.trainingQuestionList.splice(arr, 1);
              }
            });
          }
          this.trainingSet();
        }
        //分层变式训练题的时候
        if (this.indexTypes == "layeredVariantTraining") {
          // console.log(1111111);
          if (
            this.questionExtendList.length > 0 &&
            this.trainingQuestionList.length > 0
          ) {
            this.trainingQuestionList.map((index, arr) => {
              if (
                this.questionExtendList.filter((item) => item.id == index.id)
                  .length > 0
              ) {
                this.trainingQuestionList.splice(arr, 1);
              }
            });
          }
        }
        this.trainingSet();
      },
    },
    // if (this.indexTypes == "VariantTraining") {
    //     this.getSearchList();
    //   }
    //   if (this.indexTypes == "layeredVariantTraining") {
    //     this.getSearchLayered();
    //   }
    questionExtendList: {
      deep: true,
      handler: function () {
        if (
          this.questionExtendList.length > 0 &&
          this.trainingQuestionList.length > 0
        ) {
          this.trainingQuestionList.map((index, arr) => {
            if (
              this.questionExtendList.filter((item) => item.id == index.id)
                .length > 0
            ) {
              this.trainingQuestionList.splice(arr, 1);
            }
          });
        }
      },
    },
  },
  created() {
    // trainingList
    // this.trainingList();
    this.setDifficultyList();
    this.uploadVariationData = { examPaperId: this.$route.query.id };
    // this.onloadView();
  },
  mounted() {
    window.addEventListener("scroll", this.disposeScroll); //监听滚动条
  },
  beforeDestroy() {
    //离开
    window.removeEventListener("scroll", this.disposeScroll); // 离开是后清除监听
  },
  methods: {
    showAdd() {
      this.$refs.supplementaryQuestion.init(this.getIndexQuestion);
    },
    handleClick() {
      // console.log(this.indexQuestion);
      if (this.uploadType == "1") {
        this.uploadVariationURL = "/exam/exampaper/uploadexamquestionopt";
      }
      if (this.uploadType == "2") {
        this.uploadVariationURL = "/exam/exampaper/uploadexamquestionextend";
      }
      if (this.uploadType == "3") {
        this.uploadVariationURL =
          "/exam/examquestion/searchimg/training/" +
          this.indexQuestion.examQuestionId;
      }
    },
    submitUpload() {
      this.$refs.uploadVariationFile.submit();
    },
    showUpLoadFile() {
      this.uploadVariationURL = "/exam/exampaper/uploadexamquestionextend";
      this.titleDialog = "自主上传分层拓展题";
      this.uploadVariation = true;
      this.uploadType = "2";
    },
    // 回到顶部
    backTop() {
      let timer = setInterval(function () {
        var scrollTop = Math.floor(
          document.body.scrollTop ||
            document.documentElement.scrollTop ||
            window.pageYOffset
        );
        var speed = Math.floor(scrollTop / 4);
        if (scrollTop <= 10) {
          document.documentElement.scrollTop = document.body.scrollTop = 0;
        } else {
          document.documentElement.scrollTop = document.body.scrollTop =
            scrollTop - speed;
        }
        if (scrollTop === 0) {
          clearInterval(timer);
        }
      }, 10);
    },
    setStatusStep() {
      // 检查是否所有的题都标注了知识点
      let hasAllQuestionsMark = true,
        hasPartQuestionsMark = false;
      //检查所有题是否都匹配了变式训练题
      let isOk = true;
      for (let i = 0; i < this.getExamQuestion.length; i++) {
        let que = this.getExamQuestion[i];
        if (que.examQuestionOpt1 == 0) {
          isOk = false;
        }
        if (!que.knowledgeNames || que.knowledgeNames.length === 0) {
          hasAllQuestionsMark = false;
        } else {
          hasPartQuestionsMark = true;
        }
      }
      // 标注知识点的话
      if (this.indexTypes == "KnowledgePoints") {
        if (hasAllQuestionsMark) {
          let data = {
            examPaperId: this.$route.query.id,
            statusStep: STEP_MATCHVARIATIONQUESTION,
            statusAnswer: 1,
          };
          setExamPaperStatusStep(data);
        } else if (hasPartQuestionsMark) {
          let data = {
            examPaperId: this.$route.query.id,
            statusStep: STEP_MARKKNOWLEDGEPOINT,
            statusAnswer: 2,
          };
          setExamPaperStatusStep(data);
        } else {
          let data = {
            examPaperId: this.$route.query.id,
            statusStep: STEP_MARKKNOWLEDGEPOINT,
            statusAnswer: 0,
          };
          setExamPaperStatusStep(data).then(() => {
            // console.log(res);
          });
        }
      }
      //变式训练题的话提交
      if (this.indexTypes == "VariantTraining") {
        if (isOk) {
          if (this.getExamPaper.statusStep < STEP_FINISHALL) {
            let data = {
              examPaperId: this.$route.query.id,
              statusStep: STEP_FINISHALL,
            };
            setExamPaperStatusStep(data).then(() => {});
          }
        }
      }
    },
    submitLayer() {
      if (this.diffCheckboxGroup == "") {
        this.$message({
          message: "请选择难度！",
          type: "warning",
        });
        return;
      }
      let data = {
        examQuestionOpt: this.indexLayerObj.id,
        examQuestionId: this.indexQuestion.examQuestionId,
        examPaperId: this.indexQuestion.examPaperId,
        type: 1,
        difficultyType: this.diffCheckboxGroup,
      };
      if (this.indexTypes == "layeredVariantTraining") {
        data.changingType = 2;
      }
      this.modelLoading = true;
      trainingExtend(data)
        .then(() => {
          this.$message({
            message: "分享成功！",
            type: "success",
          });
          this.dialogVisibleDiff = false;
          this.modelLoading = false;
          this.$store.commit("changeQuestion", data);
        })
        .catch(() => {
          this.dialogVisibleDiff = false;
          this.modelLoading = false;
        });
    },
    checkClick(e) {
      // console.log(e);
      // console.log(this.diffCheckboxGroup);
      if (e.length > 0) {
        let arr = [];
        arr.push(e[e.length - 1]);
        this.diffCheckboxGroup = arr;
      } else {
        this.diffCheckboxGroup = [];
      }
    },
    showDiffModel(v) {
      // console.log(v);
      this.indexLayerObj = v;
      this.dialogVisibleDiff = true;
    },
    //当前试题锁定的分层（所有）包含不同难度的变式训练题
    exampaperquestionExtend() {
      questionExtendV2(this.indexQuestion.id).then((res) => {
        let arr = [];
        res.data.data.forEach((index) => {
          index.status = 0;
          if (index.difficultyType == this.layeredTypes) {
            this.indexQuestion.examQuestionExtendList.map((item) => {
              if (
                item.difficultyType == index.difficultyType &&
                item.examQuestionOpt == index.id
              ) {
                index.status = item.status || 0;
                index.reasonType = item.reasonType;
                index.reasonText = item.reasonText;
                index.username = item.username;
                index.teacherName = item.teacherName;
                index.addType = item.type;
              }
            });
            arr.push(index);
          }
        });

        this.questionExtendList = arr;
        //  = res.data.data;
      });
    },
    //上拉加载
    disposeScroll() {
      if (this.current == this.pages) {
        return;
      } // 如果当前大于总页数，也就是最后一页，那么就不不触发了
      let time = new Date().getTime();
      if (time - this.reqTime < 2000) {
        return;
      }
      let top =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0; // 获取滚动条垂直的位置
      if (document.body.clientHeight - top - window.innerHeight == 0) {
        this.current++;
        this.reqTime = time;

        this.onloadView();
        // this.initSeaechData();
        //如果滚动条的位置加上窗口的高度大于可见的窗口可见的高度，那么也就是滚动条到低了
        // this.$emit('update') // 执行update方法
      }
    },
    //获取当前试题是否是当前锁定项
    getIndexLayeredType(id) {
      let type = true;
      // console.log(this.layeredTypes);
      this.indexQuestion.examQuestionExtendList.forEach((index) => {
        // console.log(index.examQuestionOpt == id);
        // console.log(index.difficultyType == this.layeredTypes);
        if (
          index.examQuestionOpt == id &&
          index.difficultyType == this.layeredTypes
        ) {
          type = false;
        }
      });
      // console.log(type);
      return type;
    },
    //锁定  取消分层变式训练题
    trainingExtend(val, type, reqType) {
      // difficultyType	分层区间（难度类型）：较难:1 /中等:2/ 较易:3		false
      // examPaperId	试卷ID		false
      // examQuestionId	试题ID		false
      // examQuestionOpt	变式训练题ID		false
      // id			false
      // type 区分新增、删除。1：新增，2：删除
      let data = {
        examQuestionOpt: val.id,
        examQuestionId: this.indexQuestion.examQuestionId,
        examPaperId: this.indexQuestion.examPaperId,
        type: type,
        difficultyType: this.layeredTypes,
      };
      if (this.indexTypes == "layeredVariantTraining") {
        data.changingType = 2;
      }
      trainingExtend(data).then(() => {
        this.$store.commit("changeQuestion", data);
        if (reqType == "new") {
          this.exampaperquestionExtend();
        }
        this.setExtendList(data);
      });
      // console.log(data);
    },
    //分层变式训练
    clickIndexLayeredType(v) {
      this.indexLayeredVariationBtn = v.val;
      // this.oldDataList = [];
      this.current = 1;
      this.getSearchLayered();
    },
    getSearchLayered() {
      if (this.indexQuestion.examQuestionExtendList.length > 0) {
        this.exampaperquestionExtend();
      }
      if (this.indexLayeredVariationBtn == 1) {
        this.condition();
      }
      if (this.indexLayeredVariationBtn == 2) {
        this.trainingList();
      }
      if (this.indexLayeredVariationBtn == 3) {
        this.getIdList();
      }
    },

    // 判断当前试题是否通过审核 在配题模式下
    getIndexStatus(v, type) {
      let id = v.id;
      let array = this.indexQuestion.examQuestionExtendList.filter(
        (item) =>
          item.examQuestionOpt == id && (item.type == 1 || item.type == 3)
      );
      if (array.length > 0 && type == array[0].status) {
        return true;
      } else {
        return false;
      }
    },
    // 获取当前试题
    getIndexStatusType(v) {
      let id = v.id;
      let array = this.indexQuestion.examQuestionExtendList.filter(
        (item) =>
          item.examQuestionOpt == id && (item.type == 1 || item.type == 3)
      );
      if (array.length > 0) {
        return array[0];
      } else {
        return {};
      }
    },
    //自主上传变式训练题 start
    getIndexQuestionType(id) {
      let type = true;

      this.indexQuestion.examQuestionExtendList.map((item) => {
        if (item.examQuestionOpt == id && (item.type == 1 || item.type == 3)) {
          type = false;
        }
      });

      return type;
    },
    handleUploadVariationBefore(file) {
      if (!file.name.endsWith(".docx") && this.uploadType != 3) {
        this.$message.error("只能上传 .docx 格式的 Word 文件！");
        return false;
      }
      if (
        this.uploadType == 3 &&
        file.name.endsWith(".png") &&
        file.name.endsWith(".jpg") &&
        file.name.endsWith(".jpeg") &&
        file.name.endsWith(".gif")
      ) {
        this.$message.error("只能上传.png,jpg,jpeg,gif格式文件！");
        return false;
      }
      let is30M = file.size / 1024 / 1024 <= 30;
      if (!is30M) {
        this.$message.error("文件大小不能超过 30 MB！");
        return false;
      }
      return true;
    },
    handleUploadVariationOnProgress() {
      this.loading = true;
    },
    handleUploadVariationOnSuccess(response, file, fileList) {
      // console.log(response);
      this.loading = false;
      if (response.code == 1) {
        this.$message({
          showClose: true,
          message: response.msg,
          type: "error",
        });
        return;
      }

      if (this.uploadType == 3) {
        // console.log(response);
        if (response.data.length == 0) {
          this.$message({
            showClose: true,
            message: "没有找到试题，请重试！",
            type: "warning",
          });
        } else {
          this.$message({
            showClose: true,
            message: "搜索成功，已为您添加至第一条！",
            type: "success",
          });
          this.handleUploadVariationFileRemove(fileList);
          this.trainingQuestionList = response.data.concat(
            this.trainingQuestionList
          );
          this.uploadVariation = false;
        }
      } else {
        this.handleUploadVariationFileRemove(fileList);
        this.uploadVariation = false;
        this.$emit("getNewView");
      }

      // this.examList();
    },
    handleUploadLayerVariationOnSuccess(response, file, fileList) {
      // console.log(response);
      this.loading = false;
      this.handleUploadVariationFileRemove(fileList);
      this.uploadLayerVariationDialogVisible = false;
      this.$emit("getNewView");
      // this.examList();
    },
    handleUploadVariationOnError(err, file, fileList) {
      // console.log(err);
      this.loading = false;
      this.handleUploadVariationFileRemove(fileList);
    },
    handleUploadVariationFileRemove(fileList) {
      fileList.splice(0, 1);
    },
    // 自主上传变式训练题 end
    // 获取同类题列表
    showQuestionModel(v) {
      this.$refs.similarityQuestion.init(v);
    },
    // 变式训练题取消
    cancelVariation(val) {
      let data = {
        examQuestionOpt: val.id,
      };
      this.unlockTraining(data);
      // console.log(val);
    },
    //变式训练题添加
    lockingVariation(val) {
      // console.log(val);
      // console.log(this.indexQuestion);
      let data = {
        examQuestionOpt: val.id,
      };
      data.statusReplaceTraining = 0;
      this.lockTraining(data, 0, val);
      // examQuestionOpt
    },
    // 自动过审
    setpassTraining() {
      let data = {
        examPaperId: this.$route.query.id,
        examPaperQuestionId: this.indexQuestion.id,
      };

      // isPassExtend isPassOpt isPass
      if (this.indexTypes == "VariantTraining") {
        data.type = 1;
        data.isPass = this.indexQuestion.isPassOpt == 0 ? 1 : 0;
      }
      if (this.indexTypes == "layeredVariantTraining") {
        data.type = 2;
        data.isPass = this.indexQuestion.isPassExtend == 0 ? 1 : 0;
      }

      passTraining(data).then(() => {
        let reqData = {
          id: this.indexQuestion.id,
        };
        if (this.indexTypes == "VariantTraining") {
          reqData.isPassOpt = data.isPass;
          this.indexQuestion.isPassOpt = reqData.isPass;
        }
        if (this.indexTypes == "layeredVariantTraining") {
          reqData.isPassExtend = data.isPass;
          this.indexQuestion.isPassExtend = reqData.isPass;
        }
        this.$store.commit("SET_CHANGE_AUDIT", reqData);
      });
    },
    //已锁定的变式训练题  有人为操作过才调用这个接口
    getTrainings() {
      trainingsV2(this.indexQuestion.id).then((res) => {
        let arr = [];
        res.data.data.map((index) => {
          index.status = 0;

          this.indexQuestion.examQuestionExtendList.map((item) => {
            if (
              item.examQuestionOpt == index.id &&
              (item.type == 3 || item.type == 1)
            ) {
              index.status = item.status || 0;
              index.reasonType = item.reasonType;
              index.reasonText = item.reasonText;
              index.username = item.username;
              index.teacherName = item.teacherName;
              index.addType = item.type;
            }
          });

          arr.push(index);
        });

        this.examTraining = JSON.parse(JSON.stringify(arr));
      });
    },
    showAudit() {
      this.examTraining = this.indexQuestion.examQuestionExtendList;
      if (!this.examTraining) {
        this.showErrList = [];
        this.examTraining = [];
        return;
      }
      this.examTraining.map((item) => {
        if (item.status == 2) {
          this.showErrList.push(item.id);
        }
      });
    },
    //页面加载
    onloadView() {
      // console.log("通过初始方法触发");
      if (this.$parent.topicType == 1) {
        // 如果是审题
        this.showAudit();
        return;
      }
      if (this.indexTypes == "KnowledgePoints") {
        this.trainingList();
      }
      if (this.indexTypes == "VariantTraining") {
        this.getSearchList();
      }
      if (this.indexTypes == "layeredVariantTraining") {
        this.getSearchLayered();
      }
    },
    // 条件搜索
    condition() {
      let data = {
        knowledgeId: this.knowledgeIds.join("#"),
        questionId: this.indexQuestion.examQuestionId,
        beginIndex: this.current,
        examPaperId: this.getExamPaper.id,
        quesDiff: this.indexDifficulty,
        quesType: this.indexType,
      };
      if (this.knowSearchType) {
        data.knowledgeGatherType = 2;
      } else {
        data.knowledgeGatherType = 0;
      }
      examRandom(data)
        .then((res) => {
          // this.loading = false;
          // this.trainingQuestionList = res.data.data;
          this.setDataList(res.data.data);
        })
        .catch(() => {
          this.pages = this.current;
          // this.loading = false;
        });
    },
    //修改试题信息
    putPaperQuestionObj(data) {
      data.id = this.indexQuestion.id;
      putPaperQuestionObj(data).then(() => {
        this.$store.commit("changeIdQuestion", data);
        setTimeout(() => {
          this.trainingSet();
        }, 100);
      });
    },
    getSearchList() {
      // console.log("通过搜索方法触发");
      // this.trainingQuestionList = [];
      // this.loading = true;
      // this.oldDataList = [];
      if (this.indexQuestion.examQuestionExtendList.length > 0) {
        this.getTrainings();
      }
      if (this.indexVariationBtn == 1) {
        this.getIdList();
      }
      if (this.indexVariationBtn == 2) {
        this.trainingList();
      }
      if (this.indexVariationBtn == 3) {
        this.condition();
      }
      if (this.indexVariationBtn == 4) {
        // this.condition();
        // this.uploadVariationDialogVisible = true;
      }
    },
    //举一反三
    getIdList() {
      this.trainingQuestionList = [];
      let { examQuestionId, recommendXuekeId } = this.indexQuestion;
      if (!examQuestionId || !recommendXuekeId) return;
      getRecommend(examQuestionId, recommendXuekeId, this.getExamPaper.id)
        .then((res) => {
          this.pages = 1;
          this.current = 1;
          if (res.data.data && res.data.data.length == 0) {
            this.$message({
              message: "未找到变式题",
              type: "warning",
            });
          } else {
            this.trainingQuestionList = res.data.data;
          }
          this.$nextTick(() => {
            window.MathJyeLoad.LayOut(document.body);
          });
        })
        .catch(() => {
          this.pages = this.current;
          // this.loading = false;
        });
    },
    // 设置难度数组
    setDifficultyList() {
      this.difficultyList = [{ value: 0, label: "全部" }].concat(
        this.difficultyList
      );
    },
    typeClick(v, key) {
      this[key] = v.value;
    },
    //显示知识点选择弹出层
    showChangeKnow() {
      this.$refs.knowledge.init(this.knowledgeIds);
    },
    setKnow(data) {
      this.knowledgeIds = data.knowledgeIds.split("#");
      this.knowledgeNames = data.knowledgeNames.split("#");
      this.$refs.knowledge.dialogVisible = false;
    },

    delKnowList(i) {
      this.knowledgeIds.splice(i, 1);
      this.knowledgeNames.splice(i, 1);
    },

    clicklayerIndexType(v, name) {
      this[name] = v.val;
      this.current = 1;
      this.trainingQuestionList = [];
      if (this.layeredTypes == 1) {
        this.indexDifficulty = 0.1;
      }
      if (this.layeredTypes == 2) {
        this.indexDifficulty = 0.5;
      }
      if (this.layeredTypes == 3) {
        this.indexDifficulty = 0.9;
      }
      this.oldDataList = [];
      this.current = 1;
      this.$store.commit("SET_LAYER_TYPS", this.layeredTypes);
      this.onloadView();
      this.setVariation();
    },
    //变式训练题请款获取当前试题有几道变式训练题
    setVariation() {
      // layeredList.  commonly difficult
      let simple = 0;
      let commonly = 0;
      let difficult = 0;

      if (
        !this.getIndexQuestion ||
        !this.getIndexQuestion.examQuestionExtendList
      ) {
        return;
      }
      this.getIndexQuestion.examQuestionExtendList.forEach((index) => {
        if (index.difficultyType == 1) {
          difficult++;
        }
        if (index.difficultyType == 2) {
          commonly++;
        }
        if (index.difficultyType == 3) {
          simple++;
        }
      });
      this.layeredList[0].number = simple;
      this.layeredList[1].number = commonly;
      this.layeredList[2].number = difficult;
    },
    clickIndexType(v) {
      this.indexVariationBtn = v.val;
    },
    clickIndexTypeModel(val) {
      this.titleDialog = "自主上传";
      this.uploadVariation = true;
      this.uploadType = val;

      this.handleClick();
    },
    // setStatus(v) {},
    submitData(v) {
      let data = {
        reasonType: "",
        reasonText: "",
        status: 1,
        examPaperId: this.$route.query.id,
        extendId: v.qId,
        examPaperQuestionId: this.getIndexQuestion.id,
      };
      this.btnLoading = true;
      saveResult(data)
        .then(() => {
          this.btnLoading = false;
          this.showErrList = this.showErrList.filter((item) => item != v.id);
          this.upLoadView(data);
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    upLoadView(rdata) {
      let examQuestionExtendList = this.getIndexQuestion.examQuestionExtendList;
      examQuestionExtendList = JSON.parse(
        JSON.stringify(examQuestionExtendList)
      );
      examQuestionExtendList = examQuestionExtendList.map((item) => {
        if (item.qId == rdata.extendId) {
          item.reasonText = "";
          item.reasonType = "";
          item.status = rdata.status;
        }
        return item;
      });

      let data = {
        id: this.getIndexQuestion.id,
        examQuestionExtendList: examQuestionExtendList,
      };
      this.examTraining = examQuestionExtendList;
      this.showErrList = this.showErrList.filter(
        (item) => item != rdata.extendId
      );
      this.$store.commit("SET_CHANGE_AUDIT", data);
    },
    //显示答案组件
    showAwswer(v) {
      if (this.showAwswerList.indexOf(v.id) == -1) {
        this.showAwswerList.push(v.id);
      } else {
        this.showAwswerList.splice(this.showAwswerList.indexOf(v.id), 1);
      }
    },
    //显示答案组件
    showTurnDown(v) {
      if (this.showErrList.indexOf(v.id) == -1) {
        this.showErrList.push(v.id);
      } else {
        this.showErrList = this.showErrList.filter((item) => item != v.id);
      }
    },
    // 变式训练题check刷新
    trainingSet() {
      this.trainingQuestionList.map((index) => {
        if (index.xuekeId == this.indexQuestion.xuekeId) {
          index.xuekeType = true;
        } else {
          index.xuekeType = false;
        }
      });
      if (this.indexTypes == "KnowledgePoints") {
        this.knowledgeListData.map((index) => {
          if (index.xuekeId == this.indexQuestion.xuekeId) {
            index.xuekeType = true;
          } else {
            index.xuekeType = false;
          }
        });
      }

      this.boxKey = new Date().getTime();
    },
    //知识点设置当前题未原题（取消原题）
    changeIndexQuestion(v) {
      // console.log(v.xuekeType);
      this.indexListQuestion = v;
      let data = {};
      if (v.xuekeType) {
        data.xuekeId = v.xuekeId;
        data.recommendXuekeId = v.xuekeId;
        data.statusKnowledge = v.id;
        data.isReplace = 1;
      } else {
        data.xuekeId = "";
        data.statusKnowledge = v.id;
        data.isReplace = 0;
      }
      data.statusReplaceKnowledge = 0;
      this.lockTraining(data, 1);
    },
    //搜索
    inherit(type) {
      if (this.inheritVal == "") {
        this.$message({
          message: "搜索内容",
          type: "warning",
        });
        return;
      }
      this.loading = true;
      if (type == 1) {
        getList(this.indexQuestion.examQuestionId, this.inheritVal)
          .then((res) => {
            this.loading = false;
            this.pages = 1;
            this.current = 1;
            this.subBtnType = false;
            this.trainingQuestionList = [];
            this.trainingQuestionList.push(res.data.data);
          })
          .catch(() => {
            this.pages = this.current;
            this.loading = false;
          });
      } else {
        postTrainingList(this.indexQuestion.examQuestionId, {
          quesKeyword: this.inheritVal,
          isNeedExamList: 1,
        })
          .then((res) => {
            this.loading = false;
            this.pages = 1;
            this.current = 1;
            this.subBtnType = false;
            // this.trainingQuestionList = [];
            this.trainingQuestionList = res.data.data;
          })
          .catch(() => {
            this.pages = this.current;
            this.loading = false;
          });
      }
    },
    showEditQ(v, editType) {
      this.$refs.editQuestion.init(v, this.getIndexQuestion, editType);
      // console.log(v);
    },
    // 复制
    copy(id) {
      this.$store.commit("SET_ALL_COPY", { key: id, name: "试题 ID 已复制。" });
    },
    // 设置当前题知识点格式
    setKnowList() {
      if (this.indexQuestion.knowledgeIds) {
        this.indexQuestion.knowledgeIds =
          this.indexQuestion.knowledgeIds.split("#");
        this.indexQuestion.knowledgeNames =
          this.indexQuestion.knowledgeNames.split("#");
      } else {
        this.indexQuestion.knowledgeIds = [];
        this.indexQuestion.knowledgeNames = [];
      }
      this.knowledgeIds = this.indexQuestion.knowledgeIds;
      this.knowledgeNames = this.indexQuestion.knowledgeNames;
    },
    //设置分层考试列表
    setExtendList(data) {
      this.trainingQuestionList.map((index) => {
        if (index.id == data.examQuestionOpt) {
          // index.examList.map((item) => {
          if (data.type == 2) {
            index.extendList = index.extendList.filter(
              (i) =>
                i.name != this.getExamPaper.name &&
                i.difficultyType != data.difficultyType
            );
          } else {
            index.extendList.push({
              name: this.getExamPaper.name,
              examDate: "当前考试",
              difficultyType: data.difficultyType,
            });
          }
          // });
        }
      });
      this.examTraining.map((index) => {
        if (index.id == data.examQuestionOpt) {
          // index.examList.map((item) => {
          if (data.type == 2) {
            index.extendList = index.extendList.filter(
              (i) =>
                i.name != this.getExamPaper.name &&
                i.difficultyType != data.difficultyType
            );
          } else {
            index.extendList.push({
              name: this.getExamPaper.name,
              examDate: "当前考试",
              difficultyType: data.difficultyType,
            });
          }
          // });
        }
      });
      this.similarQuestionsList.map((index) => {
        if (index.id == data.examQuestionOpt) {
          // index.examList.map((item) => {
          if (data.type == 2) {
            index.extendList = index.extendList.filter(
              (i) =>
                i.name != this.getExamPaper.name &&
                i.difficultyType != data.difficultyType
            );
          } else {
            index.extendList.push({
              name: this.getExamPaper.name,
              examDate: "当前考试",
              difficultyType: data.difficultyType,
            });
          }
          // });
        }
      });
      this.boxKey = new Date().getTime();
    },
    //设置已考试列表
    setList(data, type) {
      //type == 1 就是删除  trainingQuestionList examTraining similarQuestionsList
      this.trainingQuestionList.map((index) => {
        if (index.id == data.examQuestionOpt) {
          // index.examList.map((item) => {
          if (type == 1) {
            index.examList = index.examList.filter(
              (i) => i.name != this.getExamPaper.name
            );
          } else {
            index.examList.push({
              name: this.getExamPaper.name,
              examDate: "当前考试",
            });
          }
          // });
        }
      });
      this.examTraining.map((index) => {
        if (index.id == data.examQuestionOpt) {
          // index.examList.map((item) => {
          if (type == 1) {
            index.examList = index.examList.filter(
              (i) => i.name != this.getExamPaper.name
            );
          } else {
            index.examList.push({
              name: this.getExamPaper.name,
              examDate: "当前考试",
            });
          }
          // });
        }
      });
      this.similarQuestionsList.map((index) => {
        if (index.id == data.examQuestionOpt) {
          // index.examList.map((item) => {
          if (type == 1) {
            index.examList = index.examList.filter(
              (i) => i.name != this.getExamPaper.name
            );
          } else {
            index.examList.push({
              name: this.getExamPaper.name,
              examDate: "当前考试",
            });
          }
          // });
        }
      });
      this.boxKey = new Date().getTime();
      // console.log(this.examTraining);
      // console.log(this.similarQuestionsList);
      // console.log("222222222222");
    },
    //提交取消变式训练题
    unlockTraining(data) {
      // let data = {};
      data.id = this.indexQuestion.id;
      if (this.btnDisabled) {
        this.$message({
          message: "网络状态不佳，请不要快速点击！",
          type: "warning",
        });
        return;
      }
      this.btnDisabled = true;
      unlockTraining(data)
        .then(() => {
          this.btnDisabled = false;
          this.$store.commit("delTraining", data);
          this.setList(data, 1);
          // consol
        })
        .catch(() => {
          this.pages = this.current;
          this.btnDisabled = false;
        });
    },
    submitQuestion(data) {
      if (this.indexTypes == "VariantTraining") {
        this.lockTraining(data);
      }
      if (this.indexTypes == "layeredVariantTraining") {
        data.id = data.examQuestionOpt;
        this.trainingExtend(data, 1, "new");
        // this.$store.commit("SET_NEW_VIEW");
        // console.log(data);
        // this.lockTraining(data);
      }
    },
    // 提交新增变式训练题 锁定知识点
    lockTraining(data, type) {
      let onload = false;
      if (!data.id) {
        data.id = this.indexQuestion.id;
      } else {
        onload = true;
      }

      if (this.btnDisabled) {
        this.$message({
          message: "网络状态不佳，请不要快速点击！",
          type: "warning",
        });
        return;
      }
      if (this.indexTypes == "VariantTraining") {
        data.type = 1;
      }
      this.btnDisabled = true;
      lockTraining(data)
        .then((res) => {
          this.btnDisabled = false;
          //type为1就是在锁定知识点
          if (type == 1 && res.data.data.number) {
            data.knowledgeIds = res.data.data.number;
            data.knowledgeNames = res.data.data.name;
            this.$store.commit("changeIdQuestion", data);
          }
          if (!type) {
            data.extendType = 1;
            this.$store.commit("putTraining", data);

            this.setList(data);
            if (onload) {
              this.onloadView();
            }
          }
          // putTraining
        })
        .catch(() => {
          this.btnDisabled = false;
          if (type == 1) {
            if (data.isReplace == 1) {
              this.setTraningList(1);
            }
            if (data.isReplace == 0) {
              this.setTraningList(0);
            }
          }
        });
    },
    setTraningList(type) {
      if (!this.indexListQuestion) {
        return;
      }
      //1就是取消知识点  其他就是锁定知识点
      if (type == 1) {
        this.trainingQuestionList.map((index) => {
          if (index.id == this.indexListQuestion.id) {
            index.xuekeType = false;
          }
        });
        this.knowledgeListData.map((index) => {
          if (index.id == this.indexListQuestion.id) {
            index.xuekeType = false;
          }
        });
      } else {
        this.trainingQuestionList.map((index) => {
          if (index.id != this.indexListQuestion.id) {
            index.xuekeType = false;
          } else {
            index.xuekeType = true;
          }
        });
        this.knowledgeListData.map((index) => {
          if (index.id != this.indexListQuestion.id) {
            index.xuekeType = false;
          } else {
            index.xuekeType = true;
          }
        });
      }
      //如果当前xuekeid为空 就讲所有设为空
      if (!this.indexQuestion.xuekeId) {
        this.trainingQuestionList.map((index) => {
          index.xuekeType = false;
        });
      }
      // console.log(this.indexQuestion.xuekeId);
      // console.log(this.trainingQuestionList);
      this.boxKey = new Date().getTime();
    },
    //锁定知识点
    addKnow(v) {
      this.indexListQuestion = v;
      let data = {
        recommendXuekeId: v.xuekeId,
        statusKnowledge: v.id,
        isReplace: 2,
        statusReplaceKnowledge: 0,
      };
      if (this.indexQuestion.xuekeId != v.xuekeId) {
        data.xuekeId = "";
      }
      // console.log(data);
      this.lockTraining(data, 1);
    },
    // 取消知识点锁定
    delKnow() {
      let data = {
        recommendXuekeId: "",
        statusKnowledge: 1,
      };
      this.putPaperQuestionObj(data);
    },
    setDataList(data) {
      // console.log(this.current);
      if (this.current == 1) {
        this.trainingQuestionList = data;
        this.oldDataList = [...data];
      } else {
        if (!this.oldDataList || this.oldDataList.length == 0) {
          this.trainingQuestionList = [...data];
        }
        if (!this.oldDataList || this.oldDataList.length == 0) {
          // console.log("进了");
          this.oldDataList = [...data];
        } else {
          if (this.oldDataList[0].id == data[0].id) {
            // console.log(data);
            this.pages = this.current;
          } else {
            // console.log("进入了条件");
            this.oldDataList = [...data];
            this.trainingQuestionList = this.trainingQuestionList.concat(data);
            // console.log(this.trainingQuestionList);
          }
        }
      }
      this.$nextTick(() => {
        window.MathJyeLoad.LayOut(document.body);
      });
    },
    //根据id获取试题
    getQuestion(id) {
      answer(id).then((res) => {
        this.knowledgeListData.push(res.data.data);
        // console.log(res);
      });
    },
    // 变式训练题列表
    trainingList() {
      if (!this.oldQuestionId) {
        return;
      }
      if (this.current == 1) {
        this.loading = true;
      }

      let page = this.current;
      trainingList(this.oldQuestionId, this.current, this.getExamPaper.id)
        .then((res) => {
          this.loading = false;
          // console.log(page);
          if (page == 1) {
            this.current = page;
          }
          this.setDataList(res.data.data);
        })
        .catch(() => {
          this.pages = this.current;
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss">
.model-check {
  width: 100%;
  .el-radio.is-bordered.is-checked {
    border-color: #2474ed;
  }
  .el-radio {
    width: 100%;
  }
  .el-radio {
    margin: 0 0 6px;
    display: block;
  }
  .el-radio.is-bordered + .el-radio.is-bordered {
    margin-left: 0;
  }
}
.exam-list-box {
  display: flex;
  justify-content: space-between;
  // padding: 12px;
  margin-bottom: 6px;
  &:last-child {
    margin-bottom: 0;
  }
  // span {

  // }
}
.examQuestionList {
  // .question-btn-right {

  // }
  .uploadVariationDialog .uploadVariation .el-upload .el-upload-dragger {
    width: 610px;
    height: 150px;
  }
  .uploadVariationDialog {
    .uploadVariation {
      padding: 20px 0 0 0;
      text-align: center;
      .upload-text {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  .difficulty-input {
    width: 48px;
    height: 21px;
    margin: 4px 8px 3px 0;
    input {
      line-height: 21px;
      height: 21px;
      padding: 0 4px;
      text-align: center;
    }
  }
  .search {
    color: $primary-color;
    border: 1px solid $primary-color;
    padding: 0;
    margin: 4px 8px 3px 0;
    padding: 0 8px;
    line-height: 21px;
    &:hover {
      background-color: $primary-color;
      color: #ffffff;
    }
  }
  .hr {
    background: rgba(0, 0, 0, 0.85);
    width: 12px;
    height: 1px;
    border: none;
    margin-top: 13px;
    margin-right: 7px;
  }

  .two-tree-box {
    display: flex;
    justify-content: space-between;
    > div {
      height: 400px;
      overflow-y: scroll;
      width: 50%;
      &::-webkit-scrollbar {
        width: 3px;
        height: 3px;
      }

      ///*<!--修改 滑块 -->*/
      &::-webkit-scrollbar-thumb {
        background-color: #2474ed;
      }
    }
    .is-checked {
      .el-tree-node__content {
        > .el-tree-node__label {
          color: #2474ed;
        }
      }
    }
  }
  label {
    margin-bottom: 0;
  }
  .search-box {
  }
  .layered-search-list {
    .unload-btn {
      padding: 5px 15px;
      border-color: $primary-color;
      color: $primary-color;
      position: absolute;
      right: 0;
      top: 40px;
      background-color: #ffffff;
      &:hover {
        background-color: #ffffff;
        color: $primary-color;
      }
    }
    .input-box {
      display: flex;
      position: absolute;
      right: 0;
      flex-wrap: wrap;
      .input-up {
        width: 100%;
      }
    }
    .btn-all {
      padding: 5px 15px;
    }
    .el-input {
      width: 334px;
      margin-right: 12px;
    }
  }
}
</style>
<style scoped lang="scss">
.examQuestionList {
  border: 1px solid #e9e9e9;
  margin: 18px 0 40px;
  border-radius: 4px;
  min-height: 300px;
  .question-item-box {
    height: 400px;
  }
  .search-item-box {
    padding: 0 18px;
    .search-item {
      margin-top: 8px;
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      width: 80%;
      .type-list {
        cursor: pointer;
      }
      .search-item-title {
        margin: 4px 8px 3px 0;
        line-height: 21px;
      }
      > span {
        margin-right: 8px;
      }
      .size-btn {
        color: $primary-color;
        margin: 4px 8px 3px 0;
        cursor: pointer;
      }
      .know-list {
        background: #e6e6e4;
        border-radius: 4px;
        padding: 0 8px;
        color: #000000;
        position: relative;
        margin: 4px 8px 3px 0;
        line-height: 21px;
        &:hover {
          i {
            opacity: 1;
          }
        }
        i {
          // el-icon-remove
          opacity: 0;
          color: red;
          position: absolute;
          right: -8px;
          top: -8px;
          cursor: pointer;
        }
      }
      .difficulty-list {
        background-color: unset;
        color: #333333;
        cursor: pointer;
      }

      .on {
        background-color: $primary-color;
        color: #ffffff;
      }
    }
  }
  .layered-search-list {
    position: relative;
  }
  .layered-search {
    margin-top: 8px;
    display: flex;
    align-items: center;
    ::v-deep .el-input__inner {
      height: 28px;
      line-height: 28px;
    }
    .search-cnt {
      padding: 6px 12px;
      border-radius: 4px;
      border: 1px solid #d5d6db;
      margin-right: 8px;
      line-height: 14px;
      cursor: pointer;

      &:hover {
        border-color: $primary-color;
        color: #ffffff;
        background-color: $primary-color;
      }
    }
    .on {
      border-color: $primary-color;
      color: #ffffff;
      background-color: $primary-color;
    }
  }
  .question-list-box {
    padding: 18px;
  }
  .on-succ.question-list {
    border-color: $primary-color;
    .question-btn-box {
      display: flex;
    }
  }
  .question-list {
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    margin-bottom: 12px;
    position: relative;
    overflow: hidden;
    min-height: 40px;
    .question-header {
      background: #f9fafc;
      border-bottom: 1px solid #e9e9e9;
      padding: 10px 18px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .span-tips {
        background: #ffecdd;
        border-radius: 4px;
        padding: 2px 10px;
        color: #ff5700;
        margin-left: 4px;
      }
      .header-type {
        color: $primary-color;
      }
      .question-header-right {
        align-items: center;
        display: flex;
        justify-content: flex-end;
        width: 600px;
        line-height: 16px;
        .tips {
          display: flex;

          margin-left: 12px;
          p {
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 300px;
            color: $primary-color;
          }
          span {
            margin-left: 12px;
            cursor: pointer;
          }
        }
      }
    }
    &:hover {
      border-color: $primary-color;
      .question-btn-box {
        display: flex;
      }
    }
    .question-btn-box {
      // opacity: 0;
      // visibility: hidden;
      background: #f9fafc;
      border: 1px solid #e0e0e0;
      padding: 5px 12px;
      width: 100%;
      display: none;
      align-items: center;
      justify-content: space-between;
      position: relative;

      .show-err-span {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: inline-block;
        max-width: 200px;
      }
      .err-span {
        color: #ed2424;
        align-items: center;
        display: flex;
        flex-shrink: 0;
        span {
          margin-left: 12px;
        }
        img {
          margin-right: 4px;
        }
      }
      .name-box {
        margin-left: 4px;
      }
      .succ-span {
        color: #2474ed;
        align-items: center;
        display: flex;
        img {
          margin-right: 4px;
        }
      }
      .parm-span {
        color: #ff7303;
      }
      .ans-box {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);

        transition: 0.2s linear;
        cursor: pointer;
        user-select: none;
        img {
          width: 18px;
          transition: 0.2s linear;
          transform: rotate(180deg);
        }
      }
      .ans-on {
        img {
          transform: rotate(0);
        }
      }
      .tips {
        display: flex;
        width: 30%;
        p {
          margin: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 60%;
          color: $primary-color;
        }
        span {
          margin-left: 12px;
          color: $primary-color;
          cursor: pointer;
        }
      }
      .question-btn-right {
        display: flex;
        align-items: center;
        .tips-icon {
          cursor: pointer;
          color: #0a1119;
          img {
            width: 16px;
            vertical-align: text-bottom;
          }
        }
        .tips-icon-on {
          color: $primary-color;
        }

        .el-button {
          // padding: 4px 8px;
          margin-right: 16px;
          margin-left: 0;
        }

        // justify-content: space-between;
      }
    }
    .show-btn {
      display: flex;
    }
    .question-html {
      padding: 5px 8px;
    }
  }
  .search-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    background: #f9fafc;
    border-bottom: 1px solid #e9e9e9;
    padding: 8px 18px;
    .search-box-title {
      font-size: 16px;
      color: #0a1119;
      font-weight: 500;
    }
    .unload-btn {
      padding: 5px 15px;
      border-color: $primary-color;
      color: $primary-color;
      position: absolute;
      right: 0;
      top: 40px;
      background-color: #ffffff;
      &:hover {
        background-color: #ffffff;
        color: $primary-color;
      }
    }
    .btn-all {
      padding: 5px 15px;
    }
    > div {
      display: flex;
      align-items: center;
    }
    .check-btn {
      .el-checkbox {
        height: 32px;
        margin-right: 12px;
      }
      .check-btn-item {
        padding: 4.5px 16px;
        border-radius: 4px;
        border: 1px solid #dee0e7;
        cursor: pointer;
        // line-height: 14px;
        img {
          vertical-align: text-bottom;
        }
        .on {
          display: none;
        }
      }
      .check-btn-item-on {
        border: 1px solid #2474ed;
        color: #2474ed;
        img {
          display: none;
        }
        .on {
          display: inline-block;
        }
      }
    }
    .search-left {
      .filter-item {
        padding: 0 8px;
        line-height: 30px;
        border: 1px solid #e0e0e0;
        color: $primary-color;
        border-radius: 4px;
        overflow: hidden;
        margin-right: 12px;
        cursor: pointer;
        flex-shrink: 0;
        transition: 0.2s linear;
        &:hover {
          border-color: $primary-color;
          color: #ffffff;
          background-color: $primary-color;
        }
      }
      .on {
        border-color: $primary-color;
        color: #ffffff;
        background-color: $primary-color;
      }
    }
  }
}
</style>
