<template>
  <div class="paperStatus">
    <div class="check-type-box">
      <div v-if="$parent.topicType == 0" class="paper-left-box">
        <div class="span succ-tips" @click="linkTo(1)">
          <i class="el-icon-check"></i>
        </div>
        <div class="title">裁切试题</div>
        <hr />
        <div
          class="span"
          :class="{
            voluntarily: knowledgeErrType,
            'succ-tips': knowledgeType,
          }"
          @click="linkTo(2)"
        >
          <template v-if="knowledgeType">
            <i class="el-icon-check"></i>
          </template>
          <template v-else> 2 </template>
        </div>
        <div class="title">标注知识点</div>
        <hr />
        <div
          class="span"
          :class="{ 'succ-tips': trainingType, voluntarily: trainingErrType }"
          @click="linkTo(3)"
        >
          <template v-if="trainingType && !trainingErrType">
            <i class="el-icon-check"></i>
          </template>
          <template v-else> 3 </template>
        </div>
        <div class="title">匹配变式题</div>
        <hr />

        <div
          class="span"
          :class="{
            'succ-tips': layTrainingType,
          }"
          @click="linkTo(4)"
        >
          <template v-if="layTrainingType">
            <i class="el-icon-check"></i>
          </template>
          <template v-else> 4 </template>
        </div>
        <div class="title">匹配分层变式题</div>
      </div>
      <div v-if="$parent.topicType == 1" class="time-box">
        <img src="@/assets/newExam/newpaper/time.png" alt="" />
        <template v-if="getTimeType()">尚未开始 ( {{ getTimeVal() }})</template>
        <template v-else>
          <template v-if="$parent.paperCnt.trainingTimeEffective == 0">
            审核时间：不限时(-)
          </template>
          <template v-else>
            审核剩余时间：{{ showTime }} ( {{ getTimeVal() }})
          </template>
        </template>
      </div>
      <template v-if="userInfo.roleId == 5">
        <el-button @click="$router.go(-1)">返回</el-button>
      </template>
      <el-radio-group
        v-else
        v-model="topicType"
        size="small"
        @change="lintBtn()"
      >
        <el-radio-button :label="0">配题模式</el-radio-button>
        <el-radio-button :label="1">审题模式</el-radio-button>
      </el-radio-group>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { switchMode } from "@/core/api/exam/exampaper";
import { getExampaper } from "@/core/api/exam/examLore";
import { getStore } from "@/core/util/store";
import { mapGetters } from "vuex";
export default {
  name: "PaperStatus",
  data() {
    return {
      showTime: "",
      topicType: 0,
      userInfo: {},
      knowledgeErrType: false,
      trainingErrType: false,
      knowledgeType: false,
      trainingType: false,
      layTrainingType: false,
    };
  },
  computed: {
    ...mapGetters(["getExamQuestion"]),
  },
  watch: {
    // 监听当前题在试题列表中是否更新了数据
    getExamQuestion: {
      deep: true,
      handler() {
        this.questionListData = [...this.getExamQuestion];
        // 添加判断 是否自动标注
        this.initStatus();
      },
    },
  },
  created() {
    this.userInfo = getStore({ name: "userInfo" });
  },
  destroyed() {
    clearInterval(this.setTime);
  },
  methods: {
    linkTo(step) {
      if (step === 1) {
        // 裁切试题
        // if (this.statusReplace === 1) {
        //   this.$message.error("已有试题电子化，不能重新切题！");
        //   return;
        // }
        let filePath = this.$parent.examPaper.filePath;
        let filePathArr = filePath.split(",");
        if (filePathArr[0].endsWith(".docx")) {
          // Word 文件
          this.$router.push({
            // name: "exampaperdivide",
            name: "ExamNewPaperDivide",
            query: {
              ...this.$route.query,
            },
          });
        } else if (filePathArr[0].endsWith(".pdf")) {
          // PDF 文件
          this.$router.push({
            name: "examNewPaperCropper",
            query: {
              ...this.$route.query,
            },
          });
        } else {
          // 图片文件
          this.$router.push({
            // name: "exampaperpicdivide",
            name: "examNewPaperCropper",
            query: {
              ...this.$route.query,
            },
          });
        }
      } else if (step === 2) {
        // 标注知识点
        this.$router.push({
          name: "examlore",
          query: { ...this.$route.query },
        });
      } else if (step === 3) {
        // 锁定变式题
        // this.$message.error("不能直接进行后面的步骤！");
        this.$router.push({
          name: "examvariation",
          query: {
            ...this.$route.query,
          },
        });
      } else if (step === 4) {
        this.$router.push({
          name: "ExamLayeredVariant",
          query: {
            ...this.$route.query,
          },
        });
      }
    },
    initStatus() {
      this.knowledgeErrType = false;
      this.trainingErrType = false;
      this.knowledgeType = false;
      this.trainingType = false;
      let indexArr = this.questionListData.filter((item) => item.isPush == 1);

      let knowledgeArr = indexArr.filter(
        (item) => item.statusReplaceKnowledge == 1
      );
      let trainingArr = indexArr.filter(
        (item) => item.statusReplaceTraining == 1
      );
      // 判断是不是没有人工标注知识点
      if (knowledgeArr.length == indexArr.length && indexArr.length > 0) {
        this.knowledgeErrType = true;
      }
      // 判断是不是没有人工标注变式题
      if (trainingArr.length == indexArr.length && indexArr.length > 0) {
        this.trainingErrType = true;
      }
      // 获取分层变式题是否已标注
      let index = 0;
      // 变式题
      let indexVar = 0;
      indexArr.map((item) => {
        let arr = item.examQuestionExtendList.filter((item) => item.type == 2);
        let vaArr = item.examQuestionExtendList.filter(
          (item) => item.type == 1
        );

        if (arr.length > 0) {
          index++;
        }
        if (vaArr.length > 0) {
          indexVar++;
        }
        if (item.isPush == 1) {
          // console.log(item.statusKnowledge);
          // 是否标注了知识点
          if (item.statusKnowledge >= 1) {
            this.knowledgeType = true;
          }
        }
      });
      if (indexVar == indexArr.length) {
        this.trainingType = true;
      } else {
        this.trainingType = false;
      }

      if (index == indexArr.length) {
        this.layTrainingType = true;
      } else {
        this.layTrainingType = false;
      }
    },
    init() {
      this.topicType = this.$parent.topicType;
    },
    showTipsMode() {
      this.$confirm(
        "必须在全部试题已设定“同类题”或“分层拓展题”后，才可切换至审核模式。",
        "未满足审核模式启用条件",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          showCancelButton: false,
        }
      )
        .then(() => {})
        .catch(() => {});
    },
    showCheck() {
      this.$confirm(
        "切换至审核模式后，审题教师将可查看并操作审核全部题目。",
        "切换为审题模式",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.initViewList();
        })
        .catch(() => {
          this.topicType = this.$parent.topicType;
        });
    },
    lintBtn() {
      if (this.topicType == 0) {
        this.initViewList();
        return;
      }
      getExampaper(this.$route.query.id).then(async (res) => {
        // console.log();
        if (res.data.data.trainingMateStatus == 0) {
          this.showTipsMode();
          this.topicType = this.$parent.topicType;
        } else {
          this.showCheck();
        }
      });
    },
    initViewList() {
      // this.topicType = ;
      let data = {
        examPaperId: this.$route.query.id,
        mode: this.topicType,
      };
      switchMode(data)
        .then(() => {
          this.$parent.topicType = this.topicType;
          this.$message({
            showClose: true,
            message: this.topicType ? "审题已开启!" : "审题已关闭!",
            type: "success",
          });
          this.$parent.initViewList();
        })
        .catch(() => {
          this.topicType = this.$parent.topicType;
        });
    },
    getTime() {
      let { trainingTimeBegin, trainingTimeEnd } = this.$parent.paperCnt;

      if (!trainingTimeBegin && !trainingTimeEnd) {
        this.showTime = "-";
        return;
      }
      trainingTimeEnd = new Date(trainingTimeEnd).getTime();
      trainingTimeBegin = new Date(trainingTimeBegin).getTime();
      let nowTime = new Date().getTime();
      let time = trainingTimeEnd - nowTime;
      let str = moment(time).format("DD天HH小时mm分");

      // if (nowTime < trainingTimeBegin) {
      //   this.showTime = "尚未开始";
      //   return;
      // }
      if (time < 0) {
        this.showTime = "已过期";
      } else {
        this.showTime = str;
      }
      this.setTime = setInterval(() => {
        nowTime = new Date().getTime();
        time = trainingTimeEnd - nowTime;
        str = moment(time).format("DD天HH小时mm分");
        if (time < 0) {
          this.showTime = "已过期";
        } else {
          this.showTime = str;
        }
      }, 3000);
    },
    getTimeVal() {
      let { trainingTimeBegin, trainingTimeEnd } = this.$parent.paperCnt;

      if (!trainingTimeBegin && !trainingTimeEnd) {
        return "-";
      }
      this.getTime();
      trainingTimeEnd = moment(trainingTimeEnd).format("YYYY-MM-DD HH:mm");
      trainingTimeBegin = moment(trainingTimeBegin).format("YYYY-MM-DD HH:mm");
      return trainingTimeBegin + " - " + trainingTimeEnd;
    },
    getTimeType() {
      if (this.$parent.paperCnt.trainingTimeEffective == 0) {
        return false;
      }
      let { trainingTimeBegin, trainingTimeEnd } = this.$parent.paperCnt;

      if (!trainingTimeBegin && !trainingTimeEnd) {
        return false;
      }

      let time = new Date().getTime();
      trainingTimeBegin = new Date(trainingTimeBegin).getTime();
      return trainingTimeBegin > time;
    },
  },
};
</script>
<style lang="scss" scoped>
.paperStatus {
  .check-type-box {
    padding: 8px 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f9fafc;
  }
  .time-box {
    img {
      margin-right: 4px;
    }
  }
  .el-radio-group {
    flex-shrink: 0;
  }
  .paper-left-box {
    display: flex;
    align-items: center;
    line-height: 1;
    .span {
      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      margin-right: 8px;

      background-color: #bdbdbd;
      border-radius: 50%;
      color: #ffffff;
      cursor: pointer;
    }

    .succ-tips {
      background-color: #2474ed;
    }
    .voluntarily {
      background-color: #f57574 !important;
    }
    .title {
      font-size: 14px;
      font-weight: 500;
    }

    hr {
      margin: 0 16px;
      width: 80px;
      height: 1px;
      background-color: #e0e0e0;
      box-sizing: inherit;
    }
  }
}
</style>
