<template>
  <div class="unloadQuestion">
    <!-- 上传变式训练题弹窗 -->
    <el-dialog
      class="uploadVariationDialog"
      title="上传试题"
      :visible.sync="uploadVariation"
      width="650px"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <el-tabs v-model="uploadType" type="border-card" @tab-click="handleClick">
        <el-tab-pane label="变式训练题" name="1">
          <div v-if="uploadType == 1" class="upload-tips">
            通过上传 Word 文件上传变式题，可一次上传多道多套题。<el-link
              type="primary"
              href="https://static.wtjy.com/exam/paper/template/%E8%87%AA%E4%B8%BB%E4%B8%8A%E4%BC%A0%E5%8F%98%E5%BC%8F%E8%AE%AD%E7%BB%83%E9%A2%98%E6%A8%A1%E6%9D%BF.docx"
              >下载 Word 模板<span class="el-icon-download"></span
            ></el-link>
            <br />注意： <br />1、【套号】、【试题】为必填。
            <br />2、标签【套号】填写范围为 1-5。表示该题为第几套变式题。
            <br />3、模板里只提供了一道题的标签，增加试题只需要换行增加一套这些标签即可。
          </div>
        </el-tab-pane>
        <el-tab-pane label="分层拓展题" name="2">
          <div v-if="uploadType == 2" class="upload-tips">
            通过上传 Word 文件上传变式题，可一次上传多道多套题。<el-link
              type="primary"
              href="https://static.wtjy.com/exam/paper/template/%E8%87%AA%E4%B8%BB%E4%B8%8A%E4%BC%A0%E5%88%86%E5%B1%82%E5%8F%98%E5%BC%8F%E9%A2%98%E6%A8%A1%E6%9D%BF.docx"
              >下载 Word 模板<span class="el-icon-download"></span
            ></el-link>
            <br />注意： <br />1、【难度】、【试题】为必填。
            <br />2、标签【难度】填写范围为 1-3，分别代表 较难 、 中等 、 较易
            。表示该题的分层难度。
            <br />3、模板里只提供了一道题的标签，增加试题只需要换行增加一套这些标签即可。
            <br />4、上传会根据【题号】、【难度】增加该题该难度的分层变式题。
          </div>
        </el-tab-pane>
        <el-tab-pane label="图片搜索试题" name="3">
          根据上传的图片搜索试题，不会绑定至小题，支持上传文件类型：.png,jpg,jpeg,gif
        </el-tab-pane>
      </el-tabs>

      <el-upload
        ref="uploadVariationFile"
        class="uploadVariation"
        :action="uploadVariationURL"
        :headers="uploadVariationHeaders"
        :data="uploadVariationData"
        :auto-upload="false"
        :accept="uploadType == 3 ? '.png,.jpg,.jpeg,.gif' : '.docx'"
        :before-upload="handleUploadVariationBefore"
        :on-progress="handleUploadVariationOnProgress"
        :on-success="handleUploadVariationOnSuccess"
        :on-error="handleUploadVariationOnError"
        :limit="1"
        drag
      >
        <div class="upload-text">将文件拖放到此处或点击上传</div>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose()">取 消</el-button>
        <el-button type="primary" :loading="loading" @click="submitUpload()">
          上 传
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getStore } from "@/core/util/store";
export default {
  name: "UnloadQuestion",
  data() {
    return {
      // 上传变式题
      uploadVariation: false,
      loading: false,
      uploadVariationURL: "/exam/exampaper/uploadexamquestionopt",
      uploadVariationHeaders: {
        Authorization: "Bearer " + getStore({ name: "access_token" }),
        "SCHOOL-ID": getStore({ name: "school_id" }),
      },
      uploadType: 1,
      uploadVariationData: {},
    };
  },
  created() {},
  methods: {
    handleClose() {
      this.uploadVariation = false;
      this.$parent.dialogVisible = true;
    },
    handleClick() {
      if (this.uploadType == "1") {
        this.uploadVariationURL =
          "/resource/exampaperquestion/supplement/training";
        this.uploadVariationData = {
          type: 3,
          examPaperQuestionId: this.indexQuestion.id,
          examPaperId: this.$route.query.id,
        };
      }
      if (this.uploadType == "2") {
        this.uploadVariationURL =
          "/resource/exampaperquestion/supplement/training";
        this.uploadVariationData = {
          type: 4,
          examPaperQuestionId: this.indexQuestion.id,
          examPaperId: this.$route.query.id,
        };
      }
      if (this.uploadType == "3") {
        this.uploadVariationURL =
          "/exam/examquestion/searchimg/training/" +
          this.indexQuestion.examQuestionId;
        this.uploadVariationData = {};
      }
    },
    submitUpload() {
      this.$refs.uploadVariationFile.submit();
    },
    init(val) {
      this.uploadType = val;
      this.uploadVariation = true;
      this.indexQuestion = this.$parent.indexQuestion;
      this.handleClick();
    },
    //自主上传变式训练题 start
    getIndexQuestionType(id) {
      let type = true;

      this.indexQuestion.examQuestionExtendList.map((item) => {
        if (item.examQuestionOpt == id && (item.type == 1 || item.type == 3)) {
          type = false;
        }
      });

      return type;
    },
    handleUploadVariationBefore(file) {
      if (!file.name.endsWith(".docx") && this.uploadType != 3) {
        this.$message.error("只能上传 .docx 格式的 Word 文件！");
        return false;
      }
      if (
        this.uploadType == 3 &&
        file.name.endsWith(".png") &&
        file.name.endsWith(".jpg") &&
        file.name.endsWith(".jpeg") &&
        file.name.endsWith(".gif")
      ) {
        this.$message.error("只能上传.png,jpg,jpeg,gif格式文件！");
        return false;
      }
      let is30M = file.size / 1024 / 1024 <= 30;
      if (!is30M) {
        this.$message.error("文件大小不能超过 30 MB！");
        return false;
      }
      return true;
    },
    handleUploadVariationOnProgress() {
      this.loading = true;
    },
    handleUploadVariationOnSuccess(response, file, fileList) {
      // console.log(response);
      this.loading = false;
      if (response.code == 1) {
        this.$message({
          showClose: true,
          message: response.msg,
          type: "error",
        });
        return;
      }

      if (this.uploadType == 3) {
        // console.log(response);
        if (response.data.length == 0) {
          this.$message({
            showClose: true,
            message: "没有找到试题，请重试！",
            type: "warning",
          });
        } else {
          this.$message({
            showClose: true,
            message: "搜索成功，已为您添加至第一条！",
            type: "success",
          });
          this.handleUploadVariationFileRemove(fileList);

          this.uploadVariation = false;
          this.$parent.addQuestion(response.data);
        }
      } else {
        this.handleUploadVariationFileRemove(fileList);
        this.$message({
          showClose: true,
          message: "上传成功!",
          type: "warning",
        });

        this.$store.commit("SET_NEW_VIEW");
        setTimeout(() => {
          this.uploadVariation = false;
        }, 500);
      }

      // this.examList();
    },
    handleUploadLayerVariationOnSuccess(response, file, fileList) {
      // console.log(response);
      this.loading = false;
      this.handleUploadVariationFileRemove(fileList);
      this.uploadLayerVariationDialogVisible = false;
    },
    handleUploadVariationOnError(err, file, fileList) {
      // console.log(err);
      this.loading = false;
      this.handleUploadVariationFileRemove(fileList);
    },
    handleUploadVariationFileRemove(fileList) {
      fileList.splice(0, 1);
    },
    // 自主上传变式训练题 end
  },
};
</script>
<style lang="scss" scoped>
.unloadQuestion {
  .uploadVariationDialog .uploadVariation .el-upload .el-upload-dragger {
    width: 610px;
    height: 150px;
  }
  .uploadVariationDialog {
    .uploadVariation {
      padding: 20px 0 0 0;
      text-align: center;
      .upload-text {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
</style>
