<template>
  <div class="knowledge">
    <el-dialog
      v-dialogDrag
      title="批量选择知识点"
      :visible.sync="dialogVisible"
      width="918px"
    >
      <div class="two-tree-box">
        <div class="two-tree-box-item">
          <div class="header">知识点</div>
          <div class="cnt-box edit-scroll-style">
            <el-tree
              ref="tree"
              :key="newKeys"
              :data="knowledgeAllList"
              show-checkbox
              node-key="number"
              :default-checked-keys="loreTureArrid"
              :default-expanded-keys="twoTreeArray"
              :props="defaultProps"
              @check="elTreeChange"
            >
            </el-tree>
          </div>
        </div>
        <div class="two-tree-box-item">
          <div class="header">搜索知识点</div>
          <div class="cnt-box edit-scroll-style">
            <el-input
              v-model="twoTreeVal"
              placeholder="请输入内容"
              class="input-with-select"
              @keyup.enter.native="searchLore"
            >
              <i
                slot="suffix"
                class="el-icon-search el-input__icon"
                @click="searchLore"
              >
              </i>
            </el-input>
            <el-checkbox-group
              v-model="loreTureArrid"
              style="width: 200px; margin-top: 20px"
              @change="setChange()"
            >
              <el-checkbox
                v-for="(v, i) in TreeArray"
                :key="i"
                :label="v.number"
                >{{ v.name }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="loading" @click="submitKnow()">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "Knowledge",
  data() {
    return {
      dialogVisible: false,
      loading: false,
      twoTree: false,
      twoTreetype: true,
      defaultProps: {
        children: "child",
        label: "name",
      },
      loreTureArrid: [],
      TreeArray: [],
      twoTreeArray: [],
      knowledgeAllList: [],
      newKeys: 1,
      twoTreeVal: "",
      listkey: 1,
    };
  },
  created() {},
  methods: {
    //保存知识点
    submitKnow() {
      let loreTureArr = [];
      let arrTureArrid = this.$refs.tree.getCheckedKeys();
      // 去除存在子集的id
      this.$parent.loreArr.forEach((i) => {
        arrTureArrid.forEach((item, index) => {
          if (i.number == item && i.child.length > 0) {
            arrTureArrid.splice(index, 1);
          }
        });
      });
      // 判断是否超过5条
      if (arrTureArrid.length > this.indexLength) {
        this.$message({
          message: "最多保存5条知识点",
          type: "warning",
          showClose: true,
        });
        return;
      }
      this.$parent.loreArr.forEach((i) => {
        if (arrTureArrid.indexOf(i.number) != -1 && i.child.length == 0) {
          loreTureArr.push(i.name);
        }
      });
      // this.loreTureArrid = arrTureArrid;
      // this.loreTureArr = loreTureArr;
      let data = {
        knowledgeIds: arrTureArrid.join("#"),
        knowledgeNames: loreTureArr.join("#"),
      };
      this.$parent.setKnow(data);
    },
    // 知识点弹出层搜索
    searchLore() {
      if (this.twoTreeVal == "") {
        this.TreeArray = [];
        return;
      }
      // this.twoTreetype = false;
      let arr = [];
      this.TreeArray = [];
      this.$parent.loreArr.forEach((i) => {
        if (i.name.indexOf(this.twoTreeVal) >= 0) {
          arr.push(i.id);
          if (!i.child || (i.child && i.child.length == 0)) {
            this.TreeArray.push(i);
          }
        }
      });
      if (arr.length == 0) {
        this.$message({
          message: "没有找到相似知识点",
          type: "warning",
        });
        // this.twoTreetype = true;
        return;
      }

      this.twoTreeArray = arr;
    },
    setChange() {
      this.newKeys = new Date().getTime();
      this.twoTreeArray = this.loreTureArrid;
    },
    elTreeChange() {
      this.loreTureArrid = this.$refs.tree.getCheckedKeys();
    },
    init(knowledgeIds, val) {
      this.indexLength = val || 15;
      this.knowledgeAllList = this.$parent.knowledgeAllList;
      this.loreTureArrid = knowledgeIds || [];
      this.newKeys = new Date().getTime();
      this.TreeArray = [];
      this.twoTreeArray = knowledgeIds;
      this.twoTreeVal = "";
      this.dialogVisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.knowledge {
  .two-tree-box {
    display: flex;
    .two-tree-box-item {
      width: 50%;
      border: 1px solid #dee0e7;
      .cnt-box {
        padding: 18px;
        height: 345px;
      }
      .header {
        background: #f5f7fa;
        border-bottom: 1px solid #dee0e7;
        padding: 10px 16px;
      }
      &:last-child {
        margin-left: 12px;
      }
    }
  }
}
</style>
