<template>
  <div
    class="questionNum edit-scroll-style"
    :class="{ 'postion-box': postionBox }"
  >
    <template v-if="$parent.topicType == 0">
      <!-- 变式训练题 -->
      <div v-if="$parent.indexTypes == 'VariantTraining'">
        <div class="tab-box">
          <div
            v-for="(item, index) in tabItem"
            :key="index"
            class="tab-item"
            :class="{ 'tab-item-on': indexTab == item.value }"
            @click="checkTab(item.value)"
          >
            <p>{{ getNums(item.value) }}</p>
            {{ item.label }}
          </div>
        </div>
        <div class="tips-item">
          <div><span class="tips-icon tips-default"></span>待审核</div>
          <div><span class="tips-icon tips-refuse"></span>全部拒绝</div>
          <div><span class="tips-icon tips-portion"></span>部分通过</div>
          <div><span class="tips-icon tips-succ"></span>全部通过</div>
        </div>
        <div class="order-list-box">
          <template v-if="showTextVa()">
            <div class="no-data">当前列表暂无试题</div>
          </template>
          <template v-for="(v, i) in getVariationList">
            <div
              v-if="getShow(v)"
              :key="i"
              class="order-list"
              :class="{
                on: v.id == indexQuestion.id,
                err: !v.type,
                succ: v.type,
                voluntarily: v.statusReplaceTraining,
              }"
              @click="questionVariation(v)"
            >
              <div class="question-nums">
                <span class="question-num-tips">
                  <el-tooltip
                    effect="dark"
                    :content="v.examQuestionOrder"
                    placement="top"
                  >
                    <span :ref="'textRef' + (i + 1)">
                      {{ v.examQuestionOrder }}
                    </span>
                  </el-tooltip>
                </span>
                <template v-if="v.statusReplaceTraining == 1">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="由系统智能配题"
                    placement="top"
                  >
                    <span>
                      <img src="@/assets/newExam/newpaper/icon_ai.png" alt="" />
                      <img
                        class="ai-on"
                        src="@/assets/newExam/newpaper/icon_ai_on.png"
                        alt=""
                      />
                    </span>
                  </el-tooltip>
                </template>
              </div>

              <div
                class="order-tips"
                :class="{
                  'nums-tips-bs': v.nums > 0,
                  'tips-default': getClass(1, 1, v),
                  'tips-refuse': getClass(1, 2, v),
                  'tips-portion': getClass(1, 3, v),
                  'tips-succ': getClass(1, 4, v),
                }"
              >
                <template v-if="v.nums == 0 && v.id == indexQuestion.id">
                  无变式题
                </template>
                <template v-else>
                  <span>变式题</span>
                  {{ v.nums }}
                </template>
              </div>
            </div>
          </template>
        </div>
      </div>
      <!-- 分层变式训练题 -->
      <div v-if="$parent.indexTypes == 'layeredVariantTraining'">
        <div v-if="getVariationList.length > 0" class="tab-box">
          <div
            v-for="(item, index) in tabItem"
            :key="index"
            class="tab-item"
            :class="{ 'tab-item-on': indexTab == item.value }"
            @click="checkTab(item.value)"
          >
            <p>{{ getNumsLayer(item.value) }}</p>
            {{ item.label }}
          </div>
        </div>
        <div class="tips-item">
          <div><span class="tips-icon tips-default"></span>待审核</div>
          <div><span class="tips-icon tips-refuse"></span>全部拒绝</div>
          <div><span class="tips-icon tips-portion"></span>部分通过</div>
          <div><span class="tips-icon tips-succ"></span>全部通过</div>
        </div>
        <div class="order-list-box">
          <template v-if="showTextLaVa()">
            <div class="no-data">当前列表暂无试题</div>
          </template>
          <template v-for="(v, i) in getExamQuestion">
            <div
              v-if="getShowLayer(v)"
              :key="i"
              class="order-list"
              :class="{
                on: v.id == indexQuestion.id,
              }"
              @click="questionListClick(v)"
            >
              <span class="question-num-tips">
                <el-tooltip
                  effect="dark"
                  :content="v.examQuestionOrder"
                  placement="top"
                >
                  <span :ref="'textRef' + (i + 1)">
                    {{ v.examQuestionOrder }}
                  </span>
                </el-tooltip>
              </span>
              <div
                class="order-tips"
                :class="{
                  'nums-tips-bs': getShowValue(v) > 0,
                  'tips-default': getClass(2, 1, v),
                  'tips-refuse': getClass(2, 2, v),
                  'tips-portion': getClass(2, 3, v),
                  'tips-succ': getClass(2, 4, v),
                }"
              >
                <template
                  v-if="getShowValue(v) == 0 && v.id == indexQuestion.id"
                >
                  无分层变式题
                </template>
                <template v-else>
                  <span>分层变式题</span> {{ getShowValue(v) }}
                </template>
              </div>
            </div>
          </template>
        </div>
      </div>
    </template>
    <template v-if="$parent.topicType == 1">
      <div>
        <div class="tab-box">
          <div
            v-for="(item, index) in tabItem"
            :key="index"
            class="tab-item"
            :class="{ 'tab-item-on': indexTab == item.value }"
            @click="checkTab(item.value)"
          >
            <p>{{ getNumsAudit(item.value) }}</p>
            {{ item.labelA }}
          </div>
        </div>
        <div class="order-list-box">
          <template v-if="showText()">
            <div class="no-data">当前列表暂无试题</div>
          </template>
          <template v-for="(v, i) in getVariationList">
            <div
              v-if="getShowAudit(v)"
              :key="i"
              class="order-list"
              :class="{
                on: v.id == indexQuestion.id,
              }"
              @click="questionVariation(v)"
            >
              <span class="question-num-tips">
                <el-tooltip
                  effect="dark"
                  :content="v.examQuestionOrder"
                  placement="top"
                >
                  <span :ref="'textRef' + (i + 1)">
                    {{ v.examQuestionOrder }}
                  </span>
                </el-tooltip>
              </span>

              <div class="tips-box">
                <div
                  v-if="getNumsTypeShow(v)"
                  class="nums-tips order-tips order-tips-b"
                >
                  <el-tooltip
                    effect="dark"
                    content="他人补充过变式题"
                    placement="top"
                  >
                    <span>补</span>
                  </el-tooltip>
                </div>
                <div
                  class="order-tips"
                  :class="{
                    'nums-tips': getcNumsErrTypes(v),
                  }"
                >
                  <el-tooltip
                    effect="dark"
                    content="变式题过审数/变式题总数"
                    placement="top"
                  >
                    <span>
                      {{ getcNums(v) }} /
                      {{ v.examQuestionExtendList.length }}
                    </span>
                  </el-tooltip>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="submit-push">
          <el-button type="primary" :loading="btnLoading" @click="submitPush()"
            >审题完成，推送通知</el-button
          >
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { completeexaminePush } from "@/core/api/exam/exampaper";
const tabItem = [
  {
    label: "全部",
    labelA: "全部",
    value: 0,
  },
  {
    label: "未配题",
    labelA: "待审核",
    value: 1,
  },
  {
    label: "已配题",
    labelA: "已审核",
    value: 2,
  },
];

export default {
  name: "QuestionNum",
  data() {
    return {
      isSign: false,
      btnLoading: false,
      postionBox: false,
      tabItem: tabItem,
      indexTab: 0,
      indexQuestion: {},
    };
  },
  computed: {
    ...mapGetters([
      "getExamPaper",
      "getIndexQuestion",
      "getExamQuestion",
      "getExamTypeList",
      "getVariationList",
      "GET_QUESTION_TYPE_LIST",
      "GET_LAYER_TYPS",
    ]),
  },
  watch: {
    getIndexQuestion: {
      immediate: true,
      handler() {
        this.indexQuestion = Object.assign({}, this.getIndexQuestion);
      },
    },
  },
  created() {},
  mounted() {
    //给window添加一个滚动滚动监听事件
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    //离开该页面需要移除这个监听的事件
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    submitPush() {
      let data = {
        examPaperId: this.$route.query.id,
      };
      this.btnLoading = true;
      completeexaminePush(data)
        .then(() => {
          this.$message({
            showClose: true,
            message: "通知已推送成功！",
            type: "success",
          });
          this.btnLoading = false;
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    /**
     *
     * @param type 1 或者 2 区分变式题或者分层变式题
     * @param classType 需要显示颜色的类型 1
     * @param v 当前试题
     */
    getClass(type, classType, v) {
      let { examQuestionExtendList } = v;
      let arr = examQuestionExtendList.filter(
        (item) =>
          item.type == type || (type == 1 ? item.type == 3 : item.type == 4)
      );
      if (classType == 1) {
        let array = arr.filter((item) => !item.status);
        if (arr.length != 0 && array.length == arr.length) {
          return true;
        } else {
          return false;
        }
      }
      if (classType == 2) {
        let array = arr.filter((item) => item.status == 2);
        if (arr.length != 0 && array.length == arr.length) {
          return true;
        } else {
          return false;
        }
      }
      if (classType == 3) {
        let arrayErr = arr.filter((item) => item.status == 2);
        let arraySucc = arr.filter((item) => item.status == 1);
        let arrayOn = arr.filter((item) => !item.status);
        if (
          arrayErr.length != arr.length &&
          arraySucc.length != arr.length &&
          arrayOn.length != arr.length
        ) {
          return true;
        } else {
          return false;
        }
      }
      if (classType == 4) {
        let array = arr.filter((item) => item.status == 1);
        if (arr.length != 0 && array.length == arr.length) {
          return true;
        } else {
          return false;
        }
      }
    },
    async isOverflow(i) {
      let type = true;
      await this.$nextTick(() => {
        let text = "textRef" + i;
        const textRef = this.$refs[text];

        if (!textRef) {
          return false;
        }
        let indexRef = textRef[0];
        if (indexRef && indexRef.offsetWidth < indexRef.scrollWidth) {
          type = false;
        }
      });
      return type;
    },
    handleScroll() {
      let scrollTop = 0;
      if (document.documentElement && document.documentElement.scrollTop) {
        scrollTop = document.documentElement.scrollTop;
      } else if (document.body) {
        scrollTop = document.body.scrollTop;
      }

      if (scrollTop > 100) {
        this.postionBox = true;
      } else {
        this.postionBox = false;
      }
    },
    getcNums(v) {
      let nums = v.examQuestionExtendList.filter(
        (item) => item.status == 1
      ).length;
      return nums;
    },
    getcNumsTypes(v) {
      let nums = v.examQuestionExtendList.filter(
        (item) => item.status == 1
      ).length;
      if (nums == v.examQuestionExtendList.length) {
        return true;
      }
      return false;
    },
    getcNumsErrTypes(v) {
      let nums = v.examQuestionExtendList.filter(
        (item) => item.status == 1
      ).length;
      if (nums < v.examQuestionExtendList.length) {
        return true;
      }
      return false;
    },
    getNumsTypeShow(v) {
      let nums = v.examQuestionExtendList.filter(
        (item) => item.type == 3 || item.type == 4
      ).length;
      if (nums > 0) {
        return true;
      }

      return false;
    },
    getShow(v) {
      if (this.indexTab == 0) {
        return true;
      }
      if (this.indexTab == 1 && !v.type) {
        return true;
      }
      if (this.indexTab == 2 && v.type) {
        return true;
      }
    },
    showTextVa() {
      if (this.indexTab == 0) {
        if (this.getVariationList.length == 0) {
          return true;
        }
        return false;
      }
      let arr = this.getVariationList.filter((item) => item.type);
      if (this.indexTab == 1 && arr.length == this.getVariationList.length) {
        return true;
      }
      if (this.indexTab == 2 && arr.length == 0) {
        return true;
      }
      return false;
    },
    getShowAudit(v) {
      if (this.indexTab == 0) {
        return true;
      }
      let arr = v.examQuestionExtendList.filter((item) => !item.status);
      if (this.indexTab == 1 && arr.length > 0) {
        return true;
      }
      if (this.indexTab == 2 && arr.length == 0) {
        return true;
      }
    },
    showText() {
      if (this.indexTab == 0) {
        if (this.getVariationList.length == 0) {
          return true;
        }
        return false;
      }
      let index = 0;
      let type = false;
      this.getVariationList.map((item) => {
        let arr = item.examQuestionExtendList.filter((item) => !item.status);
        if (arr.length > 0) {
          index++;
        }
      });
      if (this.indexTab == 1 && index == 0) {
        return true;
      }

      if (this.indexTab == 2 && index == this.getVariationList.length) {
        return true;
      }
      return type;
    },
    checkTab(val) {
      this.indexTab = val;
    },

    getNumsAudit(type) {
      if (type == 0) {
        return this.getVariationList.length;
      }
      if (type == 1) {
        let typeNums = 0;
        this.getVariationList.map((item) => {
          if (
            item.examQuestionExtendList.filter((item) => !item.status).length >
            0
          ) {
            typeNums++;
          }
        });
        return typeNums;
      }
      if (type == 2) {
        let typeNums = 0;
        this.getVariationList.map((item) => {
          if (
            item.examQuestionExtendList.filter((item) => !item.status).length ==
            0
          ) {
            typeNums++;
          }
        });
        return typeNums;
      }
    },
    getShowValue(item) {
      // let typeNums = 0;
      let arr = item.examQuestionExtendList.filter(
        (index) => index.difficultyType == this.GET_LAYER_TYPS
      );
      return arr.length;
    },
    // 获取分层变式题总量
    getNumsLayer(type) {
      let typeNums = 0;
      let array = JSON.parse(JSON.stringify(this.getVariationList));
      if (array.length == 0) return;
      if (type == 0) {
        return array.length;
      }

      if (type == 1) {
        array.map((item) => {
          let arr = item.examQuestionExtendList.filter(
            (index) => index.difficultyType == this.GET_LAYER_TYPS
          );
          if (arr.length == 0) {
            typeNums++;
          }
        });
      }
      if (type == 2) {
        array.map((item) => {
          let arr = item.examQuestionExtendList.filter(
            (index) => index.difficultyType == this.GET_LAYER_TYPS
          );
          if (arr.length > 0) {
            typeNums++;
          }
        });
      }
      return typeNums;
    },
    showTextLaVa() {
      if (this.indexTab == 0) {
        if (this.getExamQuestion.length == 0) {
          return true;
        }
        return false;
      }
      let index = 0;
      this.getExamQuestion.map((item) => {
        let arr = item.examQuestionExtendList.filter(
          (index) => index.difficultyType == this.GET_LAYER_TYPS
        );
        if (arr.length == 0) {
          index++;
        }
      });
      if (this.indexTab == 1 && index == 0) {
        return true;
      }
      if (this.indexTab == 2 && index == this.getExamQuestion.length) {
        return true;
      }
    },
    getShowLayer(item) {
      if (this.indexTab == 0) {
        return true;
      }
      if (this.indexTab == 1) {
        let arr = item.examQuestionExtendList.filter(
          (index) => index.difficultyType == this.GET_LAYER_TYPS
        );
        if (arr.length == 0) {
          return true;
        }
      }
      if (this.indexTab == 2) {
        let arr = item.examQuestionExtendList.filter(
          (index) => index.difficultyType == this.GET_LAYER_TYPS
        );
        if (arr.length > 0) {
          return true;
        }
      }
    },
    getNums(type) {
      if (type == 0) {
        return this.getVariationList.length;
      }
      if (type == 1) {
        let typeNums = 0;
        this.getVariationList.map((item) => {
          if (!item.type) {
            typeNums++;
          }
        });
        return typeNums;
      }
      if (type == 2) {
        let typeNums = 0;
        this.getVariationList.map((item) => {
          if (item.type) {
            typeNums++;
          }
        });
        return typeNums;
      }
    },
    questionVariation(v) {
      this.backTop();
      this.$store.commit("setQuestionVariation", v);
    },
    setType(v, checkType) {
      // console.log(v);
      let type = false;
      let arr = v.examQuestionExtendList.filter(
        (i) => i.difficultyType == this.layeredVaTypes
      );
      // console.log(arr);
      if (checkType == 1) {
        if (arr.length == 0) {
          type = true;
        }
      } else {
        if (arr.length > 0) {
          type = true;
        }
      }
      return type;
    },
    questionListClick(v) {
      // 存储第一道题
      // console.log(v);
      this.backTop();
      this.$store.commit("setIndexQuestion", v);
    },
    // 回到顶部
    backTop() {
      // let timer = setInterval(function () {
      //   var scrollTop = Math.floor(
      //     document.body.scrollTop ||
      //       document.documentElement.scrollTop ||
      //       window.pageYOffset
      //   );
      //   var speed = Math.floor(scrollTop / 4);
      //   if (scrollTop <= 10) {
      document.documentElement.scrollTop = document.body.scrollTop = 0;
      //   } else {
      //     document.documentElement.scrollTop = document.body.scrollTop =
      //       scrollTop - speed;
      //   }
      //   if (scrollTop === 0) {
      //     clearInterval(timer);
      //   }
      // }, 10);
    },
  },
};
</script>
<style lang="scss" scoped>
.postion-box {
  position: fixed;
  right: 91px;
  top: 91px;
}
.questionNum {
  width: 312px;
  max-height: calc(100vh - 300px);
  min-height: 200px;
  padding: 18px;
  border: 1px solid #e9e9e9;
  background-color: #ffffff;
  .tab-box {
    display: flex;
    justify-content: space-between;
    div {
      width: 86px;
      height: 63px;
      background: #f9fafc;
      text-align: center;
      padding: 10px 0;
      font-size: 12px;
      font-weight: bold;
      cursor: pointer;
      p {
        margin-bottom: 0;
        font-size: 16px;
      }
    }
    .tab-item-on {
      background: #f3f8ff;
      color: #2474ed;
    }
  }
  .submit-push {
    text-align: center;
    margin-top: 12px;
  }
  .order-list-box {
    .no-data {
      text-align: center;
      color: #909399;
      margin: 50px 0;
    }
    .order-list {
      padding: 8px 10px;
      border-radius: 4px;
      border: 1px solid #e9e9e9;
      display: flex;
      justify-content: space-between;
      margin-top: 8px;
      cursor: pointer;
      .question-num-tips {
        max-width: 120px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
      }
      img {
        display: inline-block;
      }
      .ai-on {
        display: none;
      }
      .tips-box {
        display: flex;
      }
      &:hover {
        background: #2474ed;
        color: #ffffff;
        .order-tips-b {
          background: #2474ed;
          color: #ffffff;
          border-color: #ffffff;
        }
        img {
          display: none;
        }
        .ai-on {
          display: inline-block;
        }
      }
    }
    .order-tips {
      font-size: 12px;
      padding: 2px 7px;
      background: #f9fafc;
      border-radius: 4px;
      border: 1px solid #d5d6db;
      color: #5e6a7a;
      // span {
      //   display: none;
      // }
    }
    .nums-tips {
      background: #f3f8ff;
      border-radius: 4px;
      border: 1px solid #2474ed;
      color: #2474ed;
    }
    .nums-tips-bs {
      background: #f3f8ff;
      border-radius: 4px;
      border: 1px solid #2474ed;
      // color: #2474ed;
    }
    .tips-default {
      color: #2474ed;
    }
    .tips-refuse {
      color: #d82c2c;
    }
    .tips-portion {
      color: #e9711e;
    }
    .tips-succ {
      color: #1ca869;
    }
    //   tips-default tips-refuse tips-portion tips-succ
    .order-tips-b {
      border: 1px dashed #2474ed;
      background: #f3f8ff;
      margin-right: 6px;
    }

    .on {
      background: #2474ed;
      color: #ffffff;
      .order-tips {
        span {
          display: inline-block;
        }
      }
      .order-tips-b {
        background: #2474ed;
        color: #ffffff;
        border-color: #ffffff;
      }
      img {
        display: none;
      }
      .ai-on {
        display: inline-block;
      }
    }
  }
  .tips-item {
    display: flex;
    justify-content: space-between;
    margin-top: 18px;
    font-size: 12px;
    .tips-icon {
      display: inline-block;
      margin-right: 4px;
      width: 8px;
      height: 8px;
      border-radius: 2px;
    }
    .tips-default {
      background-color: #2474ed;
    }
    .tips-refuse {
      background-color: #d82c2c;
    }
    .tips-portion {
      background-color: #e9711e;
    }
    .tips-succ {
      background-color: #1ca869;
    }
  }
}
</style>
