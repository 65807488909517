export const natureOptions = [
  {
    label: "公办",
    value: 0,
  },
  {
    label: "民办",
    value: 1,
  },
];

// 办学层次 1-小学，2-初中，3-九年制，4-普通高中，5-职业高中，6-高完中，7-12年制，8-大学专科，9-大学本科，10-其他;多选用逗号分隔
export const educationalLevelOptions = [
  {
    label: "小学",
    value: 1,
  },
  {
    label: "初中",
    value: 2,
  },
  {
    label: "九年制",
    value: 3,
  },
  {
    label: "普通高中",
    value: 4,
  },
  {
    label: "职业高中",
    value: 5,
  },
  {
    label: "高完中",
    value: 6,
  },
  {
    label: "12年制",
    value: 7,
  },
  {
    label: "大学专科",
    value: 8,
  },
  {
    label: "大学本科",
    value: 9,
  },
  {
    label: "其他",
    value: 10,
  },
];
// 1-省一级示范校，2-省二级示范校，3-市新优质学校;
export const honorItem = [
  {
    label: "省一级示范校",
    value: 1,
  },
  {
    label: "省二级示范校",
    value: 2,
  },
  {
    label: "市新优质学校",
    value: 3,
  },
];
// 1-非相似题，2-题干有误，3-答案有误，4-解析有误，5-知识点有误，6-其他
export const refusalGroup = [
  {
    label: "非相似题",
    value: "1",
  },
  {
    label: "题干有误",
    value: "2",
  },
  {
    label: "答案有误",
    value: "3",
  },
  {
    label: "解析有误",
    value: "4",
  },
  {
    label: "知识点有误",
    value: "5",
  },
  {
    label: "其他",
    value: "6",
  },
];
