<template>
  <div class="reasonRefusal">
    <div class="tips">请选择至少一项原因</div>
    <div class="ref-box">
      <div
        v-for="(item, index) in refusalGroup"
        :key="index"
        :class="{
          on: errArr.indexOf(item.value) != -1,
        }"
        @click="checkType(item.value)"
      >
        {{ item.label }}
      </div>
      <div
        :class="{
          on: errArr.indexOf('6') != -1,
        }"
        @click="checkTypeAll()"
      >
        以上都不是
      </div>
    </div>
    <el-input
      v-if="errArr.indexOf('6') != -1"
      v-model="reasonText"
      placeholder="请在此处填写原因"
      class="input-with-select"
      maxlength="200"
      show-word-limit
    >
      <el-button slot="append" :loading="btnLoading" @click="submitData()">
        保 存
      </el-button>
    </el-input>
  </div>
</template>
<script>
import { refusalGroup } from "@/core/util/dictionaries";
import { saveResult } from "@/core/api/exam/examLore";

export default {
  name: "ReasonRefusal",
  props: {
    indexQuestion: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      refusalGroup: refusalGroup.filter((item) => item.value != 6),
      reasonText: "",
      errArr: [],
      btnLoading: false,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      let { reasonType, reasonText } = this.indexQuestion;

      if (reasonType) {
        this.errArr = reasonType.split(",");
      } else {
        this.errArr = [];
      }
      this.reasonText = reasonText;
    },

    submitData() {
      let data = {
        reasonType: this.errArr.join(","),
        reasonText: this.errArr.indexOf("6") != -1 ? this.reasonText : "",
        status: 2,
        examPaperId: this.$route.query.id,
        extendId: this.indexQuestion.qId,
        examPaperQuestionId: this.$parent.getIndexQuestion.id,
      };
      this.btnLoading = true;
      saveResult(data)
        .then(() => {
          this.btnLoading = false;
          this.upLoadView(data);
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    upLoadView(rdata) {
      let examQuestionExtendList =
        this.$parent.getIndexQuestion.examQuestionExtendList;
      examQuestionExtendList = JSON.parse(
        JSON.stringify(examQuestionExtendList)
      );
      examQuestionExtendList = examQuestionExtendList.map((item) => {
        if (item.qId == rdata.extendId) {
          item.reasonText = rdata.reasonText;
          item.reasonType = rdata.reasonType;
          item.status = rdata.status;
        }
        return item;
      });

      let data = {
        id: this.$parent.getIndexQuestion.id,
        examQuestionExtendList: examQuestionExtendList,
      };
      this.$parent.examTraining = examQuestionExtendList;
      this.$store.commit("SET_CHANGE_AUDIT", data);
    },
    checkTypeAll() {
      this.errArr = ["6"];
    },
    checkType(value) {
      this.errArr = this.errArr.filter((item) => item != "6");
      if (this.errArr.indexOf(value) == -1) {
        this.errArr.push(value);
      } else {
        this.errArr = this.errArr.filter((item) => item != value);
      }
      if (this.errArr.length == 0) return;
      this.submitData();
    },
  },
};
</script>
<style lang="scss" scoped>
.reasonRefusal {
  padding: 18px;
  ::v-deep {
    .el-button--default {
      background-color: #ffffff;
      span {
        color: #2474ed;
      }
    }
  }
  ::v-deep .el-input__inner {
    padding-right: 70px;
  }
  .tips {
    color: #5e6a7a;
    margin-bottom: 10px;
  }
  .ref-box {
    display: flex;
    margin-bottom: 10px;
    div {
      margin-right: 8px;
      padding: 6px 18px;
      border-radius: 4px;
      border: 1px solid #d5d6db;
      cursor: pointer;
    }
    .on {
      background: #f3f8ff;
      color: #2474ed;
      border: 1px solid #2474ed;
    }
  }
}
</style>
